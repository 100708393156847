import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Input, Text, PrimaryBadge } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';

import { Question, QuestionSelection } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

import './index.scss';

type Props = { question: Question; index: number; t: TFunction; active: boolean };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function ScoreQuestionVoteCard({ question, index, t, active }: Props) {
  const { register } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.subAnswers` as const;

  const [selections] = useState<QuestionSelection[]>(question.subQuestions || []);

  const { isRequired, title, fileUrl, responseValidation, videoUrl } = question;
  const minScore = responseValidation?.minScore || 0;
  const maxScore = responseValidation?.maxScore || 100;

  return (
    <div className='score-election-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        <SecondaryBadge>{t('user:poll.surveyKind.score.label')}</SecondaryBadge>
        <Text type='b4' color='gray-400'>
          {t('user:poll.surveyKind.score.text', { minScore, maxScore })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items'>
        {selections.map((selection, selectionIndex) => (
          <VoteItem
            key={selection._id}
            image={selection?.fileUrl || ''}
            title={selection?.description || ''}
            active={active}
            videoUrl={selection?.videoUrl}
            id={selection._id}
            fieldName={`${fieldName}.${selectionIndex}`}
            min={minScore}
            max={maxScore}
            t={t}
          />
        ))}
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
    </div>
  );
}

export default ScoreQuestionVoteCard;

function VoteItem({
  image,
  videoUrl,
  active,
  title,
  id,
  fieldName,
  min,
  max,
  t,
}: {
  image?: string;
  videoUrl?: { youtube?: string };
  active?: boolean;
  title: string;
  id: string;
  fieldName: `answers.${number}.subAnswers.${number}`;
  min: number;
  max: number;
  t: TFunction;
}) {
  const { register, control } = useFormContext<VotingSurveyForm>();

  return (
    <label className='vote-card__item score-election-vote-card__item' htmlFor={id}>
      <div className='score-election-vote-card__contents'>
        {image && <SurveyImage src={image} alt={title} height={100} />}

        {active && videoUrl?.youtube ? (
          <div className='score-election-vote-card__contents__video'>
            {ReactHtmlParser(
              new YouTubeURLParser(videoUrl.youtube).getIframe({
                width: 560,
                height: 315,
                allowFullScreen: true,
              }) || '',
            )}
          </div>
        ) : (
          ''
        )}

        <Text className='score-election-vote-card__item__title' type='b3'>
          {title}
        </Text>
      </div>

      <div className='score-election-vote-card__item__input'>
        <Controller
          name={`${fieldName}.value`}
          control={control}
          rules={{
            validate: (value) => {
              const isValid = Number(value) >= min && Number(value) <= max;
              return isValid;
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              onChange={({ target: { value } }) => {
                if (value.length > 3) {
                  return;
                }

                field.onChange(value);
              }}
              width='90px'
              min={min}
              max={max}
              type='number'
              pattern='\d+'
            />
          )}
        />
        <input hidden {...register(`${fieldName}.subQuestionOid`)} value={id} />
        <Text type='b3' color='gray-300' className='score-election-vote-card__item__score-text'>
          {t('user:poll.unit.score')}
        </Text>
      </div>
    </label>
  );
}
