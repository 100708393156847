import { useSearchParams, SetURLSearchParams } from 'react-router-dom';

type Params = Record<string, string>;

type QueryParamsTuple<T> = [T, SetURLSearchParams];

export const useQueryParams = <T extends Params>(params: T): QueryParamsTuple<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedParams = parseParamsFromSearchUrl(searchParams, params);

  return [parsedParams, setSearchParams];
};

const parseParamsFromSearchUrl = <T extends Params>(
  searchParams: URLSearchParams,
  baseParams: T,
) => {
  const result: Params = { ...baseParams };

  Object.entries(result).forEach(([key]) => {
    const param = searchParams.get(key) || '';

    result[key] = param;
  });

  return result as T;
};
