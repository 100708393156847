import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Radio, Text, PrimaryBadge } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';

import { HD_POLL_ID } from 'constants/exception';

import { Question, QuestionSelection } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

import './index.scss';

type Props = { question: Question; index: number; t: TFunction; active: boolean; pollId: string };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function RadioQuestionVoteCard({ question, index, t, active, pollId }: Props) {
  const { register, control } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.answer` as const;

  const [selections] = useState<QuestionSelection[]>(question.selections || []);

  const { isRequired, title, fileUrl, videoUrl } = question;

  const isHDPollId = pollId === HD_POLL_ID;

  return (
    <div className='radio-question-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        <SecondaryBadge>
          {isHDPollId ? '객관식 질문 (택 1)' : t('user:poll.surveyKind.radio.label')}
        </SecondaryBadge>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items'>
        {selections.map((selection) => (
          <Controller
            key={selection._id}
            name={fieldName}
            control={control}
            defaultValue=''
            render={({ field }) => {
              const selectionId = `${selection.number}`;
              const isSelected = field.value === selectionId;

              return (
                <Radio
                  id={selection._id}
                  name={question._id}
                  checked={isSelected}
                  onChange={() => field.onChange(selectionId)}
                  className='vote-card__item'
                  variant='error'
                >
                  <div className='radio-vote-card__contents'>
                    {selection.fileUrl && (
                      <SurveyImage
                        src={selection.fileUrl}
                        alt={selection.description}
                        height={100}
                      />
                    )}

                    {active && selection?.videoUrl?.youtube ? (
                      <div className='radio-vote-card__contents__video'>
                        {ReactHtmlParser(
                          new YouTubeURLParser(selection.videoUrl.youtube).getIframe({
                            width: 560,
                            height: 300,
                            allowFullScreen: true,
                          }) || '',
                        )}
                      </div>
                    ) : (
                      ''
                    )}

                    <Text type='b3'>{selection.description}</Text>
                  </div>
                </Radio>
              );
            }}
          />
        ))}
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
    </div>
  );
}

export default RadioQuestionVoteCard;
