/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  kind: {
    election: 'election',
    survey: 'survey',
  },
  status: {
    ready: 'Scheduled',
    ongoing: 'OnGoing',
    ended: 'Ended',
    counted: 'Counted',
    canceled: 'Canceled',
  },
  buttons: {
    vote: 'Go voting',
    prev: 'Previous',
    next: 'Next',
    submit: 'Submit',
  },
  survey: {
    anonymous: 'This survey ensures anonymity.',
    realName: 'This survey does not ensure anonymity.',
  },
  overview: {
    rate: 'Turnout',
    currentStatus: 'Status',
    result: 'Result',
    certificate: 'Certificate',
  },
  more: 'More',
  errorTitle: 'Voting failed ',
  errorMessages: {
    canceled: 'This {{poll}} is invalid',
    currentStatus: 'This {{poll}} does not offer status',
    rate: 'This {{poll}} does not offer real-time turnout',
    result: 'This survey does not offer {{poll}} result',
    notCount: 'This {{poll}} hasn’t counted ballots. Please come back a little bit later!',
    notVoted: 'You haven’t participated in the {{poll}}',
    choiceElection: 'Please cast votes',
    score: 'Please give all scores',
    invalidScore: 'You can give scores in between {{minScore}} and {{maxScore}}',
    prosOrConsElection: 'Please cast in-favor, against{{abstention}} votes',
    rank: 'Please select all places',
    required: 'You need to answer to this question',
  },
  electionInfo: 'Election',
  unit: {
    vote: ' votes',
    score: ' points',
  },
  abstention: 'Blank',
  required: 'Required',
  electionKind: {
    choice: {
      label: 'General voting',
      text: 'You can cast {{response}} votes',
    },
    score: {
      label: 'Scored voting',
      text: 'Please give scores in between {{minScore}} and {{maxScore}}',
    },
    propsOrCons: {
      label: 'Approval voting',
      text: 'You can cast in-favor, against{{abstention}} vote',
    },
    rank: {
      label: 'Ranked voting',
      text: 'You can select to the {{rankLimit}} place',
    },
  },
  surveyKind: {
    radio: {
      label: 'Single choice question',
    },
    checkbox: {
      label: 'Multiple choice question',
      text: 'Please select {{threshold}} items',
    },
    text: {
      label: 'Text-answer question',
      placeholder: 'Let us know your idea',
    },
    scale: {
      label: 'Range question',
    },
    rank: {
      label: 'Preference question',
      text: 'Please select to the {{rankLimit}} place',
    },
    score: {
      label: 'Scoring question',
      text: 'You can give scores in between {{minScore}} and {{maxScore}}',
    },
  },
  result: {
    surveyTotal: '{{total}} total participants',
    surveyMultipleAnswer: 'including multiple answers',
    score: {
      election: {
        score: 'Total score',
        participant: 'Total voters',
        average: 'Average score',
      },
      survey: {
        score: 'Score',
        participant: 'Participants',
        average: 'Average score',
      },
    },
    propsOrCons: {
      favor: 'In favor',
      against: 'Against',
    },
    rank: {
      unit: {
        1: 'st',
        2: 'nd',
        3: 'rd',
        other: 'th',
      },
      weightedScore: 'Weighted Score',
      tooltip: 'What is Weighted Score? The value was calculated by charging 1st = 3 points, 2nd = 2 points, and 3rd = 1 point',
    },
    surveyTextEmpty: 'No content to display',
    tooltip: {
      rate: 'Turnout',
      count: 'Count',
    },
  },
};
