import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ChoiceElectionResultCard,
  ProsOrConsElectionResultCard,
  ScoreElectionResultCard,
  RankElectionResultCard,
  ChoiceQuestionResultCard,
  TextQuestionResultCard,
  ScaleQuestionResultCard,
  ScoreQuestionResultCard,
  RankQuestionResultCard,
} from '@pickme/ui';

import PollResultLayout from 'components/features/poll/layout/Result';

import { useGetPollResult } from 'query-hooks/poll';

import {
  parseChoiceElectionResult,
  parseProsOrConsElectionResult,
  parseScoreElectionResult,
  parseRankElectionResult,
  parseChoiceQuestionResult,
  parseTextQuestionResult,
  parseScaleQuestionResult,
  parseScoreQuestionResult,
  parseRankQuestionResult,
} from 'functions/poll-result';

function PollResult() {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const { data } = useGetPollResult(id);

  const isElectionResults = !!data && 'subElectionResults' in data;
  const isSurveyResults = !!data && 'questionResults' in data;

  return (
    <div>
      <PollResultLayout name={data?.title || ''}>
        {isElectionResults &&
          data?.subElectionResults?.map((subElectionResult, index) => {
            if (subElectionResult.kind === 'ChoiceElection') {
              return (
                <ChoiceElectionResultCard
                  key={`${subElectionResult.kind}${subElectionResult.title}${index}`}
                  {...parseChoiceElectionResult(subElectionResult, t)}
                />
              );
            }

            if (subElectionResult.kind === 'ProsOrConsElection') {
              return (
                <ProsOrConsElectionResultCard
                  key={`${subElectionResult.kind}${subElectionResult.title}${index}`}
                  {...parseProsOrConsElectionResult(subElectionResult, t)}
                />
              );
            }

            if (subElectionResult.kind === 'ScoreElection') {
              return (
                <ScoreElectionResultCard
                  key={`${subElectionResult.kind}${subElectionResult.title}${index}`}
                  {...parseScoreElectionResult(subElectionResult, t)}
                />
              );
            }

            if (subElectionResult.kind === 'RankElection') {
              return (
                <RankElectionResultCard
                  key={`${subElectionResult.kind}${subElectionResult.title}${index}`}
                  {...parseRankElectionResult(subElectionResult, t)}
                />
              );
            }

            return <div />;
          })}

        {isSurveyResults &&
          data?.questionResults?.map((result, index) => {
            if (result.kind === 'RadioQuestionResult' || result.kind === 'CheckboxQuestionResult') {
              return (
                <ChoiceQuestionResultCard
                  key={`${result.kind}${result.title}${index}`}
                  {...parseChoiceQuestionResult(result, index, t)}
                />
              );
            }

            if (result.kind === 'TextQuestionResult') {
              return (
                <TextQuestionResultCard
                  key={`${result.kind}${result.title}${index}`}
                  {...parseTextQuestionResult(result, index, t)}
                />
              );
            }

            if (result.kind === 'ScaleQuestionResult') {
              return (
                <ScaleQuestionResultCard
                  key={`${result.kind}${result.title}${index}`}
                  {...parseScaleQuestionResult(result, index, t)}
                />
              );
            }

            if (result.kind === 'ScoreQuestionResult') {
              return (
                <ScoreQuestionResultCard
                  key={`${result.kind}${result.title}${index}`}
                  {...parseScoreQuestionResult(result, index, t)}
                />
              );
            }

            if (result.kind === 'RankQuestionResult') {
              return (
                <RankQuestionResultCard
                  key={`${result.kind}${result.title}${index}`}
                  {...parseRankQuestionResult(result, index, t)}
                />
              );
            }

            return <div />;
          })}
      </PollResultLayout>
    </div>
  );
}

export default PollResult;
