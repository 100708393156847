import { SpaceResponse } from '@pickme/core';

import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';

const APIS: Apis = {
  getOrganization: { method: 'GET', url: '/api/organization/:id' },
  getOrganizationOid: { method: 'GET', url: '/api/organization/:alias' },
};

export const createGetOrganizationRequest = (id: string) =>
  createHttpRequest<Omit<SpaceResponse, 'subscription' | 'level'>>({
    ...APIS.getOrganization,
    params: { id },
    query: { type: 'objectId' },
  });

export const createGetOrganizationOidRequest = (alias: string) =>
  createHttpRequest<{ _id: string }>({
    ...APIS.getOrganizationOid,
    params: { alias },
    query: { type: 'alias' },
  });
