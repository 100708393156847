import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';

import {
  createOTPSignInRequest,
  createGetSessionRequest,
  createSignOutRequest,
  createAgreeComplianceRequest,
} from 'apis/auth';

import { complianceModal, confirmModal, timeoutModal } from 'states/modal';
import { toast } from 'states/toast';

import { handleSessionError } from 'utils/error-parser';
import { CrossSessionError } from 'utils/error';

import { OTPSignInBody, Session } from 'types/auth';
import type { AxiosError } from 'axios';

const assertCrossedSession = (data: Session) => {
  // 관리자 로그인을 유지한 채, 넘어온 경우
  // @ts-ignore
  if (data.type === 'admin' || data.type === 'system') {
    throw new CrossSessionError();
  }
};

export const useGetSession = (options?: { disabled: boolean }) => {
  const { mutate: signOutMutate } = useSignOut();

  return useQuery(
    'auth',
    async () => {
      const { data } = await createGetSessionRequest();

      // 투표미리보기나 관리자 설문조사 페이지의 경우, 관리자세션을 로그아웃 처리하지 않습니다.
      const isAccessWithAdminSession =
        window.location.pathname.includes('embed') ||
        window.location.pathname.includes('admin-survey');

      if (!isAccessWithAdminSession) {
        assertCrossedSession(data);
      }

      return data;
    },
    {
      onError: (error) => {
        if (error instanceof CrossSessionError) {
          signOutMutate(true);
        }
      },
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !options?.disabled,
    },
  );
};

export const useOTPSignIn = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (signInForm: OTPSignInBody) => {
      const { data } = await createOTPSignInRequest(signInForm);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('auth');
      },
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useAgreeCompliance = () => {
  const queryClient = useQueryClient();
  const setComplianceModalVisible = useSetRecoilState(complianceModal);
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async () => {
      await createAgreeComplianceRequest();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('auth');
        setComplianceModalVisible(false);
      },
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useSignOut = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setConfirmModalVisible = useSetRecoilState(confirmModal);
  const setTimeoutModalVisible = useSetRecoilState(timeoutModal);

  return useMutation(
    async () => {
      await createSignOutRequest();
    },
    {
      onSuccess: (_, isSelf?: boolean) => {
        queryClient.resetQueries();
        setConfirmModalVisible(false);
        navigate('/', { replace: true });

        if (!isSelf) {
          setTimeoutModalVisible(true);
        }
      },
      onError: (error: any, isSelf) => {
        handleSessionError(error, queryClient);

        if (!isSelf) {
          setTimeoutModalVisible(true);
        }
      },
    },
  );
};
