import { PollCardHeader } from '@pickme/design-system';
import { TFunction } from 'i18next';

import PollGridCard from 'components/features/home/Polls/PollGridCard';
import PollListCard from 'components/features/home/Polls/PollListCard';

import { useGetOrganization } from 'query-hooks/organization';

import { Poll, PollViewMode } from 'types/poll';

import { container } from './index.css';

type Props = {
  poll: Poll;
  viewMode: PollViewMode;
  t: TFunction;
};

function PollView({ poll, viewMode, t }: Props) {
  const { data: space } = useGetOrganization(poll.organizationId);

  return (
    <div key={`polls-${viewMode}-${poll?._id}`} className={container}>
      <PollCardHeader
        name={space?.name || ''}
        imageUrl={space?.logo || ''}
        verified={space?.verified}
      />

      {viewMode === PollViewMode.Table ? (
        <PollListCard poll={poll} t={t} />
      ) : (
        <PollGridCard poll={poll} />
      )}
    </div>
  );
}

export default PollView;
