import { POLL_LABELS, PollGroup } from '@pickme/core';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export const checkPollStarted = (startDate: string) => {
  const isStartDateValid = checkDateValid(startDate);
  if (isStartDateValid) {
    return false;
  }

  const isStartedPoll = dayjs().isSameOrAfter(dayjs(startDate));
  return isStartedPoll;
};

export const checkPollEnded = (endDate: string) => {
  const isEndDateValid = checkDateValid(endDate);
  if (isEndDateValid) {
    return false;
  }

  const isStartedPoll = dayjs().isSameOrBefore(dayjs(endDate));
  return isStartedPoll;
};

export const checkPollInBreakTime = (periods: { startDate: string; endDate: string }[]) => {
  const isInBreakTime = !periods.some((period) => {
    const { startDate, endDate } = period;
    return dayjs().isBetween(startDate, endDate);
  });

  return isInBreakTime;
};

export const parsePollByGroup = (group: PollGroup, t: TFunction) => {
  switch (group) {
    case PollGroup.Ready:
      return { color: 'warning', label: t(`user:poll.status.${group}`) };
    case PollGroup.Ongoing:
      return { color: 'primary', label: t(`user:poll.status.${group}`) };
    case PollGroup.Ended:
      return { color: 'error', label: t(`user:poll.status.${group}`) };
    case PollGroup.Counted:
      return { color: 'success', label: t(`user:poll.status.${group}`) };
    case PollGroup.Canceled:
      return { color: 'gray', label: t(`user:poll.status.${group}`) };
    case PollGroup.Paused:
      return { color: 'gray', label: POLL_LABELS[PollGroup.Paused] };
    default:
      return { color: '', label: '' };
  }
};

export const parsePeriodString = (startDate: string, endDate: string) => {
  const isStartDateValid = checkDateValid(startDate);
  const isEndDateValid = checkDateValid(endDate);

  const parsedStartDate = isStartDateValid
    ? dayjs(startDate).tz('Asia/Seoul').format('MM/DD HH:mm A')
    : '';
  const parsedEndDate = isEndDateValid
    ? dayjs(endDate).tz('Asia/Seoul').format('MM/DD HH:mm A')
    : '';

  const period = `${parsedStartDate} ~ ${parsedEndDate}`;

  return period;
};

export const checkDateValid = (date: string) => dayjs(date, 'YYYY-MM-DD').isValid();
