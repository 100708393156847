/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  rate: {
    base: 'As of {{date}}',
    count: {
      total: 'Total',
      participant: 'Participants',
      unit: 'votes',
    },
    rate: 'Turnout',
  },
  certificate: {
    Election: 'You have completed voting!',
    Survey: 'You have completed the survey!',
  },
  vote: {
    title: 'Are you sure you want to submit?',
    text: 'After the final submission, you cannot change the response.',
    confirm: 'Submit',
  },
  completedVoting: {
    main: 'You have completed voting!',
    download: 'You can download certificate from home page',
  },
  layout: {
    share: 'Share',
    download: 'Save',
  },
};
