import axios from 'axios';
import { useEffect } from 'react';
import { checkErrorExcludingFromSentryReport } from '@pickme/error';

import { useGetSession } from 'query-hooks/auth';

import {
  resetSentryUser,
  sendApiErrorReportToSentry,
  setSentryApiDetail,
  setSentryUser,
} from 'functions/sentry';

export const useSendSentryReport = () => {
  useEffect(() => {
    const interceptors = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!checkErrorExcludingFromSentryReport(error.code)) {
          setSentryApiDetail(error);
          sendApiErrorReportToSentry(error);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptors);
    };
  }, []);
};

export const useSentryUser = () => {
  const { data: user } = useGetSession();

  useEffect(() => {
    if (user?._id) {
      setSentryUser(user);
    } else {
      resetSentryUser();
    }
  }, [user?._id]);
};
