import { useState } from 'react';
import { Button, PlainButton, Text } from '@pickme/ui';
import { PollGroup } from '@pickme/core';
import { Link, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import { useSetRecoilState } from 'recoil';

import VotingCompletedModal from 'components/features/poll/modal/VotingCompletedModal';
import InstantCurrentStatusModal from 'components/features/poll/modal/InstantCurrentStatusModal';
import PollRateModal from 'components/features/poll/modal/RateModal';
import PollCertificateModal from 'components/features/poll/modal/CertificateModal';

import { useGetOrganization } from 'query-hooks/organization';

import { toast } from 'states/toast';

import { parsePeriodString } from 'functions/poll';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

import { Poll } from 'types/poll';
import type { TFunction } from 'i18next';

import PickmeLogo from 'resources/icons/poll-home/logo.png';
import RateIcon from 'resources/icons/poll-home/rate.png';
import ResultIcon from 'resources/icons/poll-home/result.png';
import CertificateIcon from 'resources/icons/poll-home/certificate.png';
import AnonymousIcon from 'resources/icons/poll-home/anonymous.png';
import RealNameIcon from 'resources/icons/poll-home/real-name.png';
import StopIcon from 'resources/icons/home/stop.png';

import './index.scss';

type Props = {
  poll?: Poll;
  organizationId: string;
  t: TFunction;
  language: string;
  pathname: string;
  isEmbedded?: boolean;
};

// SkeletonPollOverview와 동일한 html 구조를 가지고 있습니다. PollOverview의 구조나 className이 변경되면 Skeleton도 변경해야합니다.
function PollOverview({ poll, organizationId, t, language, pathname, isEmbedded }: Props) {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);

  const pollKindText =
    poll?.kind === 'Survey' ? t('user:poll.kind.survey') : t('user:poll.kind.election');
  const { data: organization } = useGetOrganization(organizationId);

  const [isInstantCurrentStatusModalVisible, setInstantCurrentStatusModalVisible] = useState(false);
  const [isRateModalVisible, setRateModalVisible] = useState(false);
  const [isCertificateModalVisible, setCertificateModalVisible] = useState(false);

  const isInstantPoll = poll?.hasInstantVoter;
  const isCanceledPoll = poll?.group === PollGroup.Canceled;
  const period = parsePeriodString(poll?.startDate || '', poll?.endDate?.date || '');
  const periods = poll?.periods
    .map(({ startDate, endDate }) => parsePeriodString(startDate, endDate))
    .join(`\n`);

  const isOpenableRate = poll?.isOpenPollRate && !isCanceledPoll && !isEmbedded;
  const isOpenableResult =
    poll?.isOpenPollResult && poll?.hasResult && !isCanceledPoll && !isEmbedded;
  const isOpenableCertificate = poll?.hasVoted && !isCanceledPoll && !isEmbedded;

  const isEnglish = language === 'en';

  return (
    <div className='poll-overview'>
      <div className={`poll-overview__logo `}>
        <img src={organization?.logo || PickmeLogo} alt='pickme' />
      </div>

      <div>
        <Text type='b1' fontWeight={600} className='poll-overview__title'>
          {poll?.name ?? ''}
          {(poll?.isPause || poll?.isInBreakTime) &&
            (poll?.group === PollGroup.Ongoing || poll?.group === PollGroup.Paused) && (
              <img src={StopIcon} alt='일시정지' />
            )}
        </Text>

        <Text type='b3' fontWeight={400} color='gray-400'>
          {periods}
        </Text>
      </div>

      {poll?.kind === 'Survey' && (
        <div className='poll-overview__anonymous-box'>
          <img src={poll?.hasOpenVotes ? RealNameIcon : AnonymousIcon} alt='icon' />
          <Text type='b3'>
            {t(`user:poll.survey.${poll?.hasOpenVotes ? 'realName' : 'anonymous'}`)}
          </Text>
        </div>
      )}

      {poll?.description && (
        <div className='poll-overview__description'>
          <Text type='b3' color='gray-400'>
            {ReactHtmlParser(poll?.description || '')}
          </Text>

          <Link to={`${pathname}/description`}>
            <Button variant='gray' size='xs' className='poll-overview__description__more'>
              <Text type='b4' fontWeight={300}>
                {t('user:poll.more')}
              </Text>
            </Button>
          </Link>
        </div>
      )}

      <div className='poll-overview__buttons'>
        <PlainButton
          className={`poll-overview__button ${isEnglish ? 'en' : ''}`}
          onClick={() => {
            if (isOpenableRate) {
              if (isInstantPoll) {
                setInstantCurrentStatusModalVisible(true);
              } else {
                setRateModalVisible(true);
              }
              return;
            }

            if (isEmbedded) {
              setToast({
                isVisible: true,
                message: '미리보기에서는 제공하지 않는 기능입니다',
                type: 'notification',
              });
              return;
            }

            if (isCanceledPoll) {
              setToast({
                isVisible: true,
                message: t('user:poll.errorMessages.canceled', { poll: pollKindText }),
                type: 'notification',
              });
              return;
            }

            setToast({
              isVisible: true,
              message: t(
                isInstantPoll
                  ? 'user:poll.errorMessages.currentStatus'
                  : 'user:poll.errorMessages.rate',
                { poll: pollKindText },
              ),
              type: 'notification',
            });
          }}
        >
          <img src={RateIcon} alt={t('user:poll.overview.rate') || ''} />
          <Text type='b3' color='gray-400'>
            {isInstantPoll ? t('user:poll.overview.currentStatus') : t('user:poll.overview.rate')}
          </Text>
        </PlainButton>

        <PlainButton
          className={`poll-overview__button ${isEnglish ? 'en' : ''}`}
          onClick={() => {
            if (isOpenableResult) {
              navigate(`/poll/${poll?._id}/result`);
              return;
            }

            if (isCanceledPoll) {
              setToast({
                isVisible: true,
                message: t('user:poll.errorMessages.canceled', { poll: pollKindText }),
                type: 'notification',
              });
              return;
            }

            if (isEmbedded) {
              setToast({
                isVisible: true,
                message: '미리보기에서는 제공하지 않는 기능입니다',
                type: 'notification',
              });
              return;
            }

            setToast({
              isVisible: true,
              message: poll?.isOpenPollResult
                ? t('user:poll.errorMessages.notCount', { poll: pollKindText })
                : t('user:poll.errorMessages.result', { poll: pollKindText }),
              type: poll?.isOpenPollResult ? 'warning' : 'notification',
            });
          }}
        >
          <img src={ResultIcon} alt={t('user:poll.overview.result') || ''} />
          <Text type='b3' color='gray-400'>
            {t('user:poll.overview.result')}
          </Text>
        </PlainButton>

        <PlainButton
          className={`poll-overview__button ${isEnglish ? 'en' : ''}`}
          onClick={() => {
            if (isOpenableCertificate) {
              setCertificateModalVisible(true);
              return;
            }

            if (poll?._id && EXCEPTIONAL_POLL_IDS.includes(poll._id)) {
              setToast({
                isVisible: true,
                message: '인증이 제공되지 않는 유형의 투표입니다.',
                type: 'notification',
              });
              return;
            }

            if (isEmbedded) {
              setToast({
                isVisible: true,
                message: '미리보기에서는 제공하지 않는 기능입니다',
                type: 'notification',
              });
              return;
            }

            if (isCanceledPoll) {
              setToast({
                isVisible: true,
                message: t('user:poll.errorMessages.canceled', { poll: pollKindText }),
                type: 'notification',
              });
              return;
            }

            setToast({
              isVisible: true,
              message: t('user:poll.errorMessages.notVoted', { poll: pollKindText }),
              type: 'warning',
            });
          }}
        >
          <img src={CertificateIcon} alt={t('user:poll.overview.certificate') || ''} />
          <Text type='b3' color='gray-400'>
            {t('user:poll.overview.certificate')}
          </Text>
        </PlainButton>
      </div>

      <VotingCompletedModal title={poll?.name || ''} period={period} />

      <InstantCurrentStatusModal
        id={poll?._id || ''}
        title={poll?.name || ''}
        period={period}
        endDate={poll?.endDate?.date || ''}
        isVisible={isInstantCurrentStatusModalVisible}
        onClose={() => setInstantCurrentStatusModalVisible(false)}
      />

      <PollRateModal
        id={poll?._id || ''}
        title={poll?.name || ''}
        period={period}
        endDate={poll?.endDate?.date || ''}
        isVisible={isRateModalVisible}
        onClose={() => setRateModalVisible(false)}
        pollGroup={poll?.group || PollGroup.Ready}
      />

      <PollCertificateModal
        id={poll?._id || ''}
        title={poll?.name || ''}
        pollKind={poll?.kind || ''}
        period={period}
        organizationName={organization?.name || ''}
        isVisible={isCertificateModalVisible}
        onClose={() => setCertificateModalVisible(false)}
      />
    </div>
  );
}

export default PollOverview;
