import crypto from 'crypto-js';
import { t } from 'i18next';

import { ScoreVoteValidator } from 'functions/validator/vote';

import { SubElection } from 'types/election';
import { Question } from 'types/survey';
import { VotingElectionForm, ElectionVote, VotingSurveyForm, SurveyAnswer } from 'types/vote';

export const checkElectionMovableNextStep = (
  elections: SubElection[],
  step: number,
  watch: (fieldName: string) => any,
): { isMovableNextStep: boolean; errorMessage?: string } => {
  const index = step - 1;
  const election = elections[index];

  const isChoiceElection = election?.kind === 'ChoiceElection';
  const isScoreElection = election?.kind === 'ScoreElection';
  const isPropsOrConsElection = election?.kind === 'ProsOrConsElection';
  const isRankElection = election?.kind === 'RankElection';

  const isVotedAbstention = watch(`votes.${index}.isAbstention`);

  if (isVotedAbstention) {
    return { isMovableNextStep: true };
  }

  if (isChoiceElection) {
    const requiredValueCount = election?.responseValidation.maxResponse;
    const isCompletedVote = watch(`votes.${index}.candidateOids`)?.length === requiredValueCount;

    return {
      isMovableNextStep: isCompletedVote,
      errorMessage: t('user:poll.errorMessages.choiceElection') || '',
    };
  }

  if (isScoreElection) {
    const { isMovableNextStep, errorMessage } = checkScorePollMovable(
      election,
      watch(`votes.${index}.votesPerCandidate`),
    );

    return {
      isMovableNextStep,
      errorMessage,
    };
  }

  if (isPropsOrConsElection) {
    const isCompletedVote = watch(`votes.${index}.votesPerCandidate`)?.every(
      ({ value }: { value: string }) => !!value,
    );

    const isOpenAbstention = elections[step - 1]?.responseValidation.allowAbstentionVote;

    return {
      isMovableNextStep: isCompletedVote,
      errorMessage:
        t('user:poll.errorMessages.prosOrConsElection', {
          abstention: isOpenAbstention ? `, ${t('user:poll.abstention')}` : '',
        }) || '',
    };
  }

  if (isRankElection) {
    const requiredValueCount = election?.responseValidation.rankLimit;
    const isCompletedVote =
      watch(`votes.${index}.candidateOidsForRank`)?.length === requiredValueCount;

    return {
      isMovableNextStep: isCompletedVote,
      errorMessage: t('user:poll.errorMessages.rank') || '',
    };
  }

  return { isMovableNextStep: false };
};

export const checkSurveyMovableNextStep = (
  questions: Question[],
  step: number,
  watch: (fieldName: string) => any,
): { isMovableNextStep: boolean; errorMessage?: string } => {
  const index = step - 1;
  const question = questions[index];

  const isRequired = question?.isRequired;
  const isRadioQuestion = question?.kind === 'RadioQuestion';
  const isCheckboxQuestion = question?.kind === 'CheckboxQuestion';
  const isTextQuestion = question?.kind === 'TextQuestion';
  const isScaleQuestion = question?.kind === 'ScaleQuestion';
  const isRankQuestion = question?.kind === 'RankQuestion';
  const isScoreQuestion = question?.kind === 'ScoreQuestion';

  if (isRadioQuestion || isScaleQuestion) {
    const isAnswered = !!watch(`answers.${index}.answer`);
    return {
      isMovableNextStep: isRequired ? isAnswered : true,
      errorMessage: t('user:poll.errorMessages.required') || '',
    };
  }

  if (isCheckboxQuestion) {
    const answersLength = watch(`answers.${index}.answers`)?.length;
    const isAnswered = answersLength > 0;
    const validation = question.responseValidation?.validation;
    const threshold = question.responseValidation?.threshold;

    const isValid =
      (validation === 'lte' && isAnswered) || (validation === 'eq' && answersLength === threshold);

    if (isRequired) {
      const errorMessage = isAnswered
        ? t('user:poll.surveyKind.checkbox.text', {
            threshold,
          })
        : t('user:poll.errorMessages.required');

      return {
        isMovableNextStep: isValid,
        errorMessage: errorMessage || '',
      };
    }

    return {
      isMovableNextStep: !isAnswered || isValid,
      errorMessage:
        t('user:poll.surveyKind.checkbox.text', {
          threshold,
        }) || '',
    };
  }

  if (isTextQuestion) {
    const isAnswered = !!watch(`answers.${index}.answer.contents`);
    return {
      isMovableNextStep: isRequired ? isAnswered : true,
      errorMessage: t('user:poll.errorMessages.required') || '',
    };
  }

  if (isRankQuestion) {
    const requiredValueCount = question?.responseValidation?.rankLimit;
    const answersLength = watch(`answers.${index}.subAnswerOidsForRank`)?.length;
    const isAnswered = answersLength > 0;
    const isCompletedVote = answersLength === requiredValueCount;

    return {
      isMovableNextStep: !isRequired && !isAnswered ? true : isCompletedVote,
      errorMessage: isRequired
        ? t('user:poll.errorMessages.required') || ''
        : t('user:poll.errorMessages.rank') || '',
    };
  }

  if (isScoreQuestion) {
    const { isMovableNextStep, errorMessage } = checkScorePollMovable(
      question,
      watch(`answers.${index}.subAnswers`),
      isRequired,
    );

    return {
      isMovableNextStep,
      errorMessage,
    };
  }

  return { isMovableNextStep: false };
};

export const checkScorePollMovable = (
  poll: SubElection | Question,
  values: any[],
  isRequired?: boolean,
) => {
  const isElection = poll.kind.includes('Election');

  const maxScore = poll.responseValidation?.maxScore || 0;
  const minScore = poll.responseValidation?.minScore || 0;

  if (!isRequired) {
    const isNotAnswered = values?.every(({ value }: { value: string }) => !value);
    if (isNotAnswered) {
      return { isMovableNextStep: true };
    }
  }

  const isCompletedVote = values?.every(({ value }: { value: string }) => !!value);

  if (!isCompletedVote) {
    return {
      isMovableNextStep: false,
      errorMessage: isElection
        ? t('user:poll.errorMessages.score') || ''
        : t('user:poll.errorMessages.required') || '',
    };
  }

  const isValidVote = values?.every(({ value }: { value: string }) => {
    const isValid = ScoreVoteValidator(value, maxScore, minScore);
    return isValid;
  });

  return {
    isMovableNextStep: isValidVote,
    errorMessage: t('user:poll.errorMessages.invalidScore', { minScore, maxScore }) || '',
  };
};

export const parseElectionVote = (data: VotingElectionForm) => {
  const { voterOid, pollId, votes } = data;

  const parsedVotes = votes.map((vote) => {
    const parsedVote = { subElectionOid: vote.subElectionOid } as ElectionVote;

    if (!vote.isAbstention) {
      if (vote.candidateOids) {
        parsedVote.candidateOids = vote.candidateOids;
      } else if (vote.candidateOidsForRank) {
        const rankVotes = vote.candidateOidsForRank?.map((oid, index) => ({
          candidateOid: oid,
          value: `${index + 1}`,
        }));

        parsedVote.votesPerCandidate = rankVotes;
      } else {
        parsedVote.votesPerCandidate = vote.votesPerCandidate;
      }
    }

    return parsedVote;
  });

  const votesAddedChecksum = parsedVotes.map(({ subElectionOid, ...voteInfo }) => {
    const votedTarget = JSON.stringify(voteInfo);
    const checksum = makeVotingChecksum({ voterOid, pollId, votedTarget });

    return { checksum, pollId, subElectionOid, ...voteInfo };
  });

  return { voterOid, votes: votesAddedChecksum };
};

export const parseSurveyVote = (data: VotingSurveyForm) => {
  const { organizationId, voterOid, pollId, answers } = data;

  const parsedAnswers = answers.map((answer) => {
    if (answer.answer !== undefined) {
      // Radio Answer or Scale Answer
      if (typeof answer.answer === 'string' && !answer.answer) {
        return null;
      }

      // Text Answer
      if (typeof answer.answer === 'object' && !answer.answer.contents) {
        return null;
      }
    }

    // Checkbox Answer
    if (answer.answers && answer.answers.length === 0) {
      return null;
    }

    // Rank Answer
    if (answer.subAnswerOidsForRank) {
      if (answer.subAnswerOidsForRank.length === 0) {
        return null;
      }

      const rankAnswers = answer.subAnswerOidsForRank?.map((oid, index) => ({
        subQuestionOid: oid,
        value: `${index + 1}`,
      }));

      return { questionId: answer.questionId, subAnswers: rankAnswers };
    }

    // Score Answer
    if (answer.subAnswers && !answer.subAnswers?.[0].value) {
      return null;
    }

    return answer;
  });

  const filteredAnswers = parsedAnswers.filter((answer) => answer !== null) as SurveyAnswer[];

  const checksum = makeVotingChecksum({
    voterOid,
    pollId,
    votedTarget: JSON.stringify(filteredAnswers),
  });

  return {
    organizationId,
    voterOid,
    votes: [{ checksum, pollId, answers: filteredAnswers }],
  };
};

export const makeVotingChecksum = ({
  voterOid,
  pollId,
  votedTarget,
}: {
  voterOid: string;
  pollId: string;
  votedTarget: string;
}) => {
  const SALT = import.meta.env.VITE_VOTE_SALT;
  const message = `${voterOid}${pollId}${votedTarget}${SALT}`;
  const checksum = crypto.SHA256(message).toString(crypto.enc.Hex);

  return checksum;
};
