import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

export const setExceptionalVote = (pollId: string) => {
  if (!EXCEPTIONAL_POLL_IDS.includes(pollId)) {
    return;
  }

  const exceptionalVotes = getExceptionalVotes();
  exceptionalVotes.push(pollId);

  window.localStorage.setItem('exceptional-votes', JSON.stringify(exceptionalVotes));
};

export const getExceptionalVotes = () => {
  const rawExceptionalVotes = window.localStorage.getItem('exceptional-votes');
  let exceptionalVotes: string[] = [];

  if (rawExceptionalVotes) {
    try {
      const value = JSON.parse(rawExceptionalVotes);

      if (Array.isArray(value)) {
        exceptionalVotes = value;
      }
    } catch {
      exceptionalVotes = [];
    }
  }

  return exceptionalVotes;
};
