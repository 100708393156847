import { Text } from '@pickme/ui';
import { Link } from 'react-router-dom';

import { SubElection } from 'types/election';
import type { TFunction } from 'i18next';

import Arrow from 'resources/icons/common/right-arrow.png';

import './index.scss';

type Props = { polls?: SubElection[]; t: TFunction; pathname: string };

function SubElections({ polls = [], t, pathname }: Props) {
  return (
    <div className='sub-elections'>
      <Text type='b1' fontWeight={600}>
        {t('user:poll.electionInfo')}
      </Text>

      <div className='sub-elections__items'>
        {polls.map((poll) => (
          <Link to={`${pathname}/${poll._id}/candidates`} key={poll._id}>
            <Text type='b3' className='sub-elections__item'>
              <div className='sub-elections__item__name'>{poll.title}</div>
              <img src={Arrow} alt='arrow' />
            </Text>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SubElections;
