import { useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { createGetOrganizationOidRequest, createGetOrganizationRequest } from 'apis/organization';

import { toast } from 'states/toast';

import { handleSessionError } from 'utils/error-parser';

import type { AxiosError } from 'axios';

export const useGetOrganization = (id: string) => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useQuery(
    ['organization', id],
    async () => {
      const { data } = await createGetOrganizationRequest(id);
      return data;
    },
    {
      enabled: !!id,
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useGetOrganizationOid = (alias?: string) =>
  useQuery(['organization-oid', alias], async () => {
    if (!alias) {
      throw new Error();
    }

    const { data } = await createGetOrganizationOidRequest(alias);
    return data._id;
  });
