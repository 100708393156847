import { useEffect, useState } from 'react';
import { Button, PlainButton, Text } from '@pickme/ui';
import { t } from 'i18next';

import { useSignOut } from 'query-hooks/auth';

import BulbIcon from 'resources/icons/home/bulb.svg';
import DownArrowIcon from 'resources/icons/home/gray-down-arrow.svg';

import './index.scss';

type Props = {
  isOpen: boolean;
};

function HomeCallout({ isOpen }: Props) {
  const [opened, setOpened] = useState(false);
  const { mutate: signOut } = useSignOut();

  useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);

  return (
    <div className='home-callout'>
      <div className='home-callout__header'>
        <div className='home-callout__title'>
          <img src={BulbIcon} alt='전구 아이콘' />
          <Text type='b1' className='home-banners__index'>
            {t('user:home.callout.title')}
          </Text>
        </div>

        <PlainButton onClick={() => setOpened((prev) => !prev)}>
          <img
            src={DownArrowIcon}
            alt={opened ? 'close' : 'open'}
            className={!opened ? 'home-callout__arrow' : 'home-callout__arrow--opened'}
          />
        </PlainButton>
      </div>

      <div className={`home-callout__body ${opened ? 'opened' : ''}`}>
        <div className='home-callout__list'>
          <div className='home-callout__list__item'>
            <div className='home-callout__list__marker' />

            <Text type='b3'>
              {t('user:home.callout.description.0')}

              <span className='home-callout__description--bold'>
                {t('user:home.callout.description.1')}
              </span>

              {t('user:home.callout.description.2')}

              <span className='home-callout__description--bold'>
                {t('user:home.callout.description.3')}
              </span>

              {t('user:home.callout.description.4')}
            </Text>
          </div>

          <div className='home-callout__list__item'>
            <div className='home-callout__list__marker' />

            <Text type='b3'>{t('user:home.callout.description.5')}</Text>
          </div>
        </div>

        <Button
          size='md'
          width='100%'
          className='home-callout__body__button'
          onClick={() => signOut(true)}
        >
          {t('user:home.callout.button')}
        </Button>
      </div>
    </div>
  );
}

export default HomeCallout;
