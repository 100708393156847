import { POLL_LABELS, PollGroup } from '@pickme/core';

export const STATS: { title: string; alias: PollGroup }[] = Object.entries(PollGroup).map(
  ([_, value]) => ({ title: POLL_LABELS[value], alias: value }),
);

export const ELECTION_KIND_BADGES: { [key: string]: { variant: string; text: string } } = {
  ChoiceElection: { variant: 'vivid-blue', text: '선택 투표' },
  ProsOrConsElection: { variant: 'vivid-green', text: '찬반 투표' },
  RankElection: { variant: 'vivid-yellow', text: '순위 투표' },
  ScoreElection: { variant: 'vivid-pink', text: '점수 투표' },
};

export const EXCEPTIONAL_POLL_IDS = [
  '668de39a4889e9a5aa840bb8',
  '668e6a74bc1f2b010e50e8cc',
  '668ddf154b0004054c71d957',
  '668de39a4889e9a5aa840bb8',
  '668de39c4889e9a5aa840bba',
  '668de3b74889e9a5aa840bbc',
];
