import { Toast as PickmeToast } from '@pickme/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { toast } from 'states/toast';

import type { CSSProperties } from 'react';

import './index.scss';

function Toast() {
  const { isVisible, type, message } = useRecoilValue(toast);
  const setToast = useSetRecoilState(toast);

  const toastWidth = {
    '--toast-width': `${32 + message.length * (message.includes('.') ? 11 : 12.3)}px`,
  } as CSSProperties;

  return (
    <div className='toast' style={toastWidth}>
      <PickmeToast
        isVisible={isVisible}
        type={type}
        message={message}
        onHide={() => setToast({ isVisible: false, message, type })}
      />
    </div>
  );
}

export default Toast;
