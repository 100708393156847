import { SecondaryBadge, Text } from '@pickme/ui';

// PollCard와 동일한 html 구조를 가지고 있습니다. PollCard의 구조나 className이 변경되면 SkeletonCard도 변경해야합니다.
// Skeleton 안에 있는 텍스트는 UI상 보이지 않지만, 높이를 지정해주기위해 필요합니다.

function PollListCardSkeleton() {
  return (
    <div className='poll-card'>
      <div className='poll-card__body'>
        <div className='poll-card__body__labels'>
          <SecondaryBadge className='skeleton'>라벨</SecondaryBadge>
        </div>

        <Text fontWeight={500} className='poll-card__body__name skeleton'>
          스켈레톤 선거
        </Text>

        <Text type='b4' fontWeight={400} color='gray-400' className='skeleton'>
          스켈레톤 선거 기간
        </Text>
      </div>
    </div>
  );
}

export default PollListCardSkeleton;
