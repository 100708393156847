import PollHome from 'components/features/poll/Home';

import { useEmbeddedPoll } from 'hooks/useEmbed';

function EmbeddedPollHome() {
  const poll = useEmbeddedPoll();

  return <PollHome poll={poll} isVisibleVoteButton isEmbedded />;
}

export default EmbeddedPollHome;
