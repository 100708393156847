import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { OTPSignInBody, Session } from 'types/auth';

const APIS: Apis = {
  getSession: { method: 'GET', url: '/api/auth/profile' },
  OTPSignIn: { method: 'POST', url: '/api/auth/voter/login' },
  agree: { method: 'PUT', url: '/api/voter/agree' },
  signOut: { method: 'GET', url: '/api/auth/logout' },
};

export const createGetSessionRequest = () =>
  createHttpRequest<Session>({ ...APIS.getSession, headers: { 'Cache-Control': 'no-cache' } });

export const createOTPSignInRequest = (form: OTPSignInBody) =>
  createHttpRequest<Session>({ ...APIS.OTPSignIn, data: form });

export const createAgreeComplianceRequest = () => createHttpRequest(APIS.agree);

export const createSignOutRequest = () =>
  createHttpRequest<{ result: boolean }>({ ...APIS.signOut });
