import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import VoteElection from 'components/features/poll/VoteElection';

import { toast } from 'states/toast';
import { votingCompletedModal } from 'states/modal';

import { useEmbeddedPoll } from 'hooks/useEmbed';

function EmbeddedVoteElection() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setToast = useSetRecoilState(toast);
  const setSuccessModal = useSetRecoilState(votingCompletedModal);

  const poll = useEmbeddedPoll();

  const onSubmit = () => {
    setSuccessModal(true);
    setToast({ isVisible: true, type: 'success', message: '미리보기 기능입니다' });
    navigate(pathname.replace('/vote/election', ''));
  };

  return <VoteElection poll={poll} onSubmit={onSubmit} voterOid='' />;
}

export default EmbeddedVoteElection;
