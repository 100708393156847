import { useParams } from 'react-router-dom';

import VoteElection from 'components/features/poll/VoteElection';

import { useGetSession } from 'query-hooks/auth';
import { useGetPoll } from 'query-hooks/poll';
import { useVote } from 'query-hooks/vote';

import { useCheckVotable } from 'hooks/useCheckVotable';

import { VotingSurveyForm } from 'types/vote';

function VoteElectionContainer() {
  const { id = '' } = useParams<{ id: string }>();

  const { data: auth } = useGetSession();
  const { data: poll, isError } = useGetPoll(id);

  useCheckVotable({ isError, poll, pollId: id, kind: 'Election' });
  const { mutate: vote, isLoading } = useVote();

  const onSubmit = (voteData: VotingSurveyForm) => vote({ data: voteData });

  return (
    <VoteElection
      poll={poll}
      voterOid={auth?._id || ''}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
}

export default VoteElectionContainer;
