import { Modal, ModalBody, ModalHeader } from '@pickme/ui';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  src: string;
  alt: string;
};

function ImageModal({ isVisible, onClose, src, alt }: Props) {
  return (
    <Modal isVisible={isVisible} onClose={onClose} rounded className='image-modal'>
      <ModalHeader onClose={onClose} rounded />

      <ModalBody>
        <img src={src} alt={alt} className='image-modal__image' />
      </ModalBody>
    </Modal>
  );
}

export default ImageModal;
