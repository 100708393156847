import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Poll, PollKind } from 'types/poll';

type Props = { isError: boolean; poll?: Poll; pollId: string; kind: PollKind };

export const useCheckVotable = ({ isError, poll, pollId, kind }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate(`/poll/${pollId}`, { replace: true });
      return;
    }

    if (!poll?._id) {
      return;
    }

    if (poll?.kind !== kind) {
      navigate(`/poll/${pollId}`, { replace: true });
      return;
    }

    const isPollNotOnGoing = poll?.stage === 'pre' || poll?.stage === 'post';

    if (poll?.hasVoted || isPollNotOnGoing) {
      navigate(`/poll/${pollId}`, { replace: true });
      return;
    }

    if (poll?.isInBreakTime || poll?.isPause) {
      navigate(`/poll/${pollId}`, { replace: true });
    }
  }, [poll?._id]);
};
