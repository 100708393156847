import { PlainButton, Text } from '@pickme/ui';
import { useState } from 'react';
import { useSignOut } from 'query-hooks/auth';

import ConfirmModal from 'components/common/modal/ConfirmModal';
import ServiceModal from 'components/features/home/ServiceModal';

import { useSetRecoilState } from 'recoil';
import { confirmModal } from 'states/modal';

import type { i18n as i18nType, TFunction } from 'i18next';

import PickMe from 'resources/images/home/logo.svg';

import './index.scss';

function HomeHeader({ t, i18n }: { t: TFunction; i18n: i18nType }) {
  const setConfirmModalVisible = useSetRecoilState(confirmModal);
  const [isServiceModalVisible, setServiceModalVisible] = useState(false);
  const [isToggled, setToggled] = useState(false);

  const setLanguage = (language: 'ko' | 'en') => {
    i18n.changeLanguage(language);
  };
  const isKoSelected = i18n.resolvedLanguage === 'ko';
  const isEnSelected = i18n.resolvedLanguage === 'en';

  const { mutate: signOut } = useSignOut();

  return (
    <header className={`home-header ${isToggled ? 'toggled' : ''}`}>
      <div className='home-header-bar'>
        <PlainButton
          className={`home-header-bar__menu-button ${isToggled ? 'toggled' : ''}`}
          type='button'
          onClick={() => setToggled((prev) => !prev)}
          onBlur={(event) => {
            const targetedClassName = event.relatedTarget?.className;

            if (!targetedClassName?.includes('home-header-below')) {
              setToggled(false);
            }
          }}
        >
          <span className='line top' />
          <span className='line middle' />
          <span className='line bottom' />
        </PlainButton>

        <img src={PickMe} alt='pick me' className='home-header-bar__logo' />

        <div className='home-header-bar__decoration-empty-box' />
      </div>

      <ul className={`home-header-below ${isToggled ? 'home-header-below--toggled' : ''}`}>
        <li className='home-header-below__item'>
          <PlainButton onClick={() => setLanguage('ko')} disabled={isKoSelected}>
            <Text type='b3' color={isKoSelected ? 'black' : 'gray-300'}>
              KOR
            </Text>
          </PlainButton>
          <span>|</span>
          <PlainButton onClick={() => setLanguage('en')} disabled={isEnSelected}>
            <Text type='b3' color={isEnSelected ? 'black' : 'gray-300'}>
              ENG
            </Text>
          </PlainButton>
        </li>

        <PlainButton
          onClick={() => setServiceModalVisible(true)}
          className='home-header-below__item'
        >
          <li>
            <Text type='b3'>{t('user:home.header.menu.contact')}</Text>
          </li>
        </PlainButton>

        <PlainButton
          onClick={() => setConfirmModalVisible(true)}
          className='home-header-below__item'
        >
          <li>
            <Text type='b3' color='error-500'>
              {t('user:home.header.menu.logout')}
            </Text>
          </li>
        </PlainButton>
      </ul>

      <ServiceModal
        isVisible={isServiceModalVisible}
        onClose={() => setServiceModalVisible(false)}
      />

      <ConfirmModal
        title={t('user:home.header.logoutModal.title')}
        buttons={{ confirm: t('user:home.header.logoutModal.confirm') || '' }}
        onClick={() => signOut(true)}
      />
    </header>
  );
}

export default HomeHeader;
