/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  signin: {
    title: '인증번호로 로그인하기',
    idInput: {
      placeholder: {
        email: '이메일',
        phone: '전화번호',
        all: '이메일 또는 전화번호',
      },
      errorMessage: {
        email: '이메일을 확인해주세요',
        phone: '전화번호를 확인해주세요',
        all: '이메일 또는 전화번호를 확인해주세요',
      },
    },
    terms: {
      all: '모두 동의합니다.',
      service: {
        bold1: '이용약관',
        text: '에 동의합니다 (필수)',
        bold2: '',
      },
      privacy: {
        bold1: '개인정보 처리방침',
        text: '에 동의합니다 (필수)',
        bold2: '',
      },
    },
    termsModal: {
      agree: '동의하기',
      cancel: '취소',
    },
    button: '인증하기',
    description: '인증번호를 발송했어요\n받으신 인증번호를 입력해주세요',
    retry: {
      text: '인증번호를 받지 못하셨나요? ',
      button: '다시 시도해주세요',
    },
  },
};
