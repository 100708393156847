import { useQueryParams } from 'hooks/useQueryParams';
import { PollQuery, PollViewMode } from 'types/poll';

type SearchParams = Omit<PollQuery, 'page'> & {
  mode: PollViewMode;
  alias: string;
};

type SetPollParams = (query: SearchParams) => void;

export const useHomeParams = (query: SearchParams): [SearchParams, SetPollParams] => {
  const [params, setParams] = useQueryParams({ ...query });

  const setPollParam = (newQuery: SearchParams) => {
    setParams({ ...newQuery });
  };

  const aliasValue = params.alias || query.alias || '';
  const parsedAliasValue = aliasValue.replace(/[^a-zA-Z0-9]/g, '');

  const parsedParams: SearchParams & {
    mode: string;
  } = {
    alias: parsedAliasValue,
    mode: params.mode || query.mode,
  };

  if (!Object.values(PollViewMode).includes(parsedParams.mode)) {
    parsedParams.mode = PollViewMode.Table;
  }

  return [parsedParams, setPollParam];
};
