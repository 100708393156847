import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, Text } from '@pickme/ui';
import { complianceModal } from 'states/modal';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useAgreeCompliance, useGetSession } from 'query-hooks/auth';

import ComplianceIcon from 'resources/icons/compliance/compliance.png';
import Bg1 from 'resources/icons/compliance/bg1.png';
import Bg2 from 'resources/icons/compliance/bg2.png';

import './index.scss';

function ComplianceModal() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { data: session, isSuccess } = useGetSession();
  const hasAgreed = session?.hasAgreed;
  const [isModalVisible, setModalVisible] = useRecoilState(complianceModal);

  const { mutate } = useAgreeCompliance();

  useEffect(() => {
    if (pathname.includes('embed') || pathname.includes('admin-survey')) {
      return;
    }

    if (isSuccess && !hasAgreed) {
      setModalVisible(true);
    }
  }, [hasAgreed, isSuccess]);

  const onAgree = () => {
    mutate();
  };

  return (
    <Modal isVisible={isModalVisible} rounded className='compliance-modal' size='sm'>
      <img src={Bg1} alt='background' className='compliance-modal__background--1' />
      <img src={Bg2} alt='background' className='compliance-modal__background--2' />

      <ModalBody>
        <img src={ComplianceIcon} alt='compliance' className='compliance-modal__icon' />

        <Text type='b1' fontWeight={600} color='primary-500'>
          {t('user:modal.compliance.title')}
        </Text>

        <div className='compliance-modal__description'>
          <Text type='b3' color='gray-400' fontWeight={400}>
            {t('user:modal.compliance.descriptions.0')}
          </Text>
          <Text type='b3' color='gray-400' fontWeight={400}>
            {t('user:modal.compliance.descriptions.1')}
          </Text>
        </div>

        <ul className='compliance-modal__items'>
          <li className='compliance-modal__item'>
            <Text type='b4' color='gray-400' fontWeight={400}>
              {t('user:modal.compliance.terms.0')}
            </Text>
          </li>
          <li className='compliance-modal__item'>
            <Text type='b4' color='gray-400' fontWeight={400}>
              {t('user:modal.compliance.terms.1')}
            </Text>
          </li>
        </ul>

        <Text type='b4' color='gray-400' fontWeight={400} className='compliance-modal__description'>
          {t('user:modal.compliance.agree.text1')}
          <span className='compliance-modal__text-black'>
            {t('user:modal.compliance.agree.bold1')}
          </span>
          {t('user:modal.compliance.agree.text2')}
        </Text>
      </ModalBody>

      <ModalActions
        rounded
        buttons={[
          {
            text: t('user:modal.compliance.button'),
            onClick: onAgree,
          },
        ]}
      />
    </Modal>
  );
}

export default ComplianceModal;
