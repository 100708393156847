import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';

import {
  createSendOTPByEmail,
  createSendOTPBySMS,
  createVerifyEmail,
  createVerifySMS,
} from 'apis/otp';

import { toast } from 'states/toast';

import type { AxiosError } from 'axios';

export const useSendOTPByEmail = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (email: string) => {
      await createSendOTPByEmail(email);
      return { email };
    },
    {
      onError: (error: AxiosError) => {
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useVerifyEmail = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ email, code }: { email: string; code: string }) => {
      const { data } = await createVerifyEmail(email, code);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useSendOTPBySMS = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async (phoneNumber: string) => {
      const parsedPhoneNumber = phoneNumber.replaceAll('-', '');
      await createSendOTPBySMS(parsedPhoneNumber);
      return { phoneNumber };
    },
    {
      onError: (error: AxiosError) => {
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useVerifySMS = () => {
  const setToast = useSetRecoilState(toast);

  return useMutation(
    async ({ phoneNumber, code }: { phoneNumber: string; code: string }) => {
      const parsedPhoneNumber = phoneNumber.replaceAll('-', '');
      const { data } = await createVerifySMS(parsedPhoneNumber, code);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};
