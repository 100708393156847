import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { AuthMethod } from 'types/auth';
import { Poll, PollResult, PollRate, Voter } from 'types/poll';

const APIS: Apis = {
  getPolls: { method: 'GET', url: '/api/poll' },
  getPollStatistics: { method: 'GET', url: '/api/poll/statistic' },
  getPoll: { method: 'GET', url: '/api/poll/:id' },
  getPollRate: { method: 'GET', url: '/api/poll/:id/rate' },
  getPollResult: { method: 'GET', url: '/api/poll/:id/result' },
  getVoter: { method: 'GET', url: '/api/poll/:id/voter' },
  registerAllPollsAsVoter: { method: 'POST', url: '/api/poll/voterbook/instant' },
  getAuthMethods: { method: 'GET', url: '/api/poll/:pollId/voter-auth-method' },
};

export const createGetPollsRequest = ({ page }: { page: number }) =>
  createHttpRequest<{ polls: Poll[]; endPage: number }>({
    ...APIS.getPolls,
    query: { size: 10, page },
  });

export const createGetPollRequest = (id: string) =>
  createHttpRequest<Poll>({
    ...APIS.getPoll,
    params: { id },
  });

export const createGetPollRateRequest = (id: string) =>
  createHttpRequest<PollRate>({
    ...APIS.getPollRate,
    params: { id },
  });

export const createGetPollResult = (id: string) =>
  createHttpRequest<PollResult>({
    ...APIS.getPollResult,
    params: { id },
  });

export const createGetVoter = (id: string) =>
  createHttpRequest<Voter>({
    ...APIS.getVoter,
    params: { id },
  });

export const createRegisterAllPollsAsVoterRequest = (alias: string) =>
  createHttpRequest({
    ...APIS.registerAllPollsAsVoter,
    data: { organAlias: alias },
  });

export const createGetAuthMethodsRequest = (pollId: string) =>
  createHttpRequest<{ voterAuthMethod: AuthMethod[] }>({
    ...APIS.getAuthMethods,
    params: {
      pollId,
    },
  });
