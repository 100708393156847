import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { getTerms } from 'functions/terms';
import { useTranslation } from 'react-i18next';

import './index.scss';

type Props = {
  isVisible: boolean;
  onHide: () => void;
  onCheckTerm: Function;
  kind: 'service' | 'privacy';
};

function TermModal({ isVisible, onHide, onCheckTerm, kind }: Props) {
  const { t } = useTranslation();

  const onAgree = () => {
    onCheckTerm();
    onHide();
  };

  const TERMS = getTerms(t);
  const { title, contents } = TERMS[kind];

  return (
    <Modal isVisible={isVisible} onClose={onHide} className='term-modal' rounded>
      <ModalHeader title={title} onClose={onHide} hideCloseButton />

      <ModalBody>
        <div className='term-modal__terms'>
          {contents.map(({ header, body }, index) => (
            <div key={`term-modal-${index}`}>
              <Text
                type='b3'
                fontWeight={700}
                color='gray-400'
                className='term-modal__terms__title'
              >
                {header}
              </Text>
              <Text type='b3' color='gray-400' className='term-modal__terms__description'>
                {body}
              </Text>
            </div>
          ))}
        </div>
      </ModalBody>

      <ModalActions
        buttons={[
          { text: t('user:auth.signin.termsModal.agree'), onClick: onAgree },
          { text: t('user:auth.signin.termsModal.cancel'), onClick: onHide, variant: 'gray' },
        ]}
        rounded
      />
    </Modal>
  );
}

export default TermModal;
