import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';

const APIS: Apis = {
  sendOTPByEmail: { method: 'POST', url: '/api/otp/email' },
  verifyEmail: { method: 'POST', url: '/api/otp/email/verifying' },
  sendOTPBySMS: { method: 'POST', url: '/api/otp/sms' },
  verifySMS: { method: 'POST', url: '/api/otp/sms/verifying' },
};

export const createSendOTPByEmail = (email: string) =>
  createHttpRequest<{
    message: 'ok';
  }>({ ...APIS.sendOTPByEmail, data: { email } });

export const createVerifyEmail = (email: string, otp: string) =>
  createHttpRequest<{ token: string }>({ ...APIS.verifyEmail, data: { email, otp } });

export const createSendOTPBySMS = (phoneNumber: string) =>
  createHttpRequest<{
    message: 'ok';
  }>({ ...APIS.sendOTPBySMS, data: { phoneNumber } });

export const createVerifySMS = (phoneNumber: string, otp: string) =>
  createHttpRequest<{ token: string }>({ ...APIS.verifySMS, data: { phoneNumber, otp } });
