import { omit } from 'lodash-es';

import { createHttpRequest } from 'apis/common';

import { Apis } from 'types/api';
import { VotingElectionRequest, VotingInstantSurveyRequest, VotingSurveyRequest } from 'types/vote';

export const VOTE_APIS: Apis = {
  votePoll: { method: 'POST', url: '/api/vote/' },
  voteToExceptionalPoll: { method: 'POST', url: '/api/vote/uncertified' },
  voteInstantSurvey: { method: 'POST', url: '/api/vote/instant' },
};

export const createVoteElectionRequest = (voteData: VotingElectionRequest) =>
  createHttpRequest({ ...VOTE_APIS.votePoll, data: voteData });

export const createVoteSurveyRequest = (voteData: VotingSurveyRequest) =>
  createHttpRequest({ ...VOTE_APIS.votePoll, data: voteData });

export const createVoteExceptionalElectionRequest = (voteData: VotingElectionRequest) =>
  createHttpRequest({
    ...VOTE_APIS.voteToExceptionalPoll,
    data: { votes: voteData.votes.map((vote) => omit(vote, 'checksum')) },
  });

export const createVoteExceptionalSurveyRequest = (voteData: VotingSurveyRequest) =>
  createHttpRequest({
    ...VOTE_APIS.voteToExceptionalPoll,
    data: { votes: voteData.votes.map((vote) => omit(vote, 'checksum')) },
  });

export const createVoteInstantSurveyRequest = (voteData: VotingInstantSurveyRequest) =>
  createHttpRequest({ ...VOTE_APIS.voteInstantSurvey, data: voteData });
