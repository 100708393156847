import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Text } from '@pickme/ui';

import ElectionImage from 'components/features/poll/vote-card/ElectionImage';

import { SubElection } from 'types/election';
import { VotingElectionForm } from 'types/vote';
import type { TFunction } from 'i18next';

import UnvotedStamp from 'resources/icons/poll-home/unvote-stamp.png';
import VotedStamp from 'resources/icons/poll-home/vote-stamp.png';

import './index.scss';

type Props = { election: SubElection; index: number; t: TFunction };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function ChoiceElectionVoteCard({ election, index, t }: Props) {
  const { register, control, setValue, watch } = useFormContext<VotingElectionForm>();
  const fieldName = `votes.${index}.candidateOids` as const;

  const [candidates] = useState<{ _id: string; title: string; members?: { fileUrl?: string }[] }[]>(
    election.candidates || [],
  );

  const { title, responseValidation } = election;
  const { allowAbstentionVote = true, maxResponse = 1 } = responseValidation;

  const abstentionId = `${election._id}-abstention`;
  const abstentionCandidate = { _id: abstentionId, title: t('user:poll.abstention'), members: [] };
  const parsedCandidates = allowAbstentionVote ? [...candidates, abstentionCandidate] : candidates;

  return (
    <div className='choice-election-vote-card'>
      <div className='vote-card__label'>
        <SecondaryBadge>{t('user:poll.electionKind.choice.label')}</SecondaryBadge>
        <Text type='b4' color='gray-400'>
          {t('user:poll.electionKind.choice.text', { response: maxResponse })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
      </Text>

      <div className='vote-card__items'>
        {parsedCandidates.map((candidate) => (
          <Controller
            key={candidate._id}
            name={fieldName}
            control={control}
            rules={{
              validate: (items) => items?.length === maxResponse,
            }}
            defaultValue={[]}
            render={({ field }) => {
              const isAbstentionSelected =
                candidate._id === abstentionId && !!watch(`votes.${index}.isAbstention`);
              const isSelected = field.value?.includes(candidate._id) || isAbstentionSelected;

              return (
                <VoteItem
                  image={candidate?.members?.[0]?.fileUrl || ''}
                  title={candidate?.title || ''}
                  id={candidate._id}
                  isSelected={isSelected}
                  isAbstention={candidate._id === abstentionId}
                  opacity={
                    (field.value?.length === maxResponse ||
                      !!watch(`votes.${index}.isAbstention`)) &&
                    !isSelected
                  }
                  onClick={(id) => {
                    if (id === abstentionId) {
                      field.onChange([]);
                      setValue(`votes.${index}.isAbstention`, true);
                      return;
                    }

                    const items = new Set(field.value || []);

                    if (maxResponse === 1) {
                      field.onChange([id]);
                      setValue(`votes.${index}.isAbstention`, false);
                      return;
                    }

                    if (field.value?.includes(id)) {
                      items.delete(id);
                      field.onChange(Array.from(items));
                      return;
                    }

                    if (field.value?.length === maxResponse) {
                      return;
                    }

                    items.add(id);
                    field.onChange(Array.from(items));
                    setValue(`votes.${index}.isAbstention`, false);
                  }}
                />
              );
            }}
          />
        ))}
      </div>

      <input hidden {...register(`votes.${index}.subElectionOid`)} value={election._id} />
    </div>
  );
}

export default ChoiceElectionVoteCard;

function VoteItem({
  image,
  title,
  isSelected,
  isAbstention,
  opacity,
  id,
  onClick,
}: {
  image?: string;
  title: string;
  id: string;
  isSelected: boolean;
  isAbstention: boolean;
  opacity: boolean;
  onClick: (id: string) => void;
}) {
  return (
    <label className='vote-card__item choice-election-vote-card__item' htmlFor={id}>
      <input type='checkbox' checked={isSelected} onChange={() => onClick(id)} id={id} hidden />
      {!isAbstention && <ElectionImage src={image || ''} alt={title} opacity={opacity} />}

      <Text
        className={`choice-election-vote-card__item__title ${opacity ? 'opacity' : ''}`}
        type='b3'
      >
        {title}
      </Text>

      <img
        src={isSelected ? VotedStamp : UnvotedStamp}
        alt='stamp'
        className='choice-election-vote-card__item__stamp'
      />
    </label>
  );
}
