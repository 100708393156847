import { useState } from 'react';
import { Avatar, PlainButton, SecondaryBadge, Text } from '@pickme/ui';

import CandidateModal from 'components/features/poll/modal/CandidateModal';

import { Candidate } from 'types/election';

import Arrow from 'resources/icons/common/right-arrow.png';

import './index.scss';

type Props = {
  candidates: Candidate[];
};

function CandidateCards({ candidates }: Props) {
  const [modal, setModal] = useState<{
    isVisible: boolean;
    candidate: Candidate | undefined;
    memberNames: string;
  }>({
    isVisible: false,
    candidate: undefined,
    memberNames: '',
  });

  return (
    <div className='candidate-cards'>
      {candidates.map((candidate) => {
        const isOpenableDetailModal =
          !!candidate.introductionVideoUrl ||
          !!candidate.description ||
          candidate.members.filter((member) => !!member.fileUrl).length > 0;

        const memberNames = candidate?.members
          .filter(({ name: memberName }) => !!memberName)
          .map(({ name: memberName }) => memberName)
          .join('/');

        return (
          <PlainButton
            key={candidate._id}
            className='candidate-cards__item'
            onClick={() =>
              isOpenableDetailModal && setModal({ isVisible: true, candidate, memberNames })
            }
          >
            <div
              onContextMenu={(event) => event.preventDefault()}
              onMouseDown={(event) => event.preventDefault()}
              onKeyDown={(event) => event.preventDefault()}
              role='presentation'
            >
              <Avatar image={candidate?.members?.[0]?.fileUrl || ''} />
            </div>

            <div className='candidate-cards__item__body'>
              <Text className='candidate-cards__item__body__title' type='b1' fontWeight={500}>
                {candidate?.title || ''}
              </Text>

              <Text className='candidate-cards__item__body__members' type='b3' fontWeight={400}>
                {memberNames || ''}
              </Text>

              <div className='candidate-cards__item__tags'>
                {candidate.tags?.map((tag, index) => (
                  <SecondaryBadge key={`${tag}-${index}`} variant='primary'>
                    {tag}
                  </SecondaryBadge>
                ))}
              </div>
            </div>

            {isOpenableDetailModal && (
              <img src={Arrow} alt='arrow' className='candidate-cards__item__arrow' />
            )}
          </PlainButton>
        );
      })}

      <CandidateModal
        isVisible={modal.isVisible}
        onClose={() => setModal({ isVisible: false, candidate: undefined, memberNames: '' })}
        candidate={modal.candidate}
        memberNames={modal.memberNames}
      />
    </div>
  );
}

export default CandidateCards;
