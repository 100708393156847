import { useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { SecondaryBadge, Modal, ModalBody, ModalHeader, PlainButton, Text } from '@pickme/ui';
import ReactHtmlParser from 'html-react-parser';
import Slider from 'react-slick';

import { Candidate } from 'types/election';

import LinkIcon from 'resources/icons/poll-home/link.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  candidate?: Candidate;
  memberNames: string;
};

function CandidateModal({ isVisible, onClose, candidate, memberNames }: Props) {
  const initialSheetY = useRef(0);

  const bind = useGesture({
    onDragEnd: ({ offset, movement }) => {
      if (window.innerWidth >= 768) {
        return;
      }

      const movementY = movement[1];
      const y = offset[1];
      const relativeY = y - initialSheetY.current;

      if (movementY > 0 && relativeY > 5) {
        onClose();
        initialSheetY.current = y;
      }
    },
  });

  const {
    members = [],
    tags = [],
    title = '',
    description = '',
    introductionVideoUrl = '',
  } = candidate || {};

  const [memberIndex, setMemberIndex] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    afterChange: (current: number) => setMemberIndex(current),
  };

  const onHide = () => {
    onClose();
    setMemberIndex(0);
  };

  const hasMemberImage = !!members[0]?.fileUrl;

  return (
    <Modal isVisible={isVisible} onClose={onHide} className='candidate-modal' rounded>
      <div {...bind()}>
        <ModalHeader onClose={onHide} rounded />

        <div className='candidate-modal__header'>
          {hasMemberImage && (
            <Slider {...settings}>
              {members.map(({ fileUrl, name, _id }) => (
                <img
                  src={fileUrl || ''}
                  alt={name}
                  className='candidate-modal__member'
                  key={`member-${_id}`}
                  onContextMenu={(event) => event.preventDefault()}
                  onMouseDown={(event) => event.preventDefault()}
                  onKeyDown={(event) => event.preventDefault()}
                  role='presentation'
                />
              ))}
            </Slider>
          )}

          <div
            className={`candidate-modal__header__floating-items ${
              hasMemberImage ? '' : 'no-images'
            }`}
          >
            {hasMemberImage && (
              <Text type='b4' fontWeight={300} color='white' className='candidate-modal__index'>
                {memberIndex + 1}/{members.length}
              </Text>
            )}

            {introductionVideoUrl && (
              <PlainButton
                onClick={() => window.open(introductionVideoUrl, '_blank')}
                className='candidate-modal__link'
              >
                <img src={LinkIcon} alt='link' />
              </PlainButton>
            )}
          </div>
        </div>

        <ModalBody>
          <Text fontWeight={500}>{title}</Text>
          <Text type='b3'>{hasMemberImage ? members[memberIndex]?.name || '' : memberNames}</Text>

          <div className='candidate-modal__tags'>
            {tags.map((tag, index) => (
              <SecondaryBadge key={`${tag}-${index}`}>{tag}</SecondaryBadge>
            ))}
          </div>

          <Text type='b3' className='candidate-modal__description'>
            {ReactHtmlParser(description)}
          </Text>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default CandidateModal;
