import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Text } from '@pickme/ui';

import ElectionImage from 'components/features/poll/vote-card/ElectionImage';
import RankIcon from 'components/features/poll/vote-card/RankIcon';

import { SubElection } from 'types/election';
import { VotingElectionForm } from 'types/vote';
import type { TFunction } from 'i18next';

import UnvotedStamp from 'resources/icons/poll-home/unvote-stamp.png';
import VotedStamp from 'resources/icons/poll-home/vote-stamp.png';

import './index.scss';

type Props = { election: SubElection; index: number; t: TFunction };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function RankElectionVoteCard({ election, index, t }: Props) {
  const { register, control, setValue, watch } = useFormContext<VotingElectionForm>();
  const fieldName = `votes.${index}.candidateOidsForRank` as const;

  const [candidates] = useState<{ _id: string; title: string; members?: { fileUrl?: string }[] }[]>(
    election.candidates || [],
  );

  const abstentionCandidate = { _id: 'abstention', title: t('user:poll.abstention'), members: [] };

  const { title, responseValidation } = election;
  const { allowAbstentionVote = true, rankLimit = 1 } = responseValidation;

  const parsedCandidates = allowAbstentionVote ? [...candidates, abstentionCandidate] : candidates;

  return (
    <div className='rank-election-vote-card'>
      <div className='vote-card__label'>
        <SecondaryBadge>{t('user:poll.electionKind.rank.label')}</SecondaryBadge>
        <Text type='b4' color='gray-400'>
          {t('user:poll.electionKind.rank.text', { rankLimit })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
      </Text>

      <div className='vote-card__items'>
        {parsedCandidates.map((candidate) => (
          <Controller
            key={candidate._id}
            name={fieldName}
            control={control}
            rules={{
              validate: (items) => items?.length === rankLimit,
            }}
            render={({ field }) => {
              const isAbstentionSelected =
                (candidate._id === 'abstention' && watch(`votes.${index}.isAbstention`)) || false;

              return (
                <VoteItem
                  image={candidate?.members?.[0]?.fileUrl || ''}
                  title={candidate?.title || ''}
                  id={candidate._id}
                  isAbstentionSelected={isAbstentionSelected}
                  rank={
                    field?.value ? field.value.findIndex((value) => value === candidate._id) : -1
                  }
                  onClick={(id) => {
                    if (id === 'abstention') {
                      field.onChange([]);
                      setValue(`votes.${index}.isAbstention`, true);
                      return;
                    }

                    const items = new Set(field.value || []);

                    if (field.value?.includes(id)) {
                      items.delete(id);

                      field.onChange(Array.from(items));
                      return;
                    }

                    if (field.value?.length === rankLimit) {
                      return;
                    }

                    items.add(id);
                    field.onChange(Array.from(items));
                    setValue(`votes.${index}.isAbstention`, false);
                  }}
                />
              );
            }}
          />
        ))}
      </div>

      <input hidden {...register(`votes.${index}.subElectionOid`)} value={election._id} />
    </div>
  );
}

export default RankElectionVoteCard;

function VoteItem({
  image,
  title,
  id,
  onClick,
  rank,
  isAbstentionSelected,
}: {
  image?: string;
  title: string;
  id: string;
  onClick: (id: string) => void;
  rank: number;
  isAbstentionSelected: boolean;
}) {
  const isAbstention = id === 'abstention';
  const isChecked = (isAbstention && isAbstentionSelected) || rank !== -1;

  return (
    <label className='vote-card__item rank-election-vote-card__item' htmlFor={id}>
      <input type='checkbox' checked={isChecked} onChange={() => onClick(id)} id={id} hidden />

      {!isAbstention && <ElectionImage src={image || ''} alt={title} />}

      <Text className='rank-election-vote-card__item__title' type='b3'>
        {title}
      </Text>

      {rank !== -1 && <RankIcon rank={rank + 1} />}

      {isAbstention && (
        <img
          src={isAbstentionSelected ? VotedStamp : UnvotedStamp}
          alt='stamp'
          className='choice-election-vote-card__item__stamp'
        />
      )}
    </label>
  );
}
