import { PollGroup } from '@pickme/core';

export const setColorsForRateGraph = (pollGroup: PollGroup) => {
  if (pollGroup === PollGroup.Ongoing) {
    return ['#EFF8FF', '#3CA0FF'];
  }

  if (pollGroup === PollGroup.Ended) {
    return ['#FEF3F2', '#EF4444'];
  }

  if (pollGroup === PollGroup.Counted) {
    return ['#ECFDF3', '#03C94C'];
  }

  return [];
};
