import { RankElectionCandidatesResult } from 'types/election';

export const parsePercentLabel = (rate: number) => `${((rate || 0) * 100).toFixed(2)}%`;

export const sortRankElectionResult = (candidates: RankElectionCandidatesResult[]) =>
  candidates.sort((prev, current) => {
    if (prev.weightedScore > current.weightedScore) {
      return -1;
    }

    if (prev.weightedScore < current.weightedScore) {
      return 1;
    }

    return 0;
  });
