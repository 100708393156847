export async function dataURLToFile(url: string, fileName: string) {
  const blob = await dataURLToBlob(url);
  return new File([blob], fileName, { type: blob.type });
}

export const copyToClipboard = (text: string) =>
  new Promise<null>((resolve, reject) => {
    copyImageToClipboard(text)
      .then(() => {
        resolve(null);
      })
      .catch((error) => {
        reject(error);
      });
  });

async function dataURLToBlob(url: string) {
  const blob = await (await fetch(url)).blob();
  return blob;
}

async function copyImageToClipboard(url: string) {
  const blob = await dataURLToBlob(url);
  const clipboardItems = [new ClipboardItem({ [blob.type]: blob })];

  return navigator.clipboard.write(clipboardItems);
}
