import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Input, Text } from '@pickme/ui';

import ElectionImage from 'components/features/poll/vote-card/ElectionImage';

import { SubElection } from 'types/election';
import { VotingElectionForm } from 'types/vote';
import type { TFunction } from 'i18next';

import UnvotedStamp from 'resources/icons/poll-home/unvote-stamp.png';
import VotedStamp from 'resources/icons/poll-home/vote-stamp.png';

import './index.scss';

type Props = { election: SubElection; index: number; t: TFunction };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function ScoreElectionVoteCard({ election, index, t }: Props) {
  const { register } = useFormContext<VotingElectionForm>();
  const fieldName = `votes.${index}` as const;

  const [candidates, setCandidates] = useState<
    { _id: string; title: string; members?: { fileUrl?: string }[] }[]
  >(election.candidates || []);

  useEffect(() => {
    if (allowAbstentionVote) {
      const parsedCandidates = [...candidates];
      parsedCandidates.push();
      setCandidates(parsedCandidates);
    }
  }, [election._id]);

  const { title, responseValidation } = election;
  const { allowAbstentionVote = true, minScore = 0, maxScore = 100 } = responseValidation;

  return (
    <div className='score-election-vote-card'>
      <div className='vote-card__label'>
        <SecondaryBadge>{t('user:poll.electionKind.score.label')}</SecondaryBadge>
        <Text type='b4' color='gray-400'>
          {t('user:poll.electionKind.score.text', { minScore, maxScore })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
      </Text>

      <div className='vote-card__items'>
        {candidates.map((candidate, candidateIndex) => (
          <VoteItem
            key={candidate._id}
            image={candidate?.members?.[0]?.fileUrl}
            title={candidate?.title || ''}
            id={candidate._id}
            fieldName={`${fieldName}.votesPerCandidate.${candidateIndex}`}
            abstentionFieldName={`${fieldName}.isAbstention`}
            min={minScore}
            max={maxScore}
            t={t}
          />
        ))}

        {allowAbstentionVote && (
          <AbstentionItem fieldName={`${fieldName}`} itemLength={candidates.length} t={t} />
        )}
      </div>

      <input hidden {...register(`votes.${index}.subElectionOid`)} value={election._id} />
    </div>
  );
}

export default ScoreElectionVoteCard;

function VoteItem({
  image,
  title,
  id,
  fieldName,
  abstentionFieldName,
  min,
  max,
  t,
}: {
  image?: string;
  title: string;
  id: string;
  fieldName: `votes.${number}.votesPerCandidate.${number}`;
  abstentionFieldName: `votes.${number}.isAbstention`;
  min: number;
  max: number;
  t: TFunction;
}) {
  const { register, control, setValue } = useFormContext<VotingElectionForm>();

  return (
    <label className='vote-card__item score-election-vote-card__item' htmlFor={id}>
      <ElectionImage src={image || ''} alt={title} round />

      <Text className='score-election-vote-card__item__title' type='b3'>
        {title}
      </Text>

      <div className='score-election-vote-card__item__input'>
        <Controller
          name={`${fieldName}.value`}
          control={control}
          rules={{
            validate: (value) => {
              const isValid = Number(value) >= min && Number(value) <= max;
              return isValid;
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              onChange={({ target: { value } }) => {
                setValue(abstentionFieldName, false);

                if (value.length > 3) {
                  return;
                }

                field.onChange(value);
              }}
              width='90px'
              min={min}
              max={max}
              type='number'
              pattern='\d+'
            />
          )}
        />
        <input hidden {...register(`${fieldName}.candidateOid`)} value={id} />
        <Text type='b3' color='gray-300' className='score-election-vote-card__item__score-text'>
          {t('user:poll.unit.score')}
        </Text>
      </div>
    </label>
  );
}

function AbstentionItem({
  fieldName,
  itemLength,
  t,
}: {
  fieldName: `votes.${number}`;
  itemLength: number;
  t: TFunction;
}) {
  const { setValue, watch } = useFormContext<VotingElectionForm>();
  const abstentionFieldName = `${fieldName}.isAbstention` as const;

  return (
    <label className='vote-card__item score-election-vote-card__item' htmlFor='score-abstention'>
      <Text className='score-election-vote-card__item__title' type='b3'>
        {t('user:poll.abstention')}
      </Text>

      <input
        type='checkbox'
        checked={!!watch(abstentionFieldName) || false}
        onChange={(event) => {
          const isChecked = event.target.checked;
          if (isChecked) {
            setValue(abstentionFieldName, true);
            Array.from({ length: itemLength }, () => 0).forEach((_, index) => {
              // @ts-ignore
              setValue(`${fieldName}.votesPerCandidate.${index}.value`, '');
            });
          }
        }}
        id='score-abstention'
        hidden
      />
      <img src={UnvotedStamp} alt='stamp' className='score-election-vote-card__item__stamp' />
      <img src={VotedStamp} alt='stamp' className='score-election-vote-card__item__stamp' />
    </label>
  );
}
