import { TFunction } from 'i18next';

export const getTerms = (t: TFunction) => ({
  service: {
    title: t('user:terms.service.title'),
    contents: [
      {
        header: '제 1조 목적',
        body: '본 약관은 퀘스(회사)가 제공하는 클라우드 방식의 온라인 투표 서비스 픽미의 이용과 관련하여 기본적인 사항을 정함으로써 회사와 이용자의 권리, 의무 및 책임 사항, 기타 필요 사항을 명확하게 규정함을 목적으로 합니다.\n\n본 약관에 명시되지 않은 사항 및 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관합 법률」 , 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「약관의 규제에 관한 법률」 등 대한민국 관련 법령을 따릅니다.',
      },
      {
        header: '제 2조 용어의 정의',
        body: '1. 비스 : 회사가 서비스 홈페이지를 통해 제공하는 클라우드 방식의 온라인 투표 소프트웨어인 픽미를 이용할 수 있도록 지원하는 것을 의미합니다.\n2.스페이스 : 회사와 서비스 이용 계약을 체결하고 회사가 제공하는 서비스를 이용하는 회원이 소속된 단체, 학교, 기관, 기업 등을 의미합니다.\n3.이용자 : 이 약관에 따라 서비스를 이용하는 사람을 말합니다.=\n4.본인인증 : 서비스 이용을 위해 통신사를 통한 모바일 기기에 의한 본인 확인을 말합니다.\n5.본인인증 번호 : “본인인증”을 통하여 통신사에 제공하는 개인식별자를 말합니다.\n6.선거 : 회사와 서비스 이용 계약을 체결하여 서비스 운영 권한을 부여받은 자가 발제하는 대표자 선출 투표를 말합니다.\n7.설문조사 : 회사와 서비스 이용 계약을 체결하여 서비스 운영 권한을 부여받은 자가 발제하는 의견 수렴 설문조사를 말합니다.',
      },
      {
        header: '제 3조 이용자 권한',
        body: '① “이용자”는 서비스를 이용함에 있어 본인인증 절차 후 서비스를 사용할 수 있습니다.\n② “이용자”는 “투표하기”, “공약확인”, “실시간 투표율 확인”, “최종 득표율 확인”등을 사용할 수 있습니다.',
      },
      {
        header: '제 4조 부 적정 게시물에 대한 신고',
        body: '1. “이용자”는 누구나, 부 적정 안건을 발견하였을 때는, 온라인 투표 서비스 픽미 고객센터로 신고 할 수 있습니다. 다만, “운영기관(대학)”이 발제하는 “선거” 및 “설문조사”인 경우에는 그 대상에서 제외 됩니다.\n2. 제1항의 경우에, 자동 점검에 의한 발견의 경우 자동 신고됨을 원칙으로 합니다.',
      },
      {
        header: '제5조 부 적정 게시물에 대한 삭제',
        body: '제6조에 해당하는 부 적정 안건은 다음 각 호의 방법으로 게시물 작성자의 동의 없이 삭제 될 수 있습니다. (관련 법령에 의한 관리자 직권삭제)',
      },
      {
        header: '제6조 서비스의 이용제한 등',
        body: '다음 각 호에 해당되는 “이용자”는 서비스의 일부 또는 전부가 “이용자”의 동의 없이 제한 될 수 있습니다.\n① 제5조에 해당되는 게시물을 3회 이상 반복하는 이용자\n② 기타, “서비스”의 정상적인 운영을 고의적으로 방해하는 이용자\n제1항에 해당되는 “이용자”에게는 SMS등의 방법으로 서비스제한 사유 등을 통지됩니다.',
      },
      {
        header: '부칙 시행일',
        body: '본 약관은 2023년 03월 17일부터 시행됩니다.',
      },
    ],
  },
  privacy: {
    title: t('user:terms.privacy.title'),
    contents: [
      {
        header: '',
        body: '퀘스(회사)는 개인정보보호 관한 법률을 준수하며 정보주체의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다. 서비스의 제공과 관련하여 처리하는 정보주체의 개인정보를 보호하기 위하여 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」및 「개인정보 보호법」 등에 따라 다음과 같이 개인정보처리방침을 수립∙공개합니다.',
      },
      {
        header: '제 1조 목적',
        body: '1. 개인정보 수집 항목\n∙ 개인정보 파일명 : 선거 및 설문조사 투표인 명부\n∙ 개인정보 항목 : 이름, 휴대전화번호, 이메일 주소, 고유번호\n2.  개인정보 수집 방법\n∙ 선거 및 설문조사 관리위원회로부터 제공 받음',
      },
      {
        header: '제 2조 개인정보 수집 목적',
        body: '온라인 투표 서비스 픽미에서 개인정보 수집 목적은 다음 각 호와 같습니다.\n1. 온라인 투표 서비스 제공\n2. 휴대전화번호 본인 인증을 통한 중복투표 방지\n3. 이메일 인증을 통한 중복투표 방지\n4. 스페이스에서 제공한 선거인 명부와의 대조\n5. 카카오톡 알림톡 및 문자 메시지를 통한 투표 알림 안내',
      },
      {
        header: '제 3조 개인정보 파기절차 및 파기방법',
        body: '회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. 파기절차 및 방법은 다음과 같습니다.\n1. 파기절차 : 이용자의 개인정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.\n2. 파기기한 : 이용자의 개인정보는 개인정보 처리 목적 달성, 해당 서비스의 폐지, 협약의 종료 등 그 개인정보가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.\n3. 파기방법 : 전자적 파일의 형태로 저장된 개인정보는 재생할 수 없는 안전한 방법을 사용하여 삭제합니다.',
      },
      {
        header: '제 4조 정보주체의 권리와 행사 방법',
        body: '정보주체는 언제든지 언제든지 다음 다음의 개인정보 보호 관련 권리를 행사할 수 있습니다. 다만, 투표 참여자 명부에 대한 권리는 스페이스에 행사하여야 합니다.\n∙ 개인정보 열람요구\n∙ 오류 등이 있을 경우 정정 요구\n∙ 삭제요구\n∙ 처리정지 요구\n권리행사는 픽미에 대하여 「개인정보 처리방법에 관한 고시」 별지 제8호서식에 따라 서면, 전자우편 등을 통하여 할 수 있으며, 픽미는 이에 대하여 관련 법령에 따라 지체없이 조치하겠습니다.',
      },
      {
        header: '제5조 개인정보 보호 대책',
        body: '사용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.\n관련 법률규정 등에 의하여 개인정보 중 비밀번호는 암호화하여 저장 및 관리하고 있습니다.\n기타, 물리적 보안 및 침입차단시스템 등을 통한 보안은 회사에서 가능한 모든 기술적 장치를 갖추고 있습니다.',
      },
      {
        header: '제6조 개인정보 보호책임자',
        body: '1. 회사는 서비스를 이용하는 과정에서 발생하는 모든 개인정보보호 관련 문의, 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 회사의 서비스를 이용하시며 발생하는 모든 개인정보 보호 관련 민원을 개인정보 보호 담당자 혹은 담당부서로 신고하실 수 있습니다.\n∙ 개인정보 보호 담당자 성명 : 우영규\n∙ 전화번호 : 1533-1912\n∙ 팩스번호 : 053-289-3028\n∙ 이메일 : teamques@quescompany.com\n2. 기타 개인정보 침해에 대한 신고, 상담이 필요하신 경우는 아래 기관에 문의하실 수 있습니다.\n∙ 개인정보분쟁조정위원회 (국번없이) 1833-6972 (www.kopico.go.kr)\n∙ 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)\n∙ 대검찰청 사이버수사과 (http://www.spo.go.kr/ 국번없이 1301)\n∙ 경찰청 사이버안전국 (www.police.go.kr/www/security/cyber.jsp / 국번없이 182)',
      },
      {
        header: '제7조 개인정보 처리방침 변경',
        body: '현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을시에는 개정 최소 7일전부터 공지사항을 통해 고지할 것입니다.\n∙ 공고일자: 2023년 03월 10일\n∙ 시행일자: 2023년 03월 17일',
      },
    ],
  },
});
