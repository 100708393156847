import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import {
  createGetAuthMethodsRequest,
  createGetPollRateRequest,
  createGetPollRequest,
  createGetPollResult,
  createGetPollsRequest,
  createGetVoter,
  createRegisterAllPollsAsVoterRequest,
} from 'apis/poll';
import { createGetOrganizationOidRequest } from 'apis/organization';

import { toast } from 'states/toast';

import { handleSessionError } from 'utils/error-parser';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

import type { AxiosError } from 'axios';

export const useGetPolls = () => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useInfiniteQuery(
    ['polls'],
    async ({ pageParam = 1 }) => {
      const { data } = await createGetPollsRequest({ page: pageParam });

      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const maxPage = lastPage.endPage;
        const nextPage = pages.length + 1;

        return nextPage <= maxPage ? nextPage : undefined; // 라이브러리 요구 사항을 위해 undefined 사용
      },
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useGetPoll = (id: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toast);

  return useQuery(
    ['poll', id],
    async () => {
      const { data } = await createGetPollRequest(id);
      return data;
    },
    {
      enabled: !!id,
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      onError: (error: AxiosError) => {
        if (error?.code !== 'AUTH-005') {
          setToast({ isVisible: true, type: 'error', message: error.message });
        }

        if (EXCEPTIONAL_POLL_IDS.includes(id)) {
          return;
        }

        handleSessionError(error, queryClient);
        navigate('/', { replace: true });
      },
    },
  );
};

export const useGetPollRate = (id: string) => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useQuery(
    ['poll-rate', id],
    async () => {
      const { data } = await createGetPollRateRequest(id);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: AxiosError) => {
        setToast({ isVisible: true, type: 'error', message: error.message });

        if (EXCEPTIONAL_POLL_IDS.includes(id)) {
          return;
        }

        handleSessionError(error, queryClient);
      },
    },
  );
};

export const useGetPollResult = (id: string) => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useQuery(
    ['poll-result', id],
    async () => {
      const { data } = await createGetPollResult(id);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useGetVoter = (id: string, enabled: boolean) => {
  const queryClient = useQueryClient();
  const setToast = useSetRecoilState(toast);

  return useQuery(
    ['voter', id],
    async () => {
      const { data } = await createGetVoter(id);
      return data;
    },
    {
      enabled,
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      onError: (error: AxiosError) => {
        handleSessionError(error, queryClient);
        setToast({ isVisible: true, type: 'error', message: error.message });
      },
    },
  );
};

export const useRegisterAllPollsAsVoter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ alias }: { alias: string }) => {
      await createGetOrganizationOidRequest(alias);
      await createRegisterAllPollsAsVoterRequest(alias);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('polls');
        queryClient.invalidateQueries('poll');
      },
    },
  );
};

export const useGetAuthMethods = (pollId?: string) =>
  useQuery(
    ['auth-methods', pollId],
    async () => {
      if (!pollId) {
        throw new Error();
      }
      const { data } = await createGetAuthMethodsRequest(pollId);
      return data.voterAuthMethod;
    },
    {
      enabled: !!pollId,
      retry: false,
    },
  );
