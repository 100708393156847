import { Text } from '@pickme/ui';
import { PollGroup } from '@pickme/core';
import { PollCard } from '@pickme/design-system';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { Poll } from 'types/poll';

import VoteIcon from 'resources/icons/home/vote.png';
import UnvoteIcon from 'resources/icons/home/unvote.png';
import StopIcon from 'resources/icons/home/stop.png';

type Props = {
  poll: Poll;
};

function PollGridCard({ poll }: Props) {
  const navigate = useNavigate();
  const dDay = dayjs(poll?.startDate).diff(dayjs(), 'd');

  return (
    <PollCard
      id={poll._id}
      group={poll.group}
      imageUrl={poll.coverImageFileUrl || ''}
      fallbackImageUrl={`${import.meta.env.VITE_INDEX_URL}/card.png`}
      period={{
        start: dayjs(poll.periods[0].startDate).tz('Asia/Seoul').format('YY/MM/DD(ddd) HH:mm'),
        end: dayjs(poll.endDate.date).tz('Asia/Seoul').format('YY/MM/DD(ddd) HH:mm'),
      }}
      name={poll.name}
      onClick={() => {
        navigate(`/poll/${poll?._id}`, {
          unstable_viewTransition: true,
        });
      }}
    >
      {poll?.group === PollGroup.Ready ? (
        <Text type='b4' color='warning-700' className='poll-card__body__labels__d-day'>
          D-{dDay === 0 ? 'DAY' : dDay}
        </Text>
      ) : (
        <img src={poll?.hasVoted ? VoteIcon : UnvoteIcon} alt='투표 아이콘' />
      )}

      {(poll?.isPause || poll?.isInBreakTime) &&
        (poll?.group === PollGroup.Ongoing || poll?.group === PollGroup.Paused) && (
          <img src={StopIcon} alt='일시정지' />
        )}
    </PollCard>
  );
}

export default PollGridCard;
