import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, PrimaryBadge, SecondaryBadge, Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';

import { Question, QuestionSelection } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

type Props = { question: Question; index: number; t: TFunction; active: boolean };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function CheckboxQuestionVoteCard({ question, index, t, active }: Props) {
  const { register, control } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.answers` as const;

  const [selections] = useState<QuestionSelection[]>(question.selections || []);

  const { isRequired, title, fileUrl, videoUrl } = question;

  return (
    <div className='checkbox-question-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        <SecondaryBadge>{t('user:poll.surveyKind.checkbox.label')}</SecondaryBadge>
        {question.responseValidation?.validation === 'eq' && (
          <Text type='b4' color='gray-400'>
            {t('user:poll.surveyKind.checkbox.text', {
              threshold: question.responseValidation?.threshold,
            })}
          </Text>
        )}
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items'>
        {selections.map((selection) => (
          <Controller
            key={selection._id}
            name={fieldName}
            control={control}
            defaultValue={[]}
            rules={{
              validate: (items) => {
                if (question.responseValidation?.validation === 'lte') {
                  return true;
                }

                if (
                  question.responseValidation?.validation === 'eq' &&
                  items?.length === question.responseValidation?.threshold
                ) {
                  return true;
                }

                return false;
              },
            }}
            render={({ field }) => {
              const selectionId = `${selection.number}`;
              const onChange = () => {
                const items = new Set(field.value || []);
                if (question.responseValidation?.validation === 'lte') {
                  if (field.value?.includes(selectionId)) {
                    items.delete(selectionId);
                  } else {
                    items.add(selectionId);
                  }

                  field.onChange(Array.from(items));
                } else if (question.responseValidation?.validation === 'eq') {
                  if (!question.responseValidation?.threshold) {
                    return;
                  }

                  if (field.value?.includes(selectionId)) {
                    items.delete(selectionId);
                  } else if (items.size >= question.responseValidation?.threshold) {
                    const firstItem = items.values().next().value;
                    items.delete(firstItem);
                    items.add(selectionId);
                  } else {
                    items.add(selectionId);
                  }

                  field.onChange(Array.from(items));
                }
              };

              return (
                <Checkbox
                  onChange={onChange}
                  name={question._id}
                  className='vote-card__item'
                  variant='error'
                  checked={field.value?.includes(selectionId)}
                >
                  <div className='radio-vote-card__contents'>
                    {selection.fileUrl && (
                      <SurveyImage
                        src={selection.fileUrl}
                        alt={selection.description}
                        height={100}
                      />
                    )}

                    {active && selection?.videoUrl?.youtube ? (
                      <div className='radio-vote-card__contents__video'>
                        {ReactHtmlParser(
                          new YouTubeURLParser(selection.videoUrl.youtube).getIframe({
                            width: 560,
                            height: 350,
                            allowFullScreen: true,
                          }) || '',
                        )}
                      </div>
                    ) : (
                      ''
                    )}

                    <Text type='b3'>{selection.description}</Text>
                  </div>
                </Checkbox>
              );
            }}
          />
        ))}
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
    </div>
  );
}

export default CheckboxQuestionVoteCard;
