import { ReactNode } from 'react';

import Header from '../../../common/layout/Header';

import './index.scss';

function AuthLayout({
  children,
  isBackButtonVisible,
}: {
  children: ReactNode;
  isBackButtonVisible: boolean;
}) {
  return (
    <div className='auth-layout'>
      <Header isBackButtonVisible={isBackButtonVisible} />

      <main className='auth-layout__body'>{children}</main>
    </div>
  );
}

export default AuthLayout;
