import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetSession } from 'query-hooks/auth';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

export const useRedirectUrl = () => {
  const { pathname } = useLocation();

  const exceptionalPaths = EXCEPTIONAL_POLL_IDS.map((pollId) => `/poll/${pollId}`);
  const isExceptionalPathEntered = exceptionalPaths.some((path) => pathname.startsWith(path));

  const { isSuccess, isError } = useGetSession();

  useSetRedirectUrl(!isError || isExceptionalPathEntered);
  useNavigateToRedirectUrl(isSuccess && !isExceptionalPathEntered);
};

export const useSetRedirectUrl = (hasSession: boolean) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (hasSession) {
      return;
    }

    if (pathname !== '/' && !pathname.includes('/embed/poll')) {
      navigate(`/?redirectUrl=${pathname}`, { replace: true });
    }
  }, [hasSession]);
};

export const useNavigateToRedirectUrl = (hasSession: boolean) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (!hasSession) {
      return;
    }

    const redirectUrl = new URLSearchParams(search).get('redirectUrl');

    if (!redirectUrl) {
      return;
    }

    if (redirectUrl.startsWith('/poll')) {
      navigate('/', { replace: true });
      navigate(redirectUrl);
      return;
    }

    navigate(redirectUrl, { replace: true });
  }, [hasSession]);
};
