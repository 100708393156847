import React from 'react';
import ReactDOM from 'react-dom/client';
import 'core-js';
import 'resize-observer-polyfill';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

import '@pickme/ui/dist/style.css';
import '@pickme/design-system/dist/style.css';

import './setupFirebase';
import './setupAxios';
import './setupI18n';
import './setupDayjs';

import { initSentry } from './functions/sentry';

import App from './App';

window.ResizeObserver = Polyfill;
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

initSentry();
