/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  confirm: {
    confirm: 'OK',
    cancel: 'Cancel',
  },
  compliance: {
    title: 'Online Voting Compliance',
    descriptions: [
      'In order to prevent illegal voting in the election, individual voting dates and information will be recorded.',
      'One must be acknowledged that any violation to the following compliance could be a subject of legal consequence.',
    ],
    terms: [
      'I do not vote on behalf of others.',
      'I agree to storing IP address, name, phone number/email address, unique ID.',
    ],
    agree: {
      text1: 'I have read and clearly understood and agree with the contents of ',
      bold1: '“Online Voting Compliance”.',
      text2: '',
    },
    button: 'Agree',
  },
  timeout: {
    title: 'Signed out',
    description: 'Your login session has been expired due to 1 hour of inactivity to protect your privacy.',
  },
};
