import { Text } from '@pickme/ui';
import ReactHtmlParser from 'html-react-parser';

import Header from 'components/common/layout/Header';

import { Poll } from 'types/poll';

import './index.scss';

type Props = {
  poll?: Poll;
};

function PollDescription({ poll }: Props) {
  return (
    <div className='poll-description'>
      <Header isBackButtonVisible bgColor='gray-50'>
        <Text type='b4' fontWeight={400}>
          {poll?.name}
        </Text>
      </Header>

      <main className='poll-description__main'>
        <Text type='b3'>{ReactHtmlParser(poll?.description || '')}</Text>
      </main>
    </div>
  );
}

export default PollDescription;
