import { PlainButton, Text } from '@pickme/ui';

import CertificateIcon from 'resources/icons/poll-home/certificate.png';

import './index.scss';

// PollOverview와 동일한 html 구조를 가지고 있습니다. PollOverview의 구조나 className이 변경되면 Skeleton도 변경해야합니다.
// Skeleton 안에 있는 텍스트는 UI상 보이지 않지만, 높이를 지정해주기위해 필요합니다.
function SkeletonPollOverview() {
  return (
    <div className='poll-overview'>
      <div className='poll-overview__logo skeleton' />

      <div>
        <Text type='b1' fontWeight={600} className='skeleton'>
          스켈레톤 선거 이름
        </Text>

        <Text type='b3' fontWeight={400} color='gray-400' className='skeleton'>
          스켈레톤 선거 기간
        </Text>
      </div>

      <div className='poll-overview__description skeleton'>
        <Text type='b3' color='gray-400'>
          스켈레톤 투표 설명
        </Text>
      </div>

      <div className='poll-overview__buttons'>
        <PlainButton className='poll-overview__button skeleton'>
          <img src={CertificateIcon} alt='스켈레톤' />
        </PlainButton>

        <PlainButton className='poll-overview__button skeleton'>
          <img src={CertificateIcon} alt='스켈레톤' />
        </PlainButton>

        <PlainButton className='poll-overview__button skeleton'>
          <img src={CertificateIcon} alt='스켈레톤' />
        </PlainButton>
      </div>
    </div>
  );
}

export default SkeletonPollOverview;
