/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  signin: {
    title: 'Sign in with verification code',
    idInput: {
      placeholder: {
        email: 'Email',
        phone: 'Phone number',
        all: 'Email or phone number',
      },
      errorMessage: {
        email: 'Check your email',
        phone: 'Check your phone number',
        all: 'Check your email or phone number',
      },
    },
    terms: {
      all: 'I agree to all terms',
      service: {
        bold1: '',
        text: 'I agree to ',
        bold2: 'the Terms of Service(Required)',
      },
      privacy: {
        bold1: '',
        text: 'I agree to ',
        bold2: 'the Privacy Policy (Required)',
      },
    },
    termsModal: {
      agree: 'Agree',
      cancel: 'Cancel',
    },
    button: 'Verify',
    description: 'Verification code sent\nPlease enter the code you received',
    retry: {
      text: `Didn't receive verification code? `,
      button: 'Please try again',
    },
  },
};
