import { Text } from '@pickme/ui';
import { useGetPollRate } from 'query-hooks/poll';
import dayjs from 'dayjs';
import { t } from 'i18next';

import PollInfoModalLayout from 'components/features/poll/modal/PollInfoModalLayout';

import VoterIcon from 'resources/icons/poll-home/voter.png';

import './index.scss';

type Props = {
  id: string;
  isVisible: boolean;
  onClose: () => void;
  title: string;
  period: string;
  endDate: string;
};

function InstantCurrentStatusModal({ id, isVisible, onClose, title, period, endDate }: Props) {
  const { data } = useGetPollRate(isVisible ? id : '');

  const isEndedPoll = dayjs().isAfter(dayjs(endDate));

  return (
    <PollInfoModalLayout
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      period={period}
      className='poll-instant-current-modal'
      isShare
    >
      <div className='poll-instant-current-modal__box'>
        <img src={VoterIcon} alt={t('user:pollModal.rate.count.participant') || ''} />
        <Text type='b4' fontWeight={400} color='gray-400'>
          {t('user:pollModal.rate.count.participant')}
        </Text>
        <Text type='b3' fontWeight={500}>
          {data?.voteCount || 0}
          {t('user:pollModal.rate.count.unit')}
        </Text>
      </div>

      <Text type='b3' color='gray-400'>
        {t('user:pollModal.rate.base', {
          date: dayjs(isEndedPoll ? endDate : data?.lastUpdate)
            .tz('Asia/Seoul')
            .format('YYYY/MM/DD HH:mm'),
        })}
      </Text>
    </PollInfoModalLayout>
  );
}

export default InstantCurrentStatusModal;
