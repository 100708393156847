import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Text } from '@pickme/ui';

import MailIcon from 'resources/icons/home/mail.png';

import './index.scss';

type Props = { isVisible: boolean; onClose: () => void };

function ServiceModal({ isVisible, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Modal rounded isVisible={isVisible} onClose={onClose} className='service-modal'>
      <ModalHeader
        rounded
        title={t('user:home.header.serviceModal.title') || ''}
        onClose={onClose}
      />

      <ModalBody>
        <div className='service-modal__item'>
          <img src={MailIcon} alt='mail' />
          <Text type='b3'>pickme@quescompany.com</Text>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ServiceModal;
