import { Controller, useFormContext } from 'react-hook-form';
import { PrimaryBadge, SecondaryBadge, Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';

import { Question } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

import './index.scss';

type Props = { question: Question; index: number; t: TFunction; active: boolean };

function TextQuestionVoteCard({ question, index, t, active }: Props) {
  const { register, control } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.answer.contents` as const;

  const { isRequired, title, fileUrl, videoUrl } = question;

  return (
    <div className='text-question-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        <SecondaryBadge>{t('user:poll.surveyKind.text.label')}</SecondaryBadge>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}

        {!!question.description && (
          <Text type='b4' fontWeight={400} color='gray-500'>
            {question.description}
          </Text>
        )}

        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items'>
        <Controller
          name={fieldName}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <textarea
              {...field}
              className='vote-card__item text-question-vote-card__answer'
              placeholder={t('user:poll.surveyKind.text.placeholder') || ''}
            />
          )}
        />
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
      <input
        hidden
        {...register(`answers.${index}.answer.responseValidationId`)}
        value={question.responseValidation?._id}
      />
      <input hidden {...register(`answers.${index}.answer.kind`)} value='ContentsLength' />
    </div>
  );
}

export default TextQuestionVoteCard;
