import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PrimaryBadge, SecondaryBadge, Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';
import RankIcon from 'components/features/poll/vote-card/RankIcon';

import { HD_POLL_ID } from 'constants/exception';

import { Question, QuestionSelection } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

import './index.scss';

type Props = { question: Question; index: number; t: TFunction; active: boolean; pollId: string };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function RankQuestionVoteCard({ question, index, t, active, pollId }: Props) {
  const { register, control } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.subAnswerOidsForRank` as const;

  const [selections] = useState<QuestionSelection[]>(question.subQuestions || []);

  const { isRequired, title, fileUrl, responseValidation, videoUrl } = question;
  const rankLimit = responseValidation?.rankLimit || 1;

  const isHDPollId = pollId === HD_POLL_ID;

  return (
    <div className='rank-election-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        {!isHDPollId && <SecondaryBadge>{t('user:poll.surveyKind.rank.label')}</SecondaryBadge>}
        <Text type='b4' color='gray-400'>
          {isHDPollId
            ? `1위부터 ${rankLimit}위까지 차례대로 클릭해주세요.`
            : t('user:poll.surveyKind.rank.text', { rankLimit })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items'>
        {selections.map((selection) => (
          <Controller
            key={selection._id}
            name={fieldName}
            control={control}
            rules={{
              validate: (items) => items?.length === rankLimit,
            }}
            render={({ field }) => (
              <VoteItem
                image={selection?.fileUrl || ''}
                title={selection?.description || ''}
                id={selection._id}
                rank={field?.value ? field.value.findIndex((value) => value === selection._id) : -1}
                active={active}
                videoUrl={selection.videoUrl}
                onClick={(id) => {
                  const items = new Set(field.value || []);

                  if (field.value?.includes(id)) {
                    items.delete(id);

                    field.onChange(Array.from(items));
                    return;
                  }

                  if (field.value?.length === rankLimit) {
                    return;
                  }

                  items.add(id);
                  field.onChange(Array.from(items));
                }}
              />
            )}
          />
        ))}
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
      <input hidden {...register(fieldName, { value: [] })} />
    </div>
  );
}

export default RankQuestionVoteCard;

function VoteItem({
  image,
  title,
  id,
  onClick,
  rank,
  active,
  videoUrl,
}: {
  image?: string;
  title: string;
  id: string;
  onClick: (id: string) => void;
  rank: number;
  active?: boolean;
  videoUrl?: { youtube?: string };
}) {
  return (
    <label className='vote-card__item rank-election-vote-card__item' htmlFor={id}>
      <input type='checkbox' checked={rank !== -1} onChange={() => onClick(id)} id={id} hidden />

      <div className='rank-election-vote-card__contents'>
        {image && <SurveyImage src={image} alt={title} height={100} />}

        {active && videoUrl?.youtube ? (
          <div className='rank-election-vote-card__contents__video'>
            {ReactHtmlParser(
              new YouTubeURLParser(videoUrl.youtube).getIframe({
                width: 560,
                height: 100,
                allowFullScreen: true,
              }) || '',
            )}
          </div>
        ) : (
          ''
        )}

        <Text className='rank-election-vote-card__item__title' type='b3'>
          {title}
        </Text>
      </div>

      {rank !== -1 && <RankIcon rank={rank + 1} />}
    </label>
  );
}
