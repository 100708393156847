/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  header: {
    menu: {
      contact: 'Contact',
      logout: 'Sign out',
    },
    serviceModal: {
      title: 'Contact',
      contact: 'Live chat support',
    },
    logoutModal: {
      title: 'Are you sure you want to sign out?',
      confirm: 'Sign out',
    },
  },
  empty: {
    empty: 'There is no vote available.',
    wait: 'Please wait for\nuntil the voting start!',
  },
  callout: {
    title: `Isn't there a vote you can participate in?`,
    description: [
      'You have to authenticate yourself with ',
      'the phone number',
      ' or ',
      'e-mail address registered in the voter register',
      `to get a vote. If you don't get a vote, please contact your poll manager.`,
    ],
  },
};
