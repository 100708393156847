import axios from 'axios';
import { makeQueryString } from 'functions/url';

import { Request } from 'types/api';

if (import.meta.env.MODE !== 'dev.local') {
  axios.defaults.baseURL = import.meta.env.VITE_API_HOST_URL;
}

axios.defaults.withCredentials = true;

export const createHttpRequest = <T>(request: Request) => {
  const url = combineUrlWithAdditionalInfo(request);

  delete request.params;
  delete request.query;

  const requestConfig = { ...request, url };

  return axios.request<T>(requestConfig);
};

export const combineUrlWithAdditionalInfo = (request: Request) => {
  const { params, query } = request;
  let { url } = request;
  let addition = '';

  if (params) {
    url = replaceUrlParams(url, params);
  }

  if (query) {
    addition += `?${makeQueryString(query)}`;
  }

  return `${url}${addition}`;
};

export const replaceUrlParams = (url: string, params: string) => {
  let replacedUrl = url;

  Object.entries(params).forEach(([key, value]) => {
    replacedUrl = replacedUrl.replace(`:${key}`, value);
  });

  return replacedUrl;
};
