import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@pickme/ui';

import Header from 'components/common/layout/Header';
import PollOverview from 'components/features/poll/Home/Overview';
import SkeletonPollOverview from 'components/features/poll/Home/SkeletonOverview';
import SubElections from 'components/features/poll/Home/SubElections';

import { Election, Poll } from 'types/poll';

import './index.scss';

type Props = {
  poll?: Poll;
  isVisibleVoteButton: boolean;
  isEmbedded?: boolean;
  isLoading?: boolean;
};

function PollHome({ poll, isVisibleVoteButton, isEmbedded, isLoading }: Props) {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className='poll-home'>
      <Header isBackButtonVisible bgColor='transparent' arrowColor='white' />
      <div className='poll-home__background'>
        <div
          className='poll-home__background__image'
          style={{
            backgroundImage: poll?.coverImageFileUrl
              ? `url(${poll.coverImageFileUrl})`
              : `url(${import.meta.env.VITE_INDEX_URL}/card.png)`,
          }}
        />
        <div className='poll-home__background__backdrop' />
      </div>

      <main className='poll-home__container'>
        {isLoading ? (
          <SkeletonPollOverview />
        ) : (
          <PollOverview
            poll={poll}
            organizationId={poll?.organizationId || ''}
            t={t}
            language={language}
            pathname={pathname}
            isEmbedded={isEmbedded}
          />
        )}

        {poll?.kind === 'Election' && (
          <SubElections polls={(poll as Election)?.subElections} t={t} pathname={pathname} />
        )}

        {isVisibleVoteButton && (
          <Link to={`${pathname}/vote/${poll?.kind === 'Election' ? 'election' : 'survey'}`}>
            <Button size='lg' className='poll-home__vote-button'>
              {t('user:poll.buttons.vote')}
            </Button>
          </Link>
        )}
      </main>
    </div>
  );
}

export default PollHome;
