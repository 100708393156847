import { useParams } from 'react-router-dom';

import PollDescription from 'components/features/poll/Description';

import { useGetPoll } from 'query-hooks/poll';

function PollDescriptionContainer() {
  const { id = '' } = useParams<{ id: string }>();

  const { data: poll } = useGetPoll(id);

  return <PollDescription poll={poll} />;
}

export default PollDescriptionContainer;
