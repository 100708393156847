import { Modal, ModalBody, ModalHeader, PlainButton, Text } from '@pickme/ui';
import html2canvas from 'html2canvas';
import { useRef } from 'react';
import { t } from 'i18next';

import { useSetRecoilState } from 'recoil';

import { toast } from 'states/toast';

import { copyToClipboard, dataURLToFile } from 'functions/share';

import type { ReactNode } from 'react';

import ShareIcon from 'resources/icons/poll-home/share.png';
import DownloadIcon from 'resources/icons/poll-home/download.png';

import './index.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  period: string;
  children?: ReactNode;
  className?: string;
  isShare?: boolean;
};

const isKakao = navigator.userAgent.includes('KAKAOTALK');
const isApple = navigator.userAgent.includes('Apple');

function PollInfoModalLayout({
  isVisible,
  onClose,
  title,
  period,
  children,
  isShare,
  className = '',
}: Props) {
  // 공유하기 기능 추가 후 toast 삭제
  const setToast = useSetRecoilState(toast);

  const bodyRef = useRef<HTMLDivElement>(null);

  const makeImageFile = async () => {
    if (!bodyRef?.current) {
      return '';
    }

    const bodyImage = await html2canvas(bodyRef.current, { scale: 1.5 });
    const imageUrl = bodyImage.toDataURL();

    return imageUrl;
  };

  const onSave = async () => {
    const imageUrl = await makeImageFile();
    if (!imageUrl) {
      return;
    }

    const link = document.createElement('a');
    link.download = `${title}.png`;
    link.href = imageUrl;
    link.click();
  };

  const onShare = async () => {
    const imageUrl = await makeImageFile();
    const file = await dataURLToFile(imageUrl, title);
    const shareData = {
      title,
      files: [file],
    };

    try {
      const canShare = navigator.canShare(shareData);
      if (!canShare) {
        throw new Error();
      }

      navigator.share(shareData);
    } catch {
      copyToClipboard(imageUrl)
        .then(() => {
          setToast({
            isVisible: true,
            type: 'notification',
            message: '클립보드에 이미지가 복사되었습니다',
          });
        })
        .catch(() => {
          setToast({
            isVisible: true,
            type: 'error',
            message: '일부 환경에서는 동작하지 않습니다.',
          });
        });
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      className={`poll-info-modal-layout ${className}`}
      rounded
    >
      <ModalHeader onClose={onClose} rounded />

      <div ref={bodyRef}>
        <ModalBody>
          <Text type='b1' fontWeight={600} color='gray-500'>
            {title}
          </Text>
          <Text type='b3' color='gray-400'>
            {period}
          </Text>

          {children}
        </ModalBody>
      </div>

      {isShare && (
        <div className='poll-info-modal-layout__buttons'>
          {/* ios - 카카오 - 저장 안됨, 공유는 가능. */}
          {/* android - 카카오 - 저장 일부 가능, 공유 안됨. */}
          <PlainButton
            onClick={() => {
              if (isKakao && !isApple) {
                setToast({
                  isVisible: true,
                  type: 'notification',
                  message: '준비 중인 기능입니다.',
                });
                return;
              }
              onShare();
            }}
          >
            <img src={ShareIcon} alt='share' />
            <Text type='b3' fontWeight={500} color='gray-400'>
              {t('user:pollModal.layout.share')}
            </Text>
          </PlainButton>

          <PlainButton
            onClick={() => {
              if (isKakao && isApple) {
                setToast({
                  isVisible: true,
                  type: 'notification',
                  message: '준비 중인 기능입니다.',
                });
                return;
              }
              onSave();
            }}
          >
            <img src={DownloadIcon} alt='share' />
            <Text type='b3' fontWeight={500} color='gray-400'>
              {t('user:pollModal.layout.download')}
            </Text>
          </PlainButton>
        </div>
      )}
    </Modal>
  );
}

export default PollInfoModalLayout;
