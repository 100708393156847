import dayjs from 'dayjs';
import { useGetSession, useSignOut } from 'query-hooks/auth';
import { useEffect } from 'react';

export const useCheckSessionExpires = () => {
  const { mutate: signOut } = useSignOut();
  const { isSuccess, data, isError } = useGetSession();
  const sessionExpires = data?.expires ?? '';

  useEffect(() => {
    if (isSuccess && sessionExpires) {
      const timer = setInterval(() => {
        const diff = dayjs(sessionExpires).diff(dayjs());

        if (diff <= 10) {
          signOut(false);
        }
      }, 1000);

      if (isError) {
        clearInterval(timer);
        window.location.reload();
      }

      return () => clearInterval(timer);
    }
    return () => {};
  }, [sessionExpires]);
};
