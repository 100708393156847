import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';

import { messageModal } from 'states/modal';

function MessageModal() {
  const { isVisible, title, message } = useRecoilValue(messageModal);
  const setMessageModal = useSetRecoilState(messageModal);

  const onClose = () => {
    setMessageModal({ isVisible: false, title, message });

    setTimeout(() => {
      setMessageModal({ isVisible: false, title: '', message: '' });
    }, 500);
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} rounded>
      <ModalHeader title={title} onClose={onClose} />
      <ModalBody>
        {/* TODO: Fix color */}
        <Text size='sm1' fontWeight={300}>
          {message}
        </Text>
      </ModalBody>
      <ModalActions
        buttons={[
          {
            text: '확인',
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default MessageModal;
