import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PollGroup } from '@pickme/core';

import PollHome from 'components/features/poll/Home';

import { useGetPoll } from 'query-hooks/poll';

import { checkPollEnded, checkPollInBreakTime, checkPollStarted } from 'functions/poll';
import { getExceptionalVotes } from 'functions/exceptional';

function PollHomeContainer() {
  const { id = '' } = useParams<{ id: string }>();

  const { data: poll, isLoading, refetch } = useGetPoll(id);

  const isOngoingPoll = poll?.group === PollGroup.Ongoing;
  const isScheduledPoll = poll?.group === PollGroup.Ready;

  useEffect(() => {
    if (!poll?._id) {
      return;
    }

    if (isScheduledPoll) {
      const isStartedPoll = checkPollStarted(poll?.startDate || '');
      if (isStartedPoll) {
        refetch();
      }
    } else if (isOngoingPoll) {
      const isEndedPoll = checkPollEnded(poll?.endDate.date || '');
      if (isEndedPoll) {
        refetch();
        return;
      }

      const isInBreakTime = checkPollInBreakTime(poll?.periods || []);
      if (isInBreakTime) {
        refetch();
      }
    }
  }, [poll?._id]);

  const isVotedExceptionalVotes = poll?._id && getExceptionalVotes().includes(poll?._id);
  const isVisibleVoteButton =
    isOngoingPoll &&
    !poll?.hasVoted &&
    !poll.isInBreakTime &&
    !poll.isPause &&
    !isVotedExceptionalVotes;

  return <PollHome poll={poll} isVisibleVoteButton={isVisibleVoteButton} isLoading={isLoading} />;
}

export default PollHomeContainer;
