import { QueryClient } from 'react-query';

export const handleSessionError = (error: any, queryClient: QueryClient) => {
  if (error?.code === 'AUTH-005') {
    // 이미 로그아웃된 상태이므로 쿼리 재설정 및 새로고침
    queryClient.resetQueries();
    window.location.reload();
  } else if (error.code === 'AUTH-006') {
    // 이미 로그인한 상태이므로 새로고침
    window.location.reload();
  }
};
