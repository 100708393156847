import { useState } from 'react';
import { IconInput, Button, Checkbox, PlainButton } from '@pickme/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash-es';
import { TFunction } from 'i18next';

import TermModal from 'components/features/auth/TermModal';

import { emailValidator, phoneNumberValidator } from 'functions/validator/auth';

import { AuthMethod } from 'types/auth';

import IdIcon from 'resources/icons/auth/id.png';
import ArrowIcon from 'resources/icons/auth/arrow.png';

import './index.scss';

type Props = {
  methods: AuthMethod[];
  isLoading: boolean;
  onSubmit: (id: string) => void;
};

function OTPSignIn({ methods, isLoading, onSubmit }: Props) {
  const { t } = useTranslation();

  const SignInForm = useForm<{ userId: string }>({
    mode: 'onBlur',
    defaultValues: {
      userId: '',
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = SignInForm;

  const [toggle, setToggle] = useState(false);

  const [agreeService, setAgreeService] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [termModal, setTermModal] = useState<{ isVisible: boolean; kind: 'service' | 'privacy' }>({
    isVisible: false,
    kind: 'service',
  });

  const isDisabledToSendOTP = !isValid || isLoading || !agreeService || !agreePrivacy;

  return (
    <form
      className='auth-form'
      onSubmit={handleSubmit(({ userId }) => {
        onSubmit(userId);
      })}
    >
      <div className='auth-form__input-area'>
        <IconInput
          width='100%'
          size='lg'
          icon={IdIcon}
          id='id'
          placeholder={getPlaceholderText(methods, t)}
          {...register('userId', {
            validate: (text) => {
              if (isEqual(methods, [AuthMethod.Email])) {
                return emailValidator(text);
              }

              if (isEqual(methods, [AuthMethod.Phone])) {
                return phoneNumberValidator(text);
              }

              return phoneNumberValidator(text) || emailValidator(text);
            },
            setValueAs: (value) => value?.trim().toLowerCase(),
            required: true,
          })}
          isValid={!errors.userId}
          errorMessage={(errors.userId && getErrorMessage(methods, t)) || ''}
        />
      </div>

      <div className='auth-terms'>
        <div className='auth-terms__row'>
          <Checkbox
            variant='primary'
            checked={agreePrivacy && agreeService}
            onChange={(event) => {
              if (event.target.checked) {
                setAgreeService(true);
                setAgreePrivacy(true);
              } else {
                setAgreeService(false);
                setAgreePrivacy(false);
              }
            }}
          >
            <b>{t('user:auth.signin.terms.all')}</b>
          </Checkbox>

          <PlainButton onClick={() => setToggle((prev) => !prev)}>
            <img
              className={`auth-terms__icon ${toggle ? 'auth-terms__icon--toggle' : ''}`}
              src={ArrowIcon}
              alt='arrow'
            />
          </PlainButton>
        </div>

        <div className={`auth-terms__below ${toggle ? 'auth-terms__below--toggle' : ''}`}>
          <Checkbox
            variant='primary'
            checked={agreeService}
            onChange={() => setAgreeService((prev) => !prev)}
          >
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'service' })}>
              <b>{t('user:auth.signin.terms.service.bold1')}</b>
            </PlainButton>
            {t('user:auth.signin.terms.service.text')}
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'service' })}>
              <b>{t('user:auth.signin.terms.service.bold2')}</b>
            </PlainButton>
          </Checkbox>

          <Checkbox
            variant='primary'
            checked={agreePrivacy}
            onChange={() => setAgreePrivacy((prev) => !prev)}
          >
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'privacy' })}>
              <b>{t('user:auth.signin.terms.privacy.bold1')}</b>
            </PlainButton>
            {t('user:auth.signin.terms.privacy.text')}
            <PlainButton onClick={() => setTermModal({ isVisible: true, kind: 'privacy' })}>
              <b>{t('user:auth.signin.terms.privacy.bold2')}</b>
            </PlainButton>
          </Checkbox>
        </div>
      </div>

      <Button width='100%' size='xl' variant='primary' type='submit' disabled={isDisabledToSendOTP}>
        {t('user:auth.signin.button')}
      </Button>

      <TermModal
        isVisible={termModal.isVisible}
        onHide={() => setTermModal({ isVisible: false, kind: 'service' })}
        onCheckTerm={() =>
          termModal.kind === 'service' ? setAgreeService(true) : setAgreePrivacy(true)
        }
        kind={termModal.kind}
      />
    </form>
  );
}

export default OTPSignIn;

function getPlaceholderText(authMethods: AuthMethod[], t: TFunction) {
  if (isEqual(authMethods, [AuthMethod.Email])) {
    return t('user:auth.signin.idInput.placeholder.email');
  }

  if (isEqual(authMethods, [AuthMethod.Phone])) {
    return t('user:auth.signin.idInput.placeholder.phone');
  }

  return t('user:auth.signin.idInput.placeholder.all');
}

function getErrorMessage(authMethods: AuthMethod[], t: TFunction) {
  if (isEqual(authMethods, [AuthMethod.Email])) {
    return t('user:auth.signin.idInput.errorMessage.email');
  }

  if (isEqual(authMethods, [AuthMethod.Phone])) {
    return t('user:auth.signin.idInput.errorMessage.phone');
  }

  return t('user:auth.signin.idInput.errorMessage.all');
}
