import { Controller, useFormContext } from 'react-hook-form';
import { PrimaryBadge, SecondaryBadge, Text } from '@pickme/ui';
import { YouTubeURLParser } from '@pickme/core';
import ReactHtmlParser from 'html-react-parser';

import SurveyImage from 'components/features/poll/vote-card/SurveyImage';

import { Question } from 'types/survey';
import { VotingSurveyForm } from 'types/vote';
import type { TFunction } from 'i18next';

import './index.scss';

type Props = { question: Question; index: number; t: TFunction; active: boolean };

function ScaleQuestionVoteCard({ question, index, t, active }: Props) {
  const { register, control } = useFormContext<VotingSurveyForm>();
  const fieldName = `answers.${index}.answer` as const;

  const { isRequired, title, fileUrl, max, min, videoUrl } = question;
  const maxValue = max?.value || 0;
  const minValue = min?.value || 0;

  const itemLength = maxValue - minValue + 1;
  const items = Array.from({ length: itemLength }, (_, itemIndex) => ({
    name: `${minValue + itemIndex}`,
    value: minValue + itemIndex,
  }));

  return (
    <div className='scale-question-vote-card'>
      <div className='vote-card__label'>
        {isRequired && <PrimaryBadge variant='error'>{t('user:poll.required')}</PrimaryBadge>}
        <SecondaryBadge>{t('user:poll.surveyKind.scale.label')}</SecondaryBadge>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
        {fileUrl && <SurveyImage src={fileUrl} alt={title} />}
      </Text>

      {active && videoUrl?.youtube ? (
        <div className='vote-card__video'>
          {ReactHtmlParser(
            new YouTubeURLParser(videoUrl.youtube).getIframe({
              width: 560,
              height: 315,
              allowFullScreen: true,
            }) || '',
          )}
        </div>
      ) : (
        ''
      )}

      <div className='vote-card__items scale-question-vote-card__items'>
        <Controller
          name={fieldName}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <div className='scale-question-vote-card__scale__items'>
              {items.map((item) => {
                const isSelected = !!field.value && field.value === `${item.value}`;
                const isInactiveStyle = !!field.value && !isSelected;

                return (
                  <label
                    key={`survey-scale-${item.value}`}
                    htmlFor={`${fieldName}-${item.value}`}
                    className={`vote-card__item scale-question-vote-card__scale__item ${
                      isInactiveStyle ? 'inactive' : ''
                    } ${isSelected ? 'active' : ''}`}
                  >
                    <Text type='b3'>{item.name}</Text>
                    <input
                      {...field}
                      id={`${fieldName}-${item.value}`}
                      value={item.value}
                      type='radio'
                      name={fieldName}
                      hidden
                    />
                  </label>
                );
              })}
            </div>
          )}
        />

        <div className='scale-question-vote-card__label'>
          <Text type='b3'>{min?.description}</Text>
          <Text type='b3'>{max?.description}</Text>
        </div>
      </div>

      <input hidden {...register(`answers.${index}.questionId`)} value={question._id} />
    </div>
  );
}

export default ScaleQuestionVoteCard;
