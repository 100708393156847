import { Avatar, PlainButton } from '@pickme/ui';
import { useState } from 'react';

import ImageModal from 'components/features/poll/modal/ImageModal';

import './index.scss';

type Props = {
  src: string;
  alt: string;
  round?: boolean;
  opacity?: boolean;
};

function ElectionImage({ src, alt, round = false, opacity = false }: Props) {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <PlainButton
        className={opacity ? 'election-image--opacity' : ''}
        onClick={(event) => {
          event.preventDefault();
          if (src) {
            setModalVisible(true);
          }
        }}
      >
        <Avatar image={src} round={round} />
      </PlainButton>

      {src && (
        <ImageModal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          src={src}
          alt={alt}
        />
      )}
    </>
  );
}

export default ElectionImage;
