import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryBadge, Text } from '@pickme/ui';

import ElectionImage from 'components/features/poll/vote-card/ElectionImage';

import { SubElection } from 'types/election';
import { VotingElectionForm } from 'types/vote';
import type { TFunction } from 'i18next';

import UnvotedStamp from 'resources/icons/poll-home/unvote-stamp.png';
import VotedStamp from 'resources/icons/poll-home/vote-stamp.png';

import './index.scss';

type Props = { election: SubElection; index: number; t: TFunction };

// TODO: react-hook-form 잘쓰도록 리팩토링...
function PropsOrConsElectionVoteCard({ election, index, t }: Props) {
  const { register } = useFormContext<VotingElectionForm>();
  const fieldName = `votes.${index}` as const;

  const [candidates] = useState<{ _id: string; title: string; members?: { fileUrl?: string }[] }[]>(
    election.candidates || [],
  );

  const { title, responseValidation } = election;
  const { allowAbstentionVote = true } = responseValidation;

  return (
    <div className='props-or-cons-election-vote-card'>
      <div className='vote-card__label'>
        <SecondaryBadge>{t('user:poll.electionKind.propsOrCons.label')}</SecondaryBadge>
        <Text type='b4' color='gray-400'>
          {t('user:poll.electionKind.propsOrCons.text', {
            abstention: allowAbstentionVote ? `, ${t('user:poll.abstention')}` : '',
          })}
        </Text>
      </div>

      <Text type='b1' fontWeight={600} className='vote-card__title'>
        {title}
      </Text>

      <div className='vote-card__items'>
        {candidates.map((candidate, candidateIndex) => (
          <VoteItem
            key={candidate._id}
            image={candidate?.members?.[0]?.fileUrl || ''}
            title={candidate?.title || ''}
            id={candidate._id}
            fieldName={`${fieldName}.votesPerCandidate.${candidateIndex}`}
            isOpenAbstention={allowAbstentionVote}
            t={t}
          />
        ))}
      </div>

      <input hidden {...register(`votes.${index}.subElectionOid`)} value={election._id} />
    </div>
  );
}

export default PropsOrConsElectionVoteCard;

function VoteItem({
  image,
  title,
  id,
  fieldName,
  isOpenAbstention,
  t,
}: {
  image?: string;
  title: string;
  id: string;
  fieldName: `votes.${number}.votesPerCandidate.${number}`;
  isOpenAbstention: boolean;
  t: TFunction;
}) {
  const { register, control } = useFormContext<VotingElectionForm>();

  const commonSelections = [
    { title: t('user:poll.result.propsOrCons.favor'), id: '1' },
    { title: t('user:poll.result.propsOrCons.against'), id: '0' },
  ];
  const selections = isOpenAbstention
    ? [...commonSelections, { title: t('user:poll.abstention'), id: '-1' }]
    : commonSelections;

  return (
    <div className='vote-card__item props-or-cons-election-vote-card__item'>
      <div className='props-or-cons-election-vote-card__item__header'>
        <ElectionImage src={image || ''} alt={title} round />

        <Text className='props-or-cons-election-vote-card__item__title' type='b3'>
          {title}
        </Text>
      </div>

      <div className='props-or-cons-election-vote-card__item__body'>
        {selections.map((selection) => (
          <Controller
            name={`${fieldName}.value`}
            control={control}
            rules={{}}
            render={({ field }) => (
              <Selection
                {...selection}
                onChange={(selectionId: string) => field.onChange(selectionId)}
                fieldName={`${fieldName}.value`}
                isSelected={`${field.value}` === selection.id}
              />
            )}
          />
        ))}
        <input hidden {...register(`${fieldName}.candidateOid`)} value={id} />
      </div>
    </div>
  );
}

function Selection({
  title,
  id,
  onChange,
  fieldName,
  isSelected,
}: {
  title: string;
  id: string;
  onChange: Function;
  fieldName: string;
  isSelected: boolean;
}) {
  return (
    <label
      htmlFor={`${fieldName}-${id}`}
      className='props-or-cons-election-vote-card__item__selection'
    >
      <Text type='b3' fontWeight={400}>
        {title}
      </Text>

      <input
        hidden
        type='checkbox'
        checked={isSelected}
        onChange={() => onChange(id)}
        id={`${fieldName}-${id}`}
        name={fieldName}
      />
      <img
        src={UnvotedStamp}
        alt='stamp'
        className='props-or-cons-election-vote-card__item__stamp'
      />
      <img src={VotedStamp} alt='stamp' className='props-or-cons-election-vote-card__item__stamp' />
    </label>
  );
}
