import { PollGroup } from '@pickme/core';
import { SubElection, SubElectionResult } from './election';
import { Question, QuestionResult } from './survey';

export type PollStat = { title: string; count: number; alias: PollGroup };
export type PollKind = 'Election' | 'Survey';

export type Poll = Election | Survey;

export type Election = { subElections: SubElection[] } & CommonPoll;
export type Survey = { questions: Question[] } & CommonPoll;

export enum PollViewMode {
  Table = 'table',
  Card = 'card',
}

type CommonPoll = {
  _id: string;
  authorId: string;
  cachedVoteCount: {
    count: number;
    lastUpdate: string;
  };
  description: string;
  endDate: { updatedAt: string; history: { date: string; updatedAt: string }[]; date: string };
  group: PollGroup;
  hasResult: boolean;
  hasVoted: boolean;
  hasOpenVotes: boolean;
  allowRealTimeResult: boolean;
  isInBreakTime: boolean;
  isOpenPollRate: boolean;
  isOpenPollResult: boolean;
  isPause: boolean;
  kind: PollKind;
  name: string;
  openThreshold: number;
  organizationId: string;
  periods: { startDate: string; endDate: string }[];
  population: {
    count: number;
    lastUpdate: string;
  };
  rate: number;
  stage: 'pre' | 'in' | 'post';
  startDate: string;
  status: 'approved' | 'pending' | 'canceled';
  hasInstantVoter?: boolean;
  coverImageFileUrl?: string;
};

export type PollResult = {
  title: string;
  group: PollGroup;
  author: {
    name: string;
    email: string;
  };
  rate: {
    population: number;
    voteCount: number;
    rate: number;
    rateByHour: {
      date: string;
      count: number;
    }[];
  };
  subElectionResults?: SubElectionResult[];
  questionResults?: QuestionResult[];
};

export type PollRate = {
  population: number;
  voteCount: number;
  rate: number;
  rateByHour: {
    date: string;
    count: number;
  }[];
  lastUpdate: string;
};

export type Voter = {
  category: string;
  email: string;
  hasVoted: boolean;
  name: string;
  organizationId: string;
  phoneNumber: string;
  pollId: string;
  userId?: string;
};

export type PollQuery = {
  page: number;
};
