export enum AuthMethod {
  Email = 'email',
  Phone = 'phoneNumber',
}

export type OTPSignInBody = {
  type: 'email' | 'phoneNumber';
  token: string;
};

export type SendResetMailBody = {
  email: string;
};

export type Session = {
  type: 'voter';
  _id: string;
  hasAgreed: boolean;
  expires: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  channelTalkMemberHash: string;
};
