import { Text, Button, Input, PlainButton } from '@pickme/ui';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDigitInput from 'hooks/useDigitInput';

import EmailPendingImage from 'resources/icons/auth/email_waiting.png';
import PhonePendingImage from 'resources/icons/auth/phone_waiting.png';

import './index.scss';

type Props = {
  type: 'email' | 'phone';
  target: string;
  resend: () => void;
  onSubmitCode: (code: string) => void;
};

function OTPVerificationForm({ type, target, resend, onSubmitCode }: Props) {
  const { t } = useTranslation();

  const [otp, setOtp] = useState('');
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: otp,
    onChange: setOtp,
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmitCode(otp);
  };

  return (
    <form onSubmit={onSubmit} className='otp-verification'>
      <img
        className='otp-verification__image'
        src={type === 'email' ? EmailPendingImage : PhonePendingImage}
        alt='wait'
      />
      <Text type='h4' className='otp-verification__target'>
        {type === 'email' ? target : target.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
      </Text>
      <Text color='gray-400' className='otp-verification__description'>
        {t('user:auth.signin.description')}
      </Text>

      <div className='otp-verification__digits'>
        <Input autoFocus inputMode='decimal' {...digits[0]} />
        <Input inputMode='decimal' {...digits[1]} />
        <Input inputMode='decimal' {...digits[2]} />
        <Input inputMode='decimal' {...digits[3]} />
        <Input inputMode='decimal' {...digits[4]} />
        <Input inputMode='decimal' {...digits[5]} />
      </div>

      <Button
        width='100%'
        size='xl'
        variant='primary'
        type='submit'
        disabled={!/^[0-9]{6}$/gi.test(otp)}
      >
        {t('user:auth.signin.button')}
      </Button>

      <div className='auth-form__link'>
        <Text size='sm1'>
          {t('user:auth.signin.retry.text')}
          <PlainButton onClick={() => resend()}> {t('user:auth.signin.retry.button')}</PlainButton>
        </Text>
      </div>
    </form>
  );
}

export default OTPVerificationForm;
