import { PlainButton } from '@pickme/ui';
import { useState } from 'react';

import ImageModal from 'components/features/poll/modal/ImageModal';

import './index.scss';

type Props = {
  src: string;
  alt: string;
  height?: number;
};

function SurveyImage({ src, alt, height }: Props) {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <PlainButton
        onClick={(event) => {
          event.preventDefault();
          setModalVisible(true);
        }}
      >
        <img
          src={src}
          alt={alt}
          className='vote-card__image'
          style={height ? { maxHeight: height } : {}}
        />
      </PlainButton>

      <ImageModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        src={src}
        alt={alt}
      />
    </>
  );
}

export default SurveyImage;
