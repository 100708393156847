import { useParams } from 'react-router-dom';
import ElectionCandidates from 'components/features/poll/Candidates';

import { useGetPoll } from 'query-hooks/poll';
import { PollGroup } from '@pickme/core';

function ElectionCandidatesContainer() {
  const { id = '' } = useParams<{ id: string; subElectionId: string }>();

  const { data: poll } = useGetPoll(id);

  const isVisibleVoteButton =
    (poll?.group === PollGroup.Ongoing || poll?.group === PollGroup.Paused) &&
    !poll?.hasVoted &&
    !poll.isInBreakTime &&
    !poll.isPause;

  return <ElectionCandidates poll={poll} isVisibleVoteButton={isVisibleVoteButton} />;
}

export default ElectionCandidatesContainer;
