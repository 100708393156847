import { PollGroup } from '@pickme/core';
import { GradientDoughnutGraph, Text } from '@pickme/ui';
import { useGetPollRate } from 'query-hooks/poll';
import dayjs from 'dayjs';
import { t } from 'i18next';

import PollInfoModalLayout from 'components/features/poll/modal/PollInfoModalLayout';

import { setColorsForRateGraph } from 'functions/rate';
import { parsePercentLabel } from 'functions/result';

import VotersIcon from 'resources/icons/poll-home/voters.png';
import VoterIcon from 'resources/icons/poll-home/voter.png';
import RateIcon from 'resources/icons/poll-home/modal-rate.png';

import './index.scss';

type Props = {
  id: string;
  isVisible: boolean;
  onClose: () => void;
  title: string;
  period: string;
  endDate: string;
  pollGroup: PollGroup;
};

function PollRateModal({ id, pollGroup, isVisible, onClose, title, period, endDate }: Props) {
  const { data } = useGetPollRate(isVisible ? id : '');

  const colors = setColorsForRateGraph(pollGroup);
  const rateLabel = parsePercentLabel(data?.rate || 0);

  const isEndedPoll = dayjs().isAfter(dayjs(endDate));

  return (
    <PollInfoModalLayout
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      period={period}
      className='poll-rate-modal'
      isShare
    >
      <div className='poll-rate-modal__graph'>
        <GradientDoughnutGraph gradientColors={colors} value={(data?.rate || 0) * 100} size={100} />
      </div>

      <Text type='b3' color='gray-400'>
        {t('user:pollModal.rate.base', {
          date: dayjs(isEndedPoll ? endDate : data?.lastUpdate)
            .tz('Asia/Seoul')
            .format('YYYY/MM/DD HH:mm'),
        })}
      </Text>

      <div className='poll-rate-modal__items'>
        <div className='poll-rate-modal__item'>
          <img src={VotersIcon} alt={t('user:pollModal.rate.count.total') || ''} />
          <Text type='b4' fontWeight={400} color='gray-400'>
            {t('user:pollModal.rate.count.total')}
          </Text>
          <Text type='b3' fontWeight={500}>
            {data?.population || 0}
            {t('user:pollModal.rate.count.unit')}
          </Text>
        </div>

        <div className='poll-rate-modal__item'>
          <img src={VoterIcon} alt={t('user:pollModal.rate.count.participant') || ''} />
          <Text type='b4' fontWeight={400} color='gray-400'>
            {t('user:pollModal.rate.count.participant')}
          </Text>
          <Text type='b3' fontWeight={500}>
            {data?.voteCount || 0}
            {t('user:pollModal.rate.count.unit')}
          </Text>
        </div>

        <div className='poll-rate-modal__item'>
          <img src={RateIcon} alt={t('user:pollModal.rate.rate') || ''} />
          <Text type='b4' fontWeight={400} color='gray-400'>
            {t('user:pollModal.rate.rate')}
          </Text>
          <Text type='b3' fontWeight={500}>
            {rateLabel}
          </Text>
        </div>
      </div>
    </PollInfoModalLayout>
  );
}

export default PollRateModal;
