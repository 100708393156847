import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { useRecoilState } from 'recoil';

import { timeoutModal } from 'states/modal';

import TimeoutIcon from 'resources/icons/auth/logout-timeout.png';

import './index.scss';

function TimeoutModal() {
  const { t } = useTranslation();

  const [isVisible, setVisible] = useRecoilState(timeoutModal);

  const onHide = () => setVisible(false);

  return (
    <Modal isVisible={isVisible} onClose={onHide} className='timeout-modal'>
      <ModalHeader onClose={onHide} />
      <ModalBody>
        <Text type='b1' fontWeight={600}>
          {t('user:modal.timeout.title')}
        </Text>

        <img src={TimeoutIcon} alt={t('user:modal.timeout.title') || ''} />

        <Text type='b3' color='gray-400'>
          {t('user:modal.timeout.description')}
        </Text>
      </ModalBody>
    </Modal>
  );
}

export default TimeoutModal;
