import { useTranslation } from 'react-i18next';
import { Text } from '@pickme/ui';

import { useRecoilState } from 'recoil';
import { votingCompletedModal } from 'states/modal';

import PollInfoModalLayout from 'components/features/poll/modal/PollInfoModalLayout';

import ConfettiIcon from 'resources/icons/poll-home/confetti.png';
import ConfettiCenterIcon from 'resources/icons/poll-home/confetti-center.png';

import './index.scss';

type Props = { title: string; period: string };

function VotingCompletedModal({ title, period }: Props) {
  const { t } = useTranslation();

  const [isVisible, setVisible] = useRecoilState(votingCompletedModal);

  const onHide = () => setVisible(false);

  return (
    <PollInfoModalLayout
      isVisible={isVisible}
      onClose={onHide}
      title={title}
      period={period}
      className='voting-completed-modal'
    >
      <div className='voting-completed-modal__body'>
        <div className='voting-completed-modal__confetti'>
          <img src={ConfettiIcon} alt='축하' className='voting-completed-modal__confetti__bg' />
          <img
            src={ConfettiCenterIcon}
            alt='축하'
            className='voting-completed-modal__confetti__center'
          />
        </div>

        <Text type='b3' fontWeight={600} color='gray-500'>
          {t('user:pollModal.completedVoting.main')}
        </Text>
        <Text type='b3' color='gray-400'>
          {t('user:pollModal.completedVoting.download')}
        </Text>
      </div>
    </PollInfoModalLayout>
  );
}

export default VotingCompletedModal;
