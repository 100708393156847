import { useParams } from 'react-router-dom';

import VoteSurvey from 'components/features/poll/VoteSurvey';

import { useGetPoll } from 'query-hooks/poll';
import { useGetSession } from 'query-hooks/auth';
import { useVote } from 'query-hooks/vote';

import { useCheckVotable } from 'hooks/useCheckVotable';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

import { VotingSurveyForm } from 'types/vote';

function VoteSurveyContainer() {
  const { id = '' } = useParams<{ id: string }>();

  const isExceptionalPoll = EXCEPTIONAL_POLL_IDS.includes(id);

  const { data: auth } = useGetSession({ disabled: isExceptionalPoll });
  const { data: poll, isError } = useGetPoll(id);

  useCheckVotable({ isError, poll, pollId: id, kind: 'Survey' });
  const { mutate: vote, isLoading } = useVote();

  const onSubmit = (voteData: VotingSurveyForm) => {
    vote({ data: voteData, hasInstantVoter: poll?.hasInstantVoter });
  };

  return (
    <VoteSurvey poll={poll} onSubmit={onSubmit} voterOid={auth?._id || ''} isLoading={isLoading} />
  );
}

export default VoteSurveyContainer;
