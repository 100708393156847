import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import VoteLayout from 'components/features/poll/layout/Vote';
import RadioQuestionVoteCard from 'components/features/poll/vote-card/RadioQuestion';
import CheckboxQuestionVoteCard from 'components/features/poll/vote-card/CheckboxQuestion';
import TextQuestionVoteCard from 'components/features/poll/vote-card/TextQuestion';
import ScaleQuestionVoteCard from 'components/features/poll/vote-card/ScaleQuestion';
import RankQuestionVoteCard from 'components/features/poll/vote-card/RankQuestion';
import ScoreQuestionVoteCard from 'components/features/poll/vote-card/ScoreQuestion';
import ConfirmModal from 'components/common/modal/ConfirmModal';

import { confirmModal } from 'states/modal';
import { toast } from 'states/toast';

import { shuffleVotingOptions } from 'functions/polls';
import { checkSurveyMovableNextStep } from 'functions/vote';

import { VotingSurveyForm } from 'types/vote';
import { Poll, Survey } from 'types/poll';
import { QuestionSelection } from 'types/survey';

type Props = {
  poll?: Poll;
  voterOid: string;
  onSubmit: (data: VotingSurveyForm) => void;
  isLoading?: boolean;
};

function VoteSurvey({ poll, voterOid, onSubmit, isLoading = false }: Props) {
  const { t } = useTranslation();

  const setModalVisible = useSetRecoilState(confirmModal);
  const setToast = useSetRecoilState(toast);

  const { id = '' } = useParams<{ id: string }>();

  const questions = (poll as Survey)?.questions || [];

  const [step, setStep] = useState(1);
  const lastStep = questions.length;

  const VoteForm = useForm<VotingSurveyForm>({
    defaultValues: {
      voterOid,
      pollId: id,
      organizationId: poll?.organizationId || '',
      answers: [],
    },
  });
  const { handleSubmit, setValue, getValues, watch } = VoteForm;

  useEffect(() => {
    if (voterOid) {
      setValue('voterOid', voterOid);
    }
  }, [voterOid]);

  useEffect(() => {
    if (poll?.organizationId) {
      setValue('organizationId', poll?.organizationId);
    }
  }, [[poll?.organizationId]]);

  const onShowVoteModal = () => setModalVisible(true);

  const onVote = () => {
    const voteData = getValues();
    onSubmit(voteData);
  };

  const { isMovableNextStep, errorMessage } = checkSurveyMovableNextStep(questions, step, watch);

  return (
    <VoteLayout
      name={poll?.name || ''}
      buttons={{
        prev: {
          text: t('user:poll.buttons.prev'),
          onClick: () => setStep((prev) => prev - 1),
          disabled: step === 1,
        },
        next: {
          text: t('user:poll.buttons.next'),
          onClick: () => {
            if (!isMovableNextStep) {
              setToast({ isVisible: true, type: 'error', message: errorMessage || '' });
              return;
            }

            setStep((prev) => prev + 1);
          },
          disabled: isLoading,
        },
        submit: {
          text: t('user:poll.buttons.submit'),
          onClick: () => {
            if (!isMovableNextStep) {
              setToast({ isVisible: true, type: 'error', message: errorMessage || '' });
              return;
            }

            onShowVoteModal();
          },
          disabled: isLoading,
        },
      }}
      step={step}
      lastStep={lastStep}
    >
      <FormProvider {...VoteForm}>
        <form onSubmit={handleSubmit(onShowVoteModal)}>
          {questions?.map((question, index) => {
            const isRadioQuestion = question.kind === 'RadioQuestion';
            const isCheckboxQuestion = question.kind === 'CheckboxQuestion';
            const isTextQuestion = question.kind === 'TextQuestion';
            const isScaleQuestion = question.kind === 'ScaleQuestion';
            const isRankQuestion = question.kind === 'RankQuestion';
            const isScoreQuestion = question.kind === 'ScoreQuestion';

            let selections = question.selections || question.subQuestions || [];

            if (question.shuffle) {
              selections = shuffleVotingOptions(
                question.selections || question.subQuestions || [],
              ) as QuestionSelection[];
            }

            return (
              <div key={question._id} style={{ display: `${step === index + 1 ? '' : 'none'}` }}>
                {isRadioQuestion && (
                  <RadioQuestionVoteCard
                    question={{ ...question, selections }}
                    index={index}
                    active={step === index + 1}
                    t={t}
                    pollId={id}
                  />
                )}

                {isCheckboxQuestion && (
                  <CheckboxQuestionVoteCard
                    question={{ ...question, selections }}
                    index={index}
                    active={step === index + 1}
                    t={t}
                  />
                )}

                {isTextQuestion && (
                  <TextQuestionVoteCard
                    question={question}
                    index={index}
                    active={step === index + 1}
                    t={t}
                  />
                )}

                {isScaleQuestion && (
                  <ScaleQuestionVoteCard
                    question={question}
                    index={index}
                    active={step === index + 1}
                    t={t}
                  />
                )}

                {isRankQuestion && (
                  <RankQuestionVoteCard
                    question={{ ...question, subQuestions: selections }}
                    index={index}
                    active={step === index + 1}
                    t={t}
                    pollId={id}
                  />
                )}

                {isScoreQuestion && (
                  <ScoreQuestionVoteCard
                    question={{ ...question }}
                    index={index}
                    active={step === index + 1}
                    t={t}
                  />
                )}
              </div>
            );
          })}
        </form>
      </FormProvider>
      <ConfirmModal
        title={t('user:pollModal.vote.title')}
        text={t('user:pollModal.vote.text') || ''}
        onClick={onVote}
        buttons={{ confirm: t('user:pollModal.vote.confirm') || '' }}
        isLoading={isLoading}
      />
    </VoteLayout>
  );
}

export default VoteSurvey;
