import { ReactNode } from 'react';
import { Text } from '@pickme/ui';
import Slider from 'react-slick';

import Header from 'components/common/layout/Header';

import './index.scss';

type Props = {
  children: ReactNode;
  name: string;
};

function PollResultLayout({ children, name }: Props) {
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
  };

  return (
    <div className='poll-result-layout'>
      <Header isBackButtonVisible bgColor='gray-50'>
        <Text type='b2' fontWeight={400}>
          {name}
        </Text>
      </Header>

      <main className='poll-result-layout__body'>
        <Slider {...settings} className='poll-result-layout__body__items'>
          {children}
        </Slider>
      </main>
    </div>
  );
}

export default PollResultLayout;
