/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  rate: {
    base: '{{date}} 기준',
    count: {
      total: '총 대상자 수',
      participant: '참여자 수',
      unit: '명',
    },
    rate: '투표율',
  },
  certificate: {
    Election: '투표 참여를 완료했어요!',
    Survey: '설문 참여를 완료했어요!',
  },
  vote: {
    title: '최종 제출하시겠어요?',
    text: '최종 제출 후엔 응답 내용을 변경할 수 없어요.',
    confirm: '제출하기',
  },
  completedVoting: {
    main: '투표 참여를 완료했어요!',
    download: '홈에서 인증서를 다운로드할 수 있어요',
  },
  layout: {
    share: '공유하기',
    download: '다운로드',
  },
};
