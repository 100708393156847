/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  header: {
    menu: {
      contact: '문의하기',
      logout: '로그아웃',
    },
    serviceModal: {
      title: '서비스 문의',
      contact: '실시간 채팅 상담하기',
    },
    logoutModal: {
      title: '정말 로그아웃하시겠어요?',
      confirm: '로그아웃',
    },
  },
  empty: {
    empty: '아직 참여 가능한 투표가 없어요.',
    wait: '투표가 시작되기 전까지\n조금만 기다려주세요!',
  },
  callout: {
    title: '지금 참여 가능한 투표가 없나요?',
    description: [
      '투표 ',
      '관리자가 투표인 명부에 등록한 전화번호',
      ' 혹은 ',
      '이메일 주소',
      '로 본인 인증을 해야 참여 가능한 투표가 나와요.',
      '다른 방법으로 인증해도 투표가 보이지 않는다면 투표 관리자에게 문의해주세요.',
    ],
    button: '다른 방법으로 인증하기',
  },
};
