import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import VoteLayout from 'components/features/poll/layout/Vote';
import ChoiceElectionVoteCard from 'components/features/poll/vote-card/ChoiceElection';
import ScoreElectionVoteCard from 'components/features/poll/vote-card/ScoreElection';
import PropsOrConsElectionVoteCard from 'components/features/poll/vote-card/ProsOrConsElection';
import RankElectionVoteCard from 'components/features/poll/vote-card/RankElection';
import ConfirmModal from 'components/common/modal/ConfirmModal';

import { confirmModal } from 'states/modal';
import { toast } from 'states/toast';

import { shuffleVotingOptions } from 'functions/polls';
import { checkElectionMovableNextStep } from 'functions/vote';

import { VotingElectionForm } from 'types/vote';
import { Election, Poll } from 'types/poll';
import { Candidate } from 'types/election';

type Props = {
  poll?: Poll;
  voterOid: string;
  onSubmit: Function;
  isLoading?: boolean;
};

function VoteElection({ poll, onSubmit, voterOid, isLoading = false }: Props) {
  const { t } = useTranslation();

  const setModalVisible = useSetRecoilState(confirmModal);
  const setToast = useSetRecoilState(toast);

  const { id = '' } = useParams<{ id: string }>();

  const elections = (poll as Election)?.subElections || [];

  const [step, setStep] = useState(1);
  const lastStep = elections.length;

  const VoteForm = useForm<VotingElectionForm>({
    defaultValues: {
      pollId: id,
      voterOid,
      votes: [],
    },
  });
  const { handleSubmit, getValues, setValue, watch } = VoteForm;

  useEffect(() => {
    if (voterOid) {
      setValue('voterOid', voterOid);
    }
  }, [voterOid]);

  const onShowVoteModal = () => setModalVisible(true);

  const onVote = () => {
    const voteData = getValues();
    onSubmit(voteData);
  };

  const { isMovableNextStep, errorMessage } = checkElectionMovableNextStep(elections, step, watch);

  return (
    <VoteLayout
      name={poll?.name || ''}
      buttons={{
        prev: {
          text: t('user:poll.buttons.prev'),
          onClick: () => setStep((prev) => prev - 1),
          disabled: step === 1,
        },
        next: {
          text: t('user:poll.buttons.next'),
          onClick: () => {
            if (!isMovableNextStep) {
              setToast({ isVisible: true, type: 'error', message: errorMessage || '' });
              return;
            }

            setStep((prev) => prev + 1);
          },
          disabled: isLoading,
        },
        submit: {
          text: t('user:poll.buttons.submit'),
          onClick: () => {
            if (!isMovableNextStep) {
              setToast({ isVisible: true, type: 'error', message: errorMessage || '' });
              return;
            }

            onShowVoteModal();
          },
          disabled: isLoading,
        },
      }}
      step={step}
      lastStep={lastStep}
    >
      <FormProvider {...VoteForm}>
        <form onSubmit={handleSubmit(onShowVoteModal)}>
          {elections?.map((election, index) => {
            const isChoiceElection = election.kind === 'ChoiceElection';
            const isScoreElection = election.kind === 'ScoreElection';
            const isPropsOrConsElection = election.kind === 'ProsOrConsElection';
            const isRankElection = election.kind === 'RankElection';

            let candidates = election.candidates || [];
            if (election.shuffle) {
              candidates = shuffleVotingOptions(election.candidates) as Candidate[];
            }

            return (
              <div key={election._id} style={{ display: `${step === index + 1 ? '' : 'none'}` }}>
                {isChoiceElection && (
                  <ChoiceElectionVoteCard
                    election={{ ...election, candidates }}
                    index={index}
                    t={t}
                  />
                )}

                {isScoreElection && (
                  <ScoreElectionVoteCard
                    election={{ ...election, candidates }}
                    index={index}
                    t={t}
                  />
                )}

                {isPropsOrConsElection && (
                  <PropsOrConsElectionVoteCard
                    election={{ ...election, candidates }}
                    index={index}
                    t={t}
                  />
                )}

                {isRankElection && (
                  <RankElectionVoteCard
                    election={{ ...election, candidates }}
                    index={index}
                    t={t}
                  />
                )}
              </div>
            );
          })}
        </form>
      </FormProvider>

      <ConfirmModal
        title={t('user:pollModal.vote.title')}
        text={t('user:pollModal.vote.text') || ''}
        onClick={onVote}
        buttons={{ confirm: t('user:pollModal.vote.confirm') || '' }}
        isLoading={isLoading}
      />
    </VoteLayout>
  );
}

export default VoteElection;
