import dayjs from 'dayjs';
import { Poll, PollKind } from 'types/poll';
import { Candidate } from 'types/election';
import { QuestionSelection } from 'types/survey';

export const shuffleVotingOptions = (votingOptions: (Candidate | QuestionSelection)[]) => {
  const shuffledVotingOptions = votingOptions
    .map((option) => ({ option, sort: Math.random() }))
    .sort((prev, curr) => curr.sort - prev.sort)
    .map(({ option }) => option);

  return shuffledVotingOptions;
};

export const filterPolls = (polls: Poll[], kind?: PollKind) => {
  if (!kind) {
    return polls;
  }

  const filteredPolls = polls.filter((poll) => poll.kind === kind);
  return filteredPolls;
};

export const sortPolls = (polls: Poll[]) => {
  const [ongoingPolls, finishedPolls, scheduledPolls, nullityPolls] =
    sortPollsByStatusAndStage(polls);

  ongoingPolls.sort(comparePollsWithStartDate).sort(comparePollsByPopulation);
  finishedPolls.sort(comparePollsWithStartDate).sort(comparePollsByPopulation);
  scheduledPolls.sort(comparePollsWithStartDate).sort(comparePollsByPopulation);
  nullityPolls.sort(comparePollsWithStartDate).sort(comparePollsByPopulation);

  return [ongoingPolls, finishedPolls, scheduledPolls, nullityPolls];
};

export const sortPollsByStatusAndStage = (polls: Poll[]) => {
  const ongoingPolls: Poll[] = [];
  const finishedPolls: Poll[] = [];
  const scheduledPolls: Poll[] = [];
  const nullityPolls: Poll[] = [];

  polls.forEach((poll) => {
    if (poll.status === 'canceled') {
      nullityPolls.push(poll);
    } else if (poll.stage === 'in') {
      ongoingPolls.push(poll);
    } else if (poll.stage === 'post') {
      finishedPolls.push(poll);
    } else if (poll.stage === 'pre') {
      scheduledPolls.push(poll);
    }
  });

  return [ongoingPolls, finishedPolls, scheduledPolls, nullityPolls];
};

export const comparePollsByPopulation = (prevPoll: Poll, currPoll: Poll) => {
  const prevPollPopulation = prevPoll.population.count;
  const currPollPopulation = currPoll.population.count;

  if (currPollPopulation > prevPollPopulation) {
    return 1;
  }
  if (prevPollPopulation > currPollPopulation) {
    return -1;
  }

  return 0;
};

export const comparePollsWithStartDate = (prevPoll: Poll, currPoll: Poll) => {
  const prevPollStartDate = Number(parseStartDate(prevPoll.startDate));
  const currPollStartDate = Number(parseStartDate(currPoll.startDate));

  if (currPollStartDate > prevPollStartDate) {
    return 1;
  }
  if (prevPollStartDate > currPollStartDate) {
    return -1;
  }

  return 0;
};

export const parseStartDate = (startDate: string) => dayjs(startDate).format('YYYYMMDDhhmm');
