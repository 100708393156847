/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  kind: {
    election: '선거',
    survey: '설문',
  },
  status: {
    ready: '예정',
    ongoing: '진행',
    ended: '종료',
    counted: '개표',
    canceled: '무효',
  },
  buttons: {
    vote: '투표하기',
    prev: '이전',
    next: '다음',
    submit: '제출하기',
  },
  survey: {
    anonymous: '본 설문조사는 익명성이 보장돼요.',
    realName: '본 설문조사는 익명성이 보장되지 않아요.',
  },
  overview: {
    rate: '투표율',
    currentStatus: '현황',
    result: '결과',
    certificate: '인증',
  },
  more: '더보기',
  errorTitle: '투표 실패',
  errorMessages: {
    canceled: '무효처리된 {{poll}}에요',
    currentStatus: '현황이 제공되지 않는 {{poll}}에요',
    rate: '실시간 투표율이 제공되지 않는 {{poll}}에요',
    result: '결과가 제공되지 않는 {{poll}}에요',
    notCount: '개표 전이에요. 조금만 기다려주세요!',
    notVoted: '아직 {{poll}}에 참여하지 않으셨어요',
    choiceElection: '후보자를 선택해주세요',
    score: '점수를 모두 입력해주세요',
    invalidScore: '최소 {{minScore}}점부터 최대 {{maxScore}}점까지 입력할 수 있어요',
    prosOrConsElection: '찬성, 반대{{abstention}} 중 선택해주세요',
    rank: '순위를 모두 선택해주세요',
    required: '필수 응답 항목이에요',
  },
  electionInfo: '투표',
  unit: {
    vote: '표',
    score: '점',
  },
  abstention: '기권',
  required: '필수',
  electionKind: {
    choice: {
      label: '선택 투표',
      text: '{{response}}명을 선택해주세요',
    },
    score: {
      label: '점수 투표',
      text: '최소 {{minScore}}점부터 최대 {{maxScore}}점까지 입력할 수 있어요',
    },
    propsOrCons: {
      label: '찬반 투표',
      text: '찬성, 반대{{abstention}} 중 선택할 수 있어요',
    },
    rank: {
      label: '순위 투표',
      text: '{{rankLimit}}위까지 선택해주세요',
    },
  },
  surveyKind: {
    radio: {
      label: '객관식 질문 (단일)',
    },
    checkbox: {
      label: '객관식 질문 (복수)',
      text: '{{threshold}}개의 항목을 선택해주세요',
    },
    text: {
      label: '주관식 질문',
      placeholder: '의견을 작성해주세요',
    },
    scale: {
      label: '선형 배율 질문',
    },
    rank: {
      label: '선호도 평가 질문',
      text: '{{rankLimit}}위까지 선택해주세요',
    },
    score: {
      label: '점수 평가 질문',
      text: '최소 {{minScore}}점부터 최대 {{maxScore}}점까지 입력할 수 있어요',
    },
  },
  result: {
    surveyTotal: '총 응답자 수 {{total}}명',
    surveyMultipleAnswer: '복수 응답 포함',
    score: {
      election: {
        score: '총 점수',
        participant: '투표 인원수',
        average: '평균',
      },
      survey: {
        score: '총 점수',
        participant: '응답자 수',
        average: '평균점수',
      },
    },
    propsOrCons: {
      favor: '찬성',
      against: '반대',
    },
    rank: {
      unit: {
        1: '위',
        2: '위',
        3: '위',
        other: '위',
      },
      weightedScore: '가중치 점수',
      tooltip: '가중치 점수란? 1위=3점, 2위 2점, 3위=1점을 부과하여 계산한 값이에요',
    },
    surveyTextEmpty: '표시할 내용이 없습니다',
    tooltip: {
      rate: '득표율',
      count: '득표수',
    },
  },
};
