import { useEffect, useState } from 'react';
import { Poll } from 'types/poll';

export const useEmbeddedPoll = () => {
  const [poll, setPoll] = useState<Poll>();

  useEffect(() => {
    window.parent.postMessage('set', '*');
  }, []);

  useEffect(() => {
    const onReceivePollData = ({ data, origin }: MessageEvent) => {
      const allowed =
        origin === import.meta.env.VITE_ADMIN_URL ||
        origin === import.meta.env.VITE_BLOG_URL ||
        origin === import.meta.env.VITE_SYSTEM_ADMIN_URL;

      if (!allowed) {
        return;
      }

      const isPollData = data?.kind === 'Election' || data?.kind === 'Survey';
      if (isPollData) {
        setPoll(data);
      }
    };

    window.addEventListener('message', onReceivePollData);

    return () => window.removeEventListener('message', onReceivePollData);
  }, []);

  return poll;
};
