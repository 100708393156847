import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, Text } from '@pickme/ui';

import Header from 'components/common/layout/Header';
import CandidateCards from 'components/features/poll/Candidates/CandidateCards';

import { Election, Poll } from 'types/poll';

import './index.scss';

type Props = {
  poll?: Poll;
  isVisibleVoteButton: boolean;
  isEmbedded?: boolean;
};

function ElectionCandidates({ poll, isVisibleVoteButton, isEmbedded }: Props) {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const { subElectionId = '' } = useParams<{ id: string; subElectionId: string }>();

  const election = (poll as Election)?.subElections?.find(({ _id }) => _id === subElectionId);

  return (
    <div className={`election-candidates ${isVisibleVoteButton ? 'visible-button' : ''}`}>
      <Header isBackButtonVisible bgColor='gray-50'>
        <Text fontWeight={500}>{election?.title || ''}</Text>
      </Header>

      <CandidateCards candidates={election?.candidates || []} />

      {isVisibleVoteButton && (
        <Link
          to={`${isEmbedded ? '/embed/poll' : `/poll/${id}`}/vote/${
            poll?.kind === 'Election' ? 'election' : 'survey'
          }`}
        >
          <Button size='lg' className='poll-home__vote-button'>
            {t('user:poll.buttons.vote')}
          </Button>
        </Link>
      )}
    </div>
  );
}

export default ElectionCandidates;
