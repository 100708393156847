import VoteSurvey from 'components/features/poll/VoteSurvey';

import { useEmbeddedPoll } from 'hooks/useEmbed';

import { VotingSurveyForm } from 'types/vote';

function VoteAdminSurvey() {
  const survey = useEmbeddedPoll();

  const onSubmit = (voteData: VotingSurveyForm) => {
    const { answers } = voteData;
    window.parent.postMessage(answers, '*');
  };

  return <VoteSurvey poll={survey} onSubmit={onSubmit} voterOid='' />;
}

export default VoteAdminSurvey;
