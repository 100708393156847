/* THIS FILE IS AUTO-GENERATED. DO NOT MODIFY MANUALLY. */
export default {
  confirm: {
    confirm: '확인',
    cancel: '취소',
  },
  compliance: {
    title: '온라인 투표 준수사항',
    descriptions: [
      '온라인 투표 진행에서 부정 투표를 방지하기 위하여 개인의 투표일시와 정보가 기록됩니다.',
      '만약 아래 사항을 위반할 경우 관련법에 따라 조치 될 수 있음을 알려드립니다.',
    ],
    terms: [
      '본인 외 다른 사람의 대리 투표를 하지 않는다.',
      '본인의 IP주소, 이름, 전화번호 또는 이메일 주소, 고유번호를 기록으로 남기는 것에 동의한다.',
    ],
    agree: {
      text1: '본인은 ',
      bold1: '“온라인 투표 준수사항”',
      text2: ' 내용을 읽고\n 명확히 이해하였으며 이에 동의합니다.',
    },
    button: '동의하기',
  },
  timeout: {
    title: '자동 로그아웃 되었습니다',
    description: '개인정보 보호를 위해 로그인 후 1시간 동안 사용 내역이 없어 자동 로그아웃 되었습니다.',
  },
};
