import { atom } from 'recoil';

const initialState = {
  message: { isVisible: false, title: '', message: '' },
  isConfirmVisible: false,
  isComplianceVisible: false,
  isVotingCompletedVisible: false,
  isAdminSurveyVotingCompletedVisible: false,
  isTimeoutVisible: false,
};

export const messageModal = atom({
  key: 'message-modal',
  default: initialState.message,
});

export const confirmModal = atom({
  key: 'confirm-modal',
  default: initialState.isConfirmVisible,
});

export const complianceModal = atom({
  key: 'compliance-modal',
  default: initialState.isComplianceVisible,
});

export const votingCompletedModal = atom({
  key: 'voting-completed-modal',
  default: initialState.isVotingCompletedVisible,
});

export const timeoutModal = atom({
  key: 'timeout-modal',
  default: initialState.isTimeoutVisible,
});
