import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Text } from '@pickme/ui';
import { useNavigate, useParams } from 'react-router-dom';

import HomeHeader from 'components/features/home/Header';
import HomeCallout from 'components/features/home/Callout';
import PollView from 'components/features/home/Polls/PollView';
import PollListCardSkeleton from 'components/features/home/Polls/PollListCardSkeleton';

import { useGetPolls, useRegisterAllPollsAsVoter } from 'query-hooks/poll';
import { useHomeParams } from 'pages/Home/useHomeParams';

import { filterPolls, sortPolls } from 'functions/polls';

import { PollViewMode } from 'types/poll';

import EmptyIcon from 'resources/icons/home/empty.png';

import './index.scss';

function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [viewMode, setViewMode] = useState<PollViewMode>(PollViewMode.Table);

  const { alias } = useParams<{ alias?: string }>();
  const [params, setParams] = useHomeParams({
    alias: '',
    mode: PollViewMode.Table,
  });

  useEffect(() => {
    if (alias) {
      const parsedAliasValue = alias.replace(/[^a-zA-Z0-9]/g, '');
      navigate(`/?alias=${parsedAliasValue}`, { replace: true });
    }
  }, [alias]);

  const registerAllPollsAsVoter = useRegisterAllPollsAsVoter();
  useEffect(() => {
    if (params.alias && !registerAllPollsAsVoter.isLoading && !registerAllPollsAsVoter.isSuccess) {
      registerAllPollsAsVoter.mutate({ alias: params.alias });
    }

    if (registerAllPollsAsVoter.isError && params.alias) {
      setParams({ ...params, alias: '' });
    }
  }, [params.alias, registerAllPollsAsVoter.isError]);

  useEffect(() => {
    setViewMode(params.mode as PollViewMode);
  }, [params.mode]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage } = useGetPolls();

  const polls = data?.pages.flatMap((result) => result.polls) || [];

  const [ongoingPolls, finishedPolls, scheduledPolls, nullityPolls] = sortPolls(polls || []);
  const parsedPolls = [...ongoingPolls, ...finishedPolls, ...scheduledPolls, ...nullityPolls];

  const filteredPolls = filterPolls(parsedPolls);

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className='home'>
      <HomeHeader t={t} i18n={i18n} />

      {!isLoading && polls?.length === 0 && (
        <HomeCallout isOpen={polls === undefined ? false : filteredPolls.length === 0} />
      )}

      <main className='home-polls'>
        {!isLoading && polls?.length === 0 && (
          <div className='home-polls--empty'>
            <img src={EmptyIcon} alt='empty poll' />
            <Text type='b1' fontWeight={600}>
              {t('user:home.empty.empty')}
            </Text>
            <Text type='b3' color='gray-400'>
              {t('user:home.empty.wait')}
            </Text>
          </div>
        )}

        {polls?.map((poll) => <PollView viewMode={viewMode} poll={poll} t={t} />)}

        {(isLoading || isFetchingNextPage) &&
          new Array(4)
            .fill('')
            .map((_, index) => <PollListCardSkeleton key={`skeleton-card-${index}`} />)}

        <div ref={ref} style={{ height: '50px' }} />
      </main>
    </div>
  );
}

export default Home;
