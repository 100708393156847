import { ReactNode, useEffect } from 'react';
import { Button, PlainButton, Text } from '@pickme/ui';

import Header from 'components/common/layout/Header';

import Arrow from 'resources/icons/poll-home/arrow-primary.png';

import './index.scss';

type Props = {
  children: ReactNode;
  name: string;
  lastStep: number;
  step: number;
  buttons: {
    prev: {
      text: string;
      onClick: () => void;
      disabled: boolean;
    };
    next: {
      text: string;
      onClick: () => void;
      disabled: boolean;
    };
    submit: {
      text: string;
      onClick: () => void;
      disabled: boolean;
    };
  };
};

function VoteLayout({ children, name, lastStep, step, buttons }: Props) {
  const isLastStep = step === lastStep;

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <div className='vote-layout'>
      <Header isBackButtonVisible rightText={`${step}/${lastStep}`} bgColor='gray-50'>
        <Text type='b2' fontWeight={400}>
          {name}
        </Text>
      </Header>

      <main className='vote-layout__body'>
        <div className='vote-layout__progress-bar'>
          <div
            className='vote-layout__progress-bar__filled'
            style={{ width: `${(step / lastStep) * 100}%` }}
          />
        </div>

        <div className='vote-layout__body__center'>
          <div className='vote-layout__vote-box'>{children}</div>
        </div>

        <div className='vote-layout__buttons'>
          <PlainButton onClick={buttons.prev.onClick} disabled={buttons.prev.disabled}>
            <Text type='b3' color='primary-500' className='vote-layout__button'>
              <img src={Arrow} alt='prev button' className='vote-layout__button__prev-icon' />
              {buttons.prev.text}
            </Text>
          </PlainButton>

          {isLastStep ? (
            <Button
              size='lg'
              width='149px'
              onClick={buttons.submit.onClick}
              className='vote-layout__button__voting'
              disabled={buttons.submit.disabled}
            >
              {buttons.submit.text}
            </Button>
          ) : (
            <PlainButton onClick={buttons.next.onClick} disabled={buttons.next.disabled}>
              <Text type='b3' color='primary-500' className='vote-layout__button'>
                {buttons.next.text}
                <img src={Arrow} alt='next button' className='vote-layout__button__next-icon' />
              </Text>
            </PlainButton>
          )}
        </div>
      </main>
    </div>
  );
}

export default VoteLayout;
