import { Text } from '@pickme/ui';

import './index.scss';

function RankIcon({ rank }: { rank: number }) {
  return (
    <div className={`rank-icon rank${rank}`}>
      <Text type='lab1' fontWeight={600}>
        {rank}
      </Text>
    </div>
  );
}

export default RankIcon;
