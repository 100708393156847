import {
  ChoiceElectionResult,
  ProsOrConsElectionResult,
  ScoreElectionResult,
  RankElectionResult,
} from 'types/election';
import type { TFunction } from 'i18next';
import {
  ChoiceQuestionResult,
  RankQuestionResult,
  ScaleQuestionResult,
  ScoreQuestionResult,
  TextQuestionResult,
} from 'types/survey';

export function parseChoiceElectionResult(result: ChoiceElectionResult, t: TFunction) {
  return {
    id: result._id,
    title: result.title,
    totalCount: result.totalVoteCount,
    unit: t('user:poll.unit.vote'),
    tooltipLabels: {
      rate: t('user:poll.result.tooltip.rate'),
      count: t('user:poll.result.tooltip.count'),
    },
    items: result.resultsPerCandidate
      .sort((pre, next) => next.voteCount.value - pre.voteCount.value)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl;

        return {
          title: resultPerCandidate.title,
          image: image || '',
          count: resultPerCandidate.voteCount.value,
          rate: resultPerCandidate.voteCount.rate,
        };
      }),
    abstention: result.responseValidation.allowAbstentionVote
      ? {
          title: t('user:poll.abstention'),
          rate: result.abstentionCount.rate,
          count: result.abstentionCount.value,
        }
      : undefined,
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}

export function parseProsOrConsElectionResult(result: ProsOrConsElectionResult, t: TFunction) {
  return {
    id: result._id,
    title: result.title,
    unit: t('user:poll.unit.vote'),
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    index: {
      pros: t('user:poll.result.propsOrCons.favor'),
      cons: t('user:poll.result.propsOrCons.against'),
      abstention: t('user:poll.abstention'),
    },
    tooltipLabels: {
      rate: t('user:poll.result.tooltip.rate'),
      count: t('user:poll.result.tooltip.count'),
    },
    items: result.resultsPerCandidate?.map((resultPerCandidate) => {
      const image = resultPerCandidate.members?.[0]?.fileUrl;

      return {
        title: resultPerCandidate.title,
        image: image || '',
        pros: { count: resultPerCandidate.pros.value, rate: resultPerCandidate.pros.rate },
        cons: { count: resultPerCandidate.cons.value, rate: resultPerCandidate.cons.rate },
        abstention: {
          count: resultPerCandidate.abstention.value,
          rate: resultPerCandidate.abstention.rate,
        },
      };
    }),
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}

export function parseScoreElectionResult(result: ScoreElectionResult, t: TFunction) {
  return {
    id: result._id,
    title: result.title,
    unit: t('user:poll.unit.vote'),
    items: result.resultsPerCandidate
      .sort((pre, next) => next.averageScore - pre.averageScore)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl || '';

        return {
          title: resultPerCandidate.title,
          image,
          totalScore: resultPerCandidate.totalScore,
          voteCount: resultPerCandidate.voteCount,
          averageScore: resultPerCandidate.averageScore,
        };
      }),
    labels: {
      score: {
        title: t('user:poll.result.score.election.score'),
        unit: t('user:poll.unit.score'),
      },
      voter: {
        title: t('user:poll.result.score.election.participant'),
        unit: t('user:poll.unit.vote'),
      },
      average: {
        title: t('user:poll.result.score.election.average'),
        unit: t('user:poll.unit.score'),
      },
      abstention: {
        title: t('user:poll.abstention'),
        unit: t('user:poll.unit.vote'),
      },
    },
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    abstentionCount: result.abstentionCount.value,
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}

export function parseRankElectionResult(result: RankElectionResult, t: TFunction) {
  return {
    id: result._id,
    title: result.title,
    items: result.resultsPerCandidate
      .sort((pre, next) => next.weightedScore - pre.weightedScore)
      ?.map((resultPerCandidate) => {
        const image = resultPerCandidate.members?.[0]?.fileUrl || '';

        return {
          title: resultPerCandidate.title,
          image,
          voteCountsPerRank: resultPerCandidate.voteCountsPerRank.map((voteCountsPerRank) => {
            const rankUnit =
              voteCountsPerRank?.rank !== '1' &&
              voteCountsPerRank?.rank !== '2' &&
              voteCountsPerRank?.rank !== '3'
                ? 'other'
                : voteCountsPerRank?.rank;

            return {
              ...voteCountsPerRank,
              unit: t(`user:poll.result.rank.unit.${rankUnit}`),
            };
          }),
          weightedScore: resultPerCandidate.weightedScore,
        };
      }),
    labels: {
      vote: {
        unit: t('user:poll.unit.vote'),
      },
      score: {
        title: t('user:poll.result.rank.weightedScore'),
        unit: t('user:poll.unit.score'),
        tooltip: t('user:poll.result.rank.tooltip'),
      },
      abstention: {
        title: t('user:poll.abstention'),
        unit: t('user:poll.unit.vote'),
      },
    },
    allowAbstentionVote: result.responseValidation.allowAbstentionVote,
    abstentionCount: result.abstentionCount.value,
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}

export function parseChoiceQuestionResult(
  result: ChoiceQuestionResult,
  index: number,
  t: TFunction,
) {
  return {
    index,
    id: result._id,
    title: result.title,
    answerCountText:
      result.kind === 'RadioQuestionResult'
        ? t('user:poll.result.surveyTotal', {
            total: result.totalAnsweredCount,
          })
        : `${t('user:poll.result.surveyTotal', {
            total: result.totalAnsweredCount,
          })} (${t('user:poll.result.surveyMultipleAnswer')})`,
    isRequired: result.isRequired,
    totalCount: result.totalAnsweredCount,
    unit: t('user:poll.unit.vote'),
    tooltipLabels: {
      rate: t('user:poll.result.tooltip.rate'),
      count: t('user:poll.result.tooltip.count'),
    },
    items: result.results
      .sort((pre, next) => next.count - pre.count)
      .map((item) => ({
        ...item,
        description: item.description || '',
        number: `${item.number}`,
      })),
    animation: {
      fadeIn: true,
      fillBar: true,
      countingUp: true,
    },
  };
}

export function parseTextQuestionResult(result: TextQuestionResult, index: number, t: TFunction) {
  return {
    index,
    id: result._id,
    title: result.title,
    answerCountText: t('user:poll.result.surveyTotal', {
      total: result.totalAnsweredCount,
    }),
    isRequired: result.isRequired,
    labels: {
      empty: t('user:poll.result.surveyTextEmpty'),
    },
    items: result.results,
    animation: {
      fadeIn: true,
      fillBar: true,
      countingUp: true,
    },
  };
}

export function parseScaleQuestionResult(result: ScaleQuestionResult, index: number, t: TFunction) {
  return {
    index,
    title: result.title,
    answerCountText: t('user:poll.result.surveyTotal', {
      total: result.totalAnsweredCount,
    }),
    isRequired: result.isRequired,
    labels: {
      min: result.min,
      max: result.max,
    },
    items: result.results,
  };
}

export function parseScoreQuestionResult(result: ScoreQuestionResult, index: number, t: TFunction) {
  return {
    index,
    id: result._id,
    title: result.title,
    isRequired: result.isRequired,
    answerCountText: t('user:poll.result.surveyTotal', {
      total: result.totalAnsweredCount,
    }),
    items: result.results
      .sort((pre, next) => next.averageScore - pre.averageScore)
      ?.map((item) => {
        const image = item?.fileUrl || '';

        return {
          description: item.description || '',
          image,
          totalScore: item.totalScore,
          answeredCount: item.answeredCount,
          averageScore: item.averageScore,
        };
      }),
    labels: {
      score: {
        title: t('user:poll.result.score.election.score'),
        unit: t('user:poll.unit.score'),
      },
      voter: {
        title: t('user:poll.result.score.election.participant'),
        unit: t('user:poll.unit.vote'),
      },
      average: {
        title: t('user:poll.result.score.election.average'),
        unit: t('user:poll.unit.score'),
      },
    },
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}

export function parseRankQuestionResult(result: RankQuestionResult, index: number, t: TFunction) {
  return {
    id: result._id,
    index,
    title: result.title,
    isRequired: result.isRequired,
    answerCountText: t('user:poll.result.surveyTotal', {
      total: result.totalAnsweredCount,
    }),
    items: result.results
      .sort((pre, next) => next.weightedScore - pre.weightedScore)
      ?.map((item) => {
        const image = item.fileUrl || '';

        return {
          description: item.description || '',
          image,
          answerCountsPerRank: item.answerCountsPerRank.map((answerCountsPerRank) => {
            const rankUnit =
              answerCountsPerRank?.rank !== '1' &&
              answerCountsPerRank?.rank !== '2' &&
              answerCountsPerRank?.rank !== '3'
                ? 'other'
                : answerCountsPerRank?.rank;

            return {
              ...answerCountsPerRank,
              unit: t(`user:poll.result.rank.unit.${rankUnit}`),
            };
          }),
          weightedScore: item.weightedScore,
        };
      }),
    labels: {
      vote: {
        unit: t('user:poll.unit.vote'),
      },
      score: {
        title: t('user:poll.result.rank.weightedScore'),
        unit: t('user:poll.unit.score'),
        tooltip: t('user:poll.result.rank.tooltip'),
      },
    },
    animation: { countingUp: true, fadeIn: true, fillBar: true },
  };
}
