import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import {
  createVoteElectionRequest,
  createVoteExceptionalElectionRequest,
  createVoteExceptionalSurveyRequest,
  createVoteInstantSurveyRequest,
  createVoteSurveyRequest,
} from 'apis/vote';

import { confirmModal, messageModal, votingCompletedModal } from 'states/modal';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

import { parseElectionVote, parseSurveyVote } from 'functions/vote';
import { handleSessionError } from 'utils/error-parser';

import { VotingElectionForm, VotingSurveyForm } from 'types/vote';
import type { AxiosError } from 'axios';
import { setExceptionalVote } from 'functions/exceptional';

export const useVote = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setSuccessModal = useSetRecoilState(votingCompletedModal);
  const setMessageModal = useSetRecoilState(messageModal);
  const setConfirmModalVisible = useSetRecoilState(confirmModal);

  return useMutation(
    async ({
      data,
      hasInstantVoter,
    }: {
      data: VotingElectionForm | VotingSurveyForm;
      hasInstantVoter?: boolean;
    }) => {
      if ('answers' in data) {
        const surveyVotes = parseSurveyVote(data);

        if (EXCEPTIONAL_POLL_IDS.includes(data.pollId)) {
          setExceptionalVote(data.pollId);
        }

        if (EXCEPTIONAL_POLL_IDS.includes(data.pollId)) {
          await createVoteExceptionalSurveyRequest(surveyVotes);
        } else if (hasInstantVoter) {
          await createVoteInstantSurveyRequest({ ...surveyVotes, pollOid: data?.pollId });
        } else {
          await createVoteSurveyRequest(surveyVotes);
        }

        return;
      }

      const electionVotes = parseElectionVote(data);

      if (EXCEPTIONAL_POLL_IDS.includes(data.pollId)) {
        await createVoteExceptionalElectionRequest(electionVotes);
      } else {
        await createVoteElectionRequest(electionVotes);
      }
    },
    {
      onSettled: () => {
        setConfirmModalVisible(false);
      },
      onSuccess: (_, { data }) => {
        queryClient.invalidateQueries(['auth']);
        queryClient.invalidateQueries(['polls']);
        queryClient.invalidateQueries(['poll', data.pollId]);
        navigate(-1);
        setSuccessModal(true);
      },
      onError: (error: AxiosError, { data }) => {
        handleSessionError(error, queryClient);
        setMessageModal({
          isVisible: true,
          title: t('user:poll.errorTitle'),
          message: error.message,
        });
        navigate(`/poll/${data.pollId}`, { replace: true });
        queryClient.invalidateQueries(['poll', data.pollId]);
      },
    },
  );
};
