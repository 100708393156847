import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from '@pickme/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'components/features/auth/Layout';
import OTPSignInForm from 'components/features/auth/form/OTPSignIn';
import OTPVerificationForm from 'components/features/auth/form/OTPVerification';

import { useSendOTPByEmail, useSendOTPBySMS, useVerifyEmail, useVerifySMS } from 'query-hooks/otp';
import { useOTPSignIn } from 'query-hooks/auth';
import { useGetAuthMethods } from 'query-hooks/poll';

import { emailValidator, phoneNumberValidator } from 'functions/validator/auth';

import { AuthMethod } from 'types/auth';

import './OTPSignIn.scss';

type Method = 'email' | 'phone';

function Signin() {
  const { t } = useTranslation();

  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirectUrl');

  const pollId = getPollIdFromQueryString(redirectUrl ?? '');
  const { data: authMethods } = useGetAuthMethods(pollId);

  const [method, setMethod] = useState<Method>('email');

  const sendEmail = useSendOTPByEmail();
  const sendSMS = useSendOTPBySMS();
  const verifyEmail = useVerifyEmail();
  const verifySMS = useVerifySMS();
  const signIn = useOTPSignIn();

  const isSent = sendEmail.isSuccess || sendSMS.isSuccess;

  const phoneNumber = sendSMS.data?.phoneNumber || '';
  const email = sendEmail.data?.email || '';

  const onSendOTP = (id: string) => {
    if (phoneNumberValidator(id)) {
      setMethod('phone');
      sendSMS.mutate(id);
    } else if (emailValidator(id)) {
      setMethod('email');
      sendEmail.mutate(id);
    }
  };

  const resend = () => {
    if (method === 'email' && email) {
      sendEmail.mutate(email);
    } else if (method === 'phone' && phoneNumber) {
      sendSMS.mutate(phoneNumber);
    }
  };

  const onSubmitCode = (code: string) => {
    const isError = false;

    if (method === 'email') {
      verifyEmail.mutate(
        { email, code },
        {
          onSuccess: ({ token }) => {
            signIn.mutate({ type: 'email', token: token || '' });
          },
        },
      );
    } else if (method === 'phone') {
      verifySMS.mutate(
        { phoneNumber, code },
        {
          onSuccess: ({ token }) => {
            signIn.mutate({ type: 'phoneNumber', token: token || '' });
          },
        },
      );
    }

    return isError;
  };

  return (
    <AuthLayout isBackButtonVisible={isSent}>
      <div className='auth'>
        <Text type='b1' fontWeight={600} className='auth__title'>
          {t('user:auth.signin.title')}
        </Text>

        {!isSent ? (
          <OTPSignInForm
            methods={authMethods || [AuthMethod.Email, AuthMethod.Phone]}
            isLoading={sendEmail.isLoading || sendSMS.isLoading}
            onSubmit={onSendOTP}
          />
        ) : (
          <OTPVerificationForm
            type={method}
            target={method === 'email' ? email : phoneNumber}
            resend={resend}
            onSubmitCode={onSubmitCode}
          />
        )}
      </div>
    </AuthLayout>
  );
}

export default Signin;

function getPollIdFromQueryString(redirectUrl: string) {
  const pollIdRegex = /\/poll\/([^/]+)/;
  const matchedPollId = (redirectUrl || '').match(pollIdRegex);

  const pollId = matchedPollId?.[1];
  return pollId || '';
}
