import axios, { AxiosError } from 'axios';
import { parseErrorMessage } from '@pickme/error';
import i18n from 'i18next';

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { resolvedLanguage } = i18n;

    const combinedError = {
      ...error,
      networkStatusCode: error.code,
      ...parseErrorMessage(error, resolvedLanguage as 'ko' | 'en'),
    };

    return Promise.reject(combinedError);
  },
);
