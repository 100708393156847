import { Routes, Route, Navigate } from 'react-router-dom';

import SignIn from 'pages/auth/OTPSignIn';
import Home from 'pages/Home';

import PollHome from 'pages/poll/Home';
import PollDescription from 'pages/poll/Description';
import ElectionCandidates from 'pages/poll/Candidates';
import VoteElection from 'pages/poll/VoteElection';
import VoteSurvey from 'pages/poll/VoteSurvey';
import PollResult from 'pages/poll/Result';

import EmbeddedPollHome from 'pages/embed/PollHome';
import EmbeddedDescription from 'pages/embed/Description';
import EmbeddedElectionCandidates from 'pages/embed/Candidates';
import EmbeddedVoteElection from 'pages/embed/VoteElection';
import EmbeddedVoteSurvey from 'pages/embed/VoteSurvey';

import VoteAdminSurvey from 'pages/admin-survey/Vote';

import Wrapper from 'pages/exceptional-poll/Wrapper';

import { useGetSession } from 'query-hooks/auth';

import { useRedirectUrl } from 'hooks/useRedirectUrl';
import { useCheckSessionExpires } from 'hooks/useCheckSessionExpires';
import { useSendSentryReport, useSentryUser } from 'hooks/useSentry';

function Navigator() {
  const { isLoading, data } = useGetSession();
  const isSigned = !!data?._id;

  useRedirectUrl();
  useCheckSessionExpires();
  useSendSentryReport();
  useSentryUser();

  if (isLoading) {
    return <div />;
  }

  return (
    <Routes>
      <Route path='/embed/poll'>
        <Route index element={<EmbeddedPollHome />} />
        <Route path='description' element={<EmbeddedDescription />} />
        <Route path=':subElectionId/candidates' element={<EmbeddedElectionCandidates />} />
        <Route path='vote/election' element={<EmbeddedVoteElection />} />
        <Route path='vote/survey' element={<EmbeddedVoteSurvey />} />
      </Route>

      {!isSigned && <Route path='*' element={<SignIn />} />}

      {!isSigned && (
        <Route path='poll/:id' element={<Wrapper />}>
          <Route index element={<PollHome />} />
          <Route path='description' element={<PollDescription />} />
          <Route path=':subElectionId/candidates' element={<ElectionCandidates />} />
          <Route path='vote/election' element={<VoteElection />} />
          <Route path='vote/survey' element={<VoteSurvey />} />
          <Route path='result' element={<PollResult />} />
        </Route>
      )}

      {isSigned && (
        <Route>
          <Route index element={<Home />} />
          <Route path='/:alias' element={<Home />} />

          <Route path='poll/:id'>
            <Route index element={<PollHome />} />
            <Route path='description' element={<PollDescription />} />
            <Route path=':subElectionId/candidates' element={<ElectionCandidates />} />
            <Route path='vote/election' element={<VoteElection />} />
            <Route path='vote/survey' element={<VoteSurvey />} />
            <Route path='result' element={<PollResult />} />
          </Route>

          <Route path='/admin-survey/:organizationOid/vote' element={<VoteAdminSurvey />} />

          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      )}
    </Routes>
  );
}

export default Navigator;
