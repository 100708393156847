import { PrimaryBadge, GradientDoughnutGraph, Text } from '@pickme/ui';
import { PollGroup } from '@pickme/core';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

import { parsePollByGroup, parsePeriodString } from 'functions/poll';
import { setColorsForRateGraph } from 'functions/rate';

import { Poll } from 'types/poll';

import VoteIcon from 'resources/icons/home/vote.png';
import UnvoteIcon from 'resources/icons/home/unvote.png';
import StopIcon from 'resources/icons/home/stop.png';

// SkeletonCard와 동일한 html 구조를 가지고 있습니다. PollCard의 구조나 className이 변경되면 SkeletonCard도 변경해야합니다.

function PollListCard({ poll, t }: { poll: Poll; t: TFunction }) {
  const { color, label } = parsePollByGroup(poll?.group, t);

  const graphColors = setColorsForRateGraph(poll?.group);

  const dDay = dayjs(poll?.startDate).diff(dayjs(), 'd');
  const period = parsePeriodString(poll?.startDate, poll?.endDate?.date);

  return (
    <Link to={`/poll/${poll?._id}`}>
      <div className='poll-card'>
        <div className='poll-card__body'>
          <div className='poll-card__body__labels'>
            <PrimaryBadge variant={color}>{label}</PrimaryBadge>

            {poll?.group === PollGroup.Ready ? (
              <Text type='b4' color='warning-700' className='poll-card__body__labels__d-day'>
                D-{dDay === 0 ? 'DAY' : dDay}
              </Text>
            ) : (
              <img src={poll?.hasVoted ? VoteIcon : UnvoteIcon} alt='투표 아이콘' />
            )}

            {(poll?.isPause || poll?.isInBreakTime) &&
              (poll?.group === PollGroup.Ongoing || poll?.group === PollGroup.Paused) && (
                <img src={StopIcon} alt='일시정지' />
              )}
          </div>

          <Text type='b1' fontWeight={500} className='poll-card__body__name'>
            {poll?.name}
          </Text>

          <Text type='b4' fontWeight={400} color='gray-400'>
            {period}
          </Text>
        </div>

        <div className='poll-card__graph'>
          {poll?.isOpenPollRate && !poll?.hasInstantVoter && (
            <GradientDoughnutGraph
              gradientColors={graphColors}
              value={Number(((poll.rate ?? 0) * 100).toFixed(2))}
            />
          )}
        </div>
      </div>
    </Link>
  );
}

export default PollListCard;
