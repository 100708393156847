import { Text } from '@pickme/ui';
import { t } from 'i18next';

import PollInfoModalLayout from 'components/features/poll/modal/PollInfoModalLayout';

import { useGetVoter } from 'query-hooks/poll';

import { PollKind } from 'types/poll';

import ElectionIcon from 'resources/icons/poll-home/election.png';

import './index.scss';

type Props = {
  id: string;
  pollKind: PollKind | '';
  isVisible: boolean;
  onClose: () => void;
  title: string;
  period: string;
  organizationName: string;
};

function PollCertificateModal({
  id,
  pollKind,
  isVisible,
  onClose,
  title,
  period,
  organizationName,
}: Props) {
  const { data: voter } = useGetVoter(id, isVisible);
  const { name = '', userId = '', email, phoneNumber } = voter || {};

  return (
    <PollInfoModalLayout
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      period={period}
      className='poll-certificate-modal'
      isShare
    >
      <img src={ElectionIcon} alt={pollKind} className='poll-certificate-modal__icon' />

      <Text type='b3' fontWeight={600} color='gray-500'>
        {t(`user:pollModal.certificate.${pollKind}`)}
      </Text>

      <div className='poll-certificate-modal__items'>
        <Text type='b3' fontWeight={400} color='gray-400' className='poll-certificate-modal__item'>
          {organizationName}
        </Text>

        <Text type='b3' fontWeight={400} color='gray-400' className='poll-certificate-modal__item'>
          {userId || '-'}
        </Text>

        <Text type='b3' fontWeight={400} color='gray-400' className='poll-certificate-modal__item'>
          {name || phoneNumber || email || '-'}
        </Text>
      </div>
    </PollInfoModalLayout>
  );
}

export default PollCertificateModal;
