import { PlainButton, Text } from '@pickme/ui';
import { CSSProperties, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowIcon from 'resources/icons/common/left-arrow.png';
import WhiteArrowIcon from 'resources/icons/common/left-arrow.svg';

import './index.scss';

type Props = {
  isBackButtonVisible?: boolean;
  backLink?: string;
  children?: ReactNode;
  rightText?: string;
  bgColor?: string;
  arrowColor?: 'gray' | 'white';
};

function Header({
  isBackButtonVisible,
  backLink,
  children,
  rightText,
  bgColor,
  arrowColor = 'gray',
}: Props) {
  const navigate = useNavigate();

  const style = { '--color': bgColor ? `var(--${bgColor})` : 'white' } as CSSProperties;
  const icon = arrowColor === 'gray' ? ArrowIcon : WhiteArrowIcon;

  return (
    <header className='header' style={style}>
      {isBackButtonVisible ? (
        <PlainButton
          onClick={() => (backLink ? navigate(backLink) : navigate(-1))}
          className='header__back-button'
        >
          <img src={icon} alt='back button' />
        </PlainButton>
      ) : (
        <div className='header__empty-box' />
      )}

      {children && <div className='header__title'>{children}</div>}

      {rightText ? (
        <Text type='b3' color='gray-400' fontWeight={400} className='header__right'>
          {rightText}
        </Text>
      ) : (
        <div className='header__empty-box' />
      )}
    </header>
  );
}

export default Header;
