import PollDescription from 'components/features/poll/Description';

import { useEmbeddedPoll } from 'hooks/useEmbed';

function EmbeddedDescription() {
  const poll = useEmbeddedPoll();

  return <PollDescription poll={poll} />;
}

export default EmbeddedDescription;
