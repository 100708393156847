import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { EXCEPTIONAL_POLL_IDS } from 'constants/poll';

function Wrapper() {
  const navigate = useNavigate();
  const { id: pollId } = useParams<{ id: string }>();

  if (!pollId || !EXCEPTIONAL_POLL_IDS.includes(pollId)) {
    navigate('/');
  }

  return <Outlet />;
}

export default Wrapper;
