export const emailValidator = (value: string) => {
  if (value.length < 7 || value.length > 50) {
    return false;
  }

  // Just trust http://emailregex.com/
  const regex =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(value)) {
    return false;
  }

  return true;
};

export const nameValidator = (value: string) => /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,100}$/.test(value);

export const organizationNameValidator = (value: string) =>
  /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,100}$/.test(value);

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
const pwRegex = new RegExp(PASSWORD_REGEX);
export const passwordValidator = (value: string) => pwRegex.test(value);

export const otpValidator = (value: string) => value.length === 6;

export const phoneNumberValidator = (value: string) =>
  /^(010-\d{4}-\d{4}$)|(^010\d{4}\d{4}$)/.test(value);
