import ElectionCandidates from 'components/features/poll/Candidates';

import { useEmbeddedPoll } from 'hooks/useEmbed';

function EmbeddedElectionCandidates() {
  const poll = useEmbeddedPoll();

  return <ElectionCandidates poll={poll} isVisibleVoteButton isEmbedded />;
}

export default EmbeddedElectionCandidates;
