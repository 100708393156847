import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, ModalHeader, Spinner, Text } from '@pickme/ui';

import { confirmModal } from 'states/modal';

import './index.scss';

type Props = {
  title: string;
  text?: string;
  onClick: () => void;
  isLoading?: boolean;
  buttons: {
    confirm?: string;
    cancel?: string;
  };
};

function ConfirmModal({ title, text, onClick, isLoading, buttons }: Props) {
  const { t } = useTranslation();

  const [isModalVisible, setModalVisible] = useRecoilState(confirmModal);

  const onClose = () => {
    if (isLoading) {
      return;
    }

    setModalVisible(false);
  };

  return (
    <Modal isVisible={isModalVisible} onClose={onClose} rounded className='confirm-modal'>
      <ModalHeader title={title} onClose={onClose} hideCloseButton />
      <ModalBody>
        <Text size='sm1' fontWeight={300} color='gray-400'>
          {text}
        </Text>
      </ModalBody>
      <ModalActions
        rounded
        buttons={[
          {
            text: isLoading ? <Spinner /> : buttons.confirm || t('user:modal.confirm.confirm'),
            onClick,
            disabled: isLoading,
          },
          {
            text: buttons.cancel || t('user:modal.confirm.cancel'),
            onClick: onClose,
            variant: 'gray',
            disabled: isLoading,
          },
        ]}
      />
    </Modal>
  );
}

export default ConfirmModal;
